import React, {Component} from 'react';
import { Redirect, Link } from 'react-router-dom';
import axios from 'axios';
import qs from 'qs';
import { Alert } from 'reactstrap';
import whiteLogo from '../../../assets/assets/images/logo-text.png';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            pageTitle: 'Login - Ticketsasa Merchants',
            loginResponseData: '',
            blocking: false,
            loginStatusType: '',
            loginMessage: '',
            showLoginMessage: false,
            loginUserData: {},
            redirect: false,
            isLoggedInMessage: false,
            isLoggedOutMessage: false
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggleBlocking = this.toggleBlocking.bind(this);
    }

    componentWillMount() {
    }

	componentDidMount() {
		// Update page title
        document.title = this.state.pageTitle;
        
        // Check for the isloggedin variable and show message or not
        this.isLoggedIn();

        // Check for the logged out param
        this.isLoggedOut();
    }
    
    isLoggedIn() {
        // Get url segments
        let isLoggedIn = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).isloggedin;

        if(isLoggedIn) {
            if(isLoggedIn === 'false') {
                this.setState({isLoggedInMessage: true});
            }  
        }
    }

    isLoggedOut() {
        // Get url segment
        var loggedOutStatus = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).loggedout;

        if(loggedOutStatus) {
            if(loggedOutStatus === 'true') {
                this.setState({isLoggedOutMessage: true});
            }
        }
    }

    toggleBlocking() {
        this.setState({blocking: !this.state.blocking});
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        // Get events to populate dropdown
        let url = 'https://www.ticketsasa.com/index.php?option=com_enmasse&controller=webservice&task=loginMobile&username=' + this.state.email + '&password=' + this.state.password;

        this.toggleBlocking();

		// Send a GET request
		axios({
			method: 'GET',
			url: url,
			headers: {
				// "Authorization": `Bearer ${tokenStr}`,
				// "Aff-Key": keyStr
			}
		})
        .then(response => {
            // console.log(response);
            this.setState({loginResponseData: response.data.toString()});

            // Check whether the user is a merchant
            if(response.data.Type.toLowerCase() === "ok") {
                // Check whether it is a successful merchant login
                if(response.data.Data.isMerchant) {
                    // Proceed to login
                    this.setState({loginUserData: response.data.Data});
                    this.setState({loginMessage: 'You were successfully logged in! Redirecting to home page..'});
                    this.setState({loginStatusType: response.data.Type.toLowerCase()});

                    // Set local storage details
                    localStorage.setItem('email', response.data.Data.email);
                    localStorage.setItem('username', response.data.Data.username);
                    localStorage.setItem('name', response.data.Data.name);
                    localStorage.setItem('id', response.data.Data.id);
                    localStorage.setItem('phone', response.data.Data.phone);
                    localStorage.setItem('merchant', JSON.stringify(response.data.Data.merchant)); // Stringify object
                    localStorage.setItem('isLoggedIn', true);
                    localStorage.setItem('contactName', response.data.Data.merchant.person);
                    localStorage.setItem('role', response.data.Data.role);
                    localStorage.setItem('registerDate', response.data.Data.registerDate);
                    localStorage.setItem('lastVisitDate', response.data.Data.lastvisitDate);

                    // Merchant details
                    localStorage.setItem('merchantName', response.data.Data.merchant.name);
                    localStorage.setItem('merchantDescription', response.data.Data.merchant.description);
                    localStorage.setItem('merchantWebsite', response.data.Data.merchant.web_url);
                    localStorage.setItem('merchantImage', response.data.Data.merchant.logo_url);
                    localStorage.setItem('twitterLink', response.data.Data.merchant['tw-link']);
                    localStorage.setItem('facebookLink', response.data.Data.merchant['fb-link']);
                    localStorage.setItem('instagramLink', response.data.Data.merchant['ig-link']);

                    // Do redirect to the dashboard/home page
                    this.setState({ redirect: true });

                } else {
                    // Display error message
                    this.setState({loginMessage: 'This is not a valid merchant account. Please contact support for further assistance.'});
                    this.setState({loginStatusType: 'warning'});
                }
                
            } else {
                // Set error messages
                this.setState({loginMessage: response.data.Message.toLowerCase()});
                this.setState({loginStatusType: response.data.Type.toLowerCase()});

            }

            this.setState({showLoginMessage: true});
            this.toggleBlocking();

        })
        .catch(error => {
            // handle error
            this.toggleBlocking();
            // console.log('Error ', error);
        });
    }

    render () {
        const { redirect } = this.state;

        if (redirect) {
            return <Redirect to='/'/>;
        }

        return (
            <BlockUi tag="div" blocking={this.state.blocking}>
                
                <div className="">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-3 col-sm-0"></div>
                            <div className="col-md-6 col-sm-12">
                                <center>
                                    <Link to="/"><img src={whiteLogo} alt="Ticketsasa"></img></Link>
                                </center>
                                <br /><br />
                                <Alert color="success" style={{display: this.state.isLoggedOutMessage ? '' : 'none'}}>
                                    {'You have been successfully logged out.'}
                                </Alert>
                                <Alert color="info" style={{display: this.state.isLoggedInMessage ? '' : 'none'}}>
                                    {'Please login first to proceed.'}
                                </Alert>
                                <Alert color={this.state.loginStatusType === "error" ? 'danger' : this.state.loginStatusType === "warning" ? 'warning' : 'success'} style={{display: this.state.showLoginMessage ? '' : 'none'}}>
                                    {this.state.loginMessage ? this.state.loginMessage.charAt(0).toUpperCase() + this.state.loginMessage.slice(1) : 'There was no message to display.'}
                                </Alert>
                                <div className="card">
                                    <div className="card-block">
                                        <form className="form-horizontal form-material" onSubmit={this.handleSubmit}>
                                            <div className="form-group">
                                                <label htmlFor="example-email" className="col-md-12">Email</label>
                                                <div className="col-md-12">
                                                    <input type="email" placeholder="" className="form-control form-control-line" name="email" value={this.state.email} onChange={this.handleInputChange} required />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="col-md-12">Password</label>
                                                <div className="col-md-12">
                                                    <input type="password" name="password" value={this.state.password} className="form-control form-control-line" onChange={this.handleInputChange} required />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="col-sm-12">
                                                    {/* <Link to="/" className="btn btn-success">Login</Link> */}
                                                    <button className="btn btn-success" type="submit">Login</button>
                                                    <br /><br />
                                                    {/* <p><Link to="/identity/forgot">Forgot your password?</Link></p> */}
                                                    <p><Link to="/reset">Forgot your password?</Link></p>
                                                    <p><Link to="/register">Create an account</Link></p>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-0">
                            </div>
                        </div>
                    </div>
                </div>
            
            </BlockUi>
        )
    }
}

export default Login;