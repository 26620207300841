import React, { Component } from 'react';
import { Row, Col, Button, Form, FormGroup, Input } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import qs from 'qs';

import Footer from '../../components/Footer/Footer.jsx';
import TopMenu from '../../components/TopMenu/TopMenu.jsx';
import Sidebar from '../../components/Sidebar/Sidebar.jsx';

// import OrderList from '../../components/Sales/OrderList.jsx';
import RecentOrders from '../../components/Dashboard/RecentOrders';

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
// import moment from 'moment';

class Sales extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            pageTitle: 'Single Event Sales - Ticketsasa Merchants',
            isLoggedIn: false,
            loginRedirect: false,
            merchant: {},
            eventId: null,
            blocking: false,
            orderDetails: [],
            filterEmail: null,
            filterCompsOnly: null,
            filterStartDate: null,
            filterEndDate: null,
            filterOrderId: null,
            eventDetails: {}
        }

        this.getSalesData = this.getSalesData.bind(this);
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.handleResetFilter = this.handleResetFilter.bind(this);
        this.handleFilterSubmit = this.handleFilterSubmit.bind(this);
        this.handleFilterInputChange = this.handleFilterInputChange.bind(this);
        this.getEventDetails = this.getEventDetails.bind(this);
        this.checkIfLoggedIn = this.checkIfLoggedIn.bind(this);
    }

    componentWillMount() {
        this.checkIfLoggedIn();
    }

    componentDidMount() {
		// Update page title
        document.title = this.state.pageTitle;
        
        const { eventId } = this.props.match.params;
        this.setState({ eventId: eventId });

        // Get single event orders
        this.getSalesData(eventId);
        this.getEventDetails(eventId);
    }

    checkIfLoggedIn() {
        // Check local storage for the isLoggedIn value
        if(localStorage.getItem('isLoggedIn')) {
            // Get user details
            this.setState({merchant: JSON.parse(localStorage.getItem('merchant'))}); // Converting JSON string to object and storing it to state
            // this.setState({name: localStorage.getItem('name')});
            // this.setState({email: localStorage.getItem('email')});
            // this.setState({username: localStorage.getItem('username')});

        } else {
            // Redirect to the login page with an error message
            this.setState({loginRedirect: true});
        } 
    }

    handleResetFilter(event) {
        this.setState({
            filterEmail: null,
            filterCompsOnly: null,
            filterStartDate: null,
            filterEndDate: null,
            filterOrderId: null
        });
    }

    handleFilterSubmit(event) {
        event.preventDefault();
        // Get filter variables and pass them to getSalesData
        this.getSalesData(this.state.eventId, this.state.filterEmail, this.state.filterCompsOnly, this.state.filterOrderId);
    }

    handleFilterInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
            [name]: value
        });
    }

    toggleBlocking() {
        this.setState({blocking: !this.state.blocking});
    }

    getSalesData(eventId = null, email = null, complimentary = null, orderId = null) {
        // Get single event orders
        // Add loader
        this.toggleBlocking();

        // Make call to api
        var url = 'https://www.ticketsasa.com/index.php?option=com_enmasse&controller=merchantservice&task=getEventOrders';

        var dataObj = {
            'deal_id': eventId,
            'email': email ? email : '',
            'complimentary': complimentary ? complimentary : 0,
            // 'd_start': startDate ? moment(startDate).format('YYYY-MM-DD HH:mm:ss') : '',
            // 'd_end': endDate ? moment(endDate).format('YYYY-MM-DD HH:mm:ss') : '',
            'order_id' : orderId ? orderId : ''
        }

		// Send a POST request
		axios({
            method: 'POST',
            url: url,
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(dataObj)
		})
        .then(response => {
            // Display response
            // console.log('Data response: ', response);
            
            // Store data in state    
            if(response.data.success) {
                this.setState({orderDetails: response.data.orders});
            }

            // Remove UI blocker
            this.toggleBlocking();
        })
        .catch(error => {
            // Hanandle error
            alert(error);
            
            // Remove UI blocker
            this.toggleBlocking();
        })
        .then(data => {
            // always executed
        });
    }

    getEventDetails(eventId) {
        // Getting event details
        // Block UI
        // this.toggleBlocking();

        let url = 'https://www.ticketsasa.com/index.php?option=com_enmasse&controller=merchantservice&task=getEventDetails';

        var dataObj = {
            'deal_id': eventId,
            'user_id': localStorage.getItem('username')
        }

		axios({
            method: 'POST',
            url: url,
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(dataObj)
		})
        .then(response => {
            // Prepare data
            // Check if request was successful
            if(response.data.success) {
                // Get required event data
                this.setState({eventDetails: response.data.deal})

            } else {
                // Get error details
                // TODO: Display TOAST message on the page
                alert('There was an error getting event data.')

            }
        })
        .catch(error => {
            // Display error message on the page
            alert(error);

        })
        .then(data => {
            // Always executed
            // this.toggleBlocking();
        });
    }

    render() {
        const { loginRedirect } = this.state;

        if (loginRedirect) {
            return <Redirect to='/login?isloggedin=false'/>;
        }
        
        return (

            <BlockUi tag="div" blocking={this.state.blocking}>
                <div id="main-wrapper">
                    <TopMenu />
                    <Sidebar />
                    <div className="page-wrapper">
                        
                        <div className="container-fluid">
                            <div className="row page-titles">
                                <div className="col-md-12 col-12 align-self-center">
                                    <h3 className="text-themecolor m-b-0 m-t-0">Sales</h3>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item"><Link to="/events">Events</Link></li>
                                        <li className="breadcrumb-item"><Link to={"/event/" + this.state.eventId}>{this.state.eventDetails.name ? this.state.eventDetails.name : 'Single Event'}</Link></li>
                                        <li className="breadcrumb-item active">Sales</li>
                                    </ol>
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-block">
                                            <p>Use the filter below to search for specific orders</p>
                                            <Form onSubmit={this.handleFilterSubmit}>
                                                <Row>
                                                    <Col xs="3">
                                                        <FormGroup>
                                                            <label htmlFor="">Email</label>
                                                            <Input type="text" name="filterEmail" placeholder="Enter email" onChange={this.handleFilterInputChange}></Input>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs="3">
                                                        <FormGroup>
                                                            <label htmlFor="">Show comps only?</label>
                                                            <Input type="select" name="filterCompsOnly" onChange={this.handleFilterInputChange}>
                                                                <option value="">- Select Option -</option>
                                                                <option value="1">Yes</option>
                                                                <option value="0">No</option>
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs="3">
                                                        <FormGroup>
                                                            {/* <label htmlFor="">End Date</label> */}
                                                            {/* <Input type="date" name="filterEndDate" placeholder="End Date" onChange={this.handleFilterInputChange} id="filterEndDate">
                                                            </Input> */}
                                                            <label htmlFor="">Order Id</label>
                                                            <Input type="text" name="filterOrderId" placeholder="Enter order Id" onChange={this.handleFilterInputChange}>
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>
                                                    {/* <Col xs="3">
                                                        <FormGroup>
                                                            <label htmlFor="">Start Date</label>
                                                            <Input type="date" name="filterStartDate" placeholder="Start Date" onChange={this.handleFilterInputChange} id="filterStartDate">
                                                            </Input>
                                                        </FormGroup>
                                                    </Col> */}
                                                </Row>
                                                <Row>
                                                    <Col xs="3">
                                                        {/* <label htmlFor="">Action</label> */}
                                                        {/* <br /><br /> */}
                                                        <Button color="info" type="submit">Filter Results</Button>&nbsp;
                                                        <Button color="default" type="reset" onClick={this.handleResetFilter}>Reset</Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                            <div className="row" style={{display: 'none'}}>

                                <div className="col-sm-1">
                                </div>
                                <div className="col-sm-2">
                                    <div className="card">
                                        <div className="card-block">
                                            <h4 className="card-title">Ticket Totals</h4>
                                            <h2 className="font-light m-b-0"> KES 1,200,000</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-2">
                                    <div className="card">
                                        <div className="card-block">
                                            <h4 className="card-title">Online Tickets</h4>
                                            <h2 className="font-light m-b-0"> 3,120</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-2">
                                    <div className="card">
                                        <div className="card-block">
                                            <h4 className="card-title">Physical Tickets</h4>
                                            <h2 className="font-light m-b-0"> 0</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-2">
                                    <div className="card">
                                        <div className="card-block">
                                            <h4 className="card-title">Complimentary Tickets</h4>
                                            <h2 className="font-light m-b-0"> 1,120</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-2">
                                    <div className="card">
                                        <div className="card-block">
                                            <h4 className="card-title">Verified Tickets</h4>
                                            <h2 className="font-light m-b-0"> 320</h2>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-1">
                                </div>

                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-block">
                                            <h4 className="card-title">Orders</h4>
                                            <h6 className="card-subtitle">Event order list</h6>
                                            <p style={{display: (this.state.orderDetails.length < 1) === '' ? 'none' : ''}}>There were no orders for this event found.</p>

                                            <RecentOrders orderList={this.state.orderDetails} showPagination={true} filterCompsOnly={this.state.filterCompsOnly}></RecentOrders>

                                            {/* <div className="table-responsive m-t-40" style={{display: this.state.orderDetails.length < 1 ? 'none' : ''}}>
                                                <table className="table stylish-table">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Order</th>
                                                            <th>Ticket Details</th>
                                                            <th>Status</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <OrderList orders={this.state.orderDetails}></OrderList>
                                                </table>
                                            </div> */}

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <Footer />
                    </div>
                </div>
            </BlockUi>
            
        )
    }
}

export default Sales;