import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import qs from 'qs';
import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';
import whiteLogo from '../../../assets/assets/images/logo-text.png';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle: 'Ticketsasa Merchants - Password Reset',
            email: '',
            showMessage: false,
            resetMessage: "",
            showPasswordsForm: false,
            showTokenError: false,
            passwordMatchError: false,
            password: '',
            confirmPassword: '',
            passwordSubmitMessage: '',
            passwordSubmitMessageDisplay: false,
            passwordSubmitStatus: null
        }
    }

    componentWillMount() {
        // Check for params in URL
        // sample ss - bmlja0Bkb29yc3RlcC5jby5rZSsxNTU2NTM5ODgx
    }

    componentDidMount() {
		// Update page title
        document.title = this.state.pageTitle;      
        let ss = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).ss;

        if(ss) {
            // Decode
            let decodedData = window.atob(ss);
            decodedData = decodedData.split('+');

            if(decodedData.length === 2) {
                // Get email & timestamp
                let email = decodeURIComponent(decodedData[0]);
                let timestamp = parseInt(decodedData[1]) ? parseInt(decodedData[1]) : null;
                timestamp = moment.unix(timestamp);

                // Check difference in timestamps
                if((moment().unix() - moment(timestamp).unix()) < 1800) {
                    // Show change password form
                    this.setState({
                        email: email,
                        showPasswordsForm: true
                    })
                } else {
                    // Expired token
                    this.setState({
                        showTokenError: true,
                        tokenErrorMessage: 'Your security token has expired. Please try again.'
                    })
                }
            } else {
                // Expired token
                this.setState({
                    showTokenError: true,
                    tokenErrorMessage: 'There was an error with your security token. Please retry. If the issue persists, please contact support at support@ticketsasa.com'
                })
            }
        }  
    }

    toggleBlocking = () => {
        this.setState({blocking: !this.state.blocking});
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
            [name]: value
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();

        // Submit merchant email & base URL
        let merchantBaseUrl = "https://merchants.ticketsasa.com";
        let url = "https://www.ticketsasa.com/index.php?option=com_enmasse&controller=merchantservice&task=resetPassword";

        var dataObj = {
            'merchantBaseUrl': merchantBaseUrl,
            'email' : this.state.email ? this.state.email : ''
        }

        this.toggleBlocking();

		axios({
            method: 'POST',
            url: url,
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(dataObj)
		})
        .then(response => {
            this.toggleBlocking();
            if(response.data.status === 'success') {
                this.setState({
                    resetMessage: response.data.message
                });
                this.setState({showMessage: true});
            } else if(response.data.status === 'error') {
                // Show error message
                this.setState({
                    resetMessage: response.data.message ? response.data.message : 'There was an error reseting your password.',
                });
                this.setState({showMessage: false});
            }

            // Show success message
           
        })
        .catch(error => {
            this.toggleBlocking();
            alert(error);
        });
    }

    handlePasswordsSubmit = (event) => {
        event.preventDefault();

        // Submit new passwords
        let url = "https://www.ticketsasa.com/index.php?option=com_enmasse&controller=merchantservice&task=updateUserPassword";

        let dataObj = {
            'email' : this.state.email ? this.state.email : '',
            'password' : this.state.password ? this.state.password : '',
            'confirmPassword' : this.state.confirmPassword ? this.state.confirmPassword : ''
        }

        this.toggleBlocking();

		axios({
            method: 'POST',
            url: url,
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(dataObj)
        })
        .then(response => {
            // console.log(response)
            // Show message
            if(response.data.status === 'success') {
                this.setState({
                    passwordSubmitMessage: 'Password was successful changed.',
                    passwordSubmitMessageDisplay: true,
                    passwordSubmitStatus: 'success'
                })
            } else if(response.data.status === 'error') {
                // Show error message
                this.setState({
                    passwordSubmitMessage: response.data.message ? response.data.message : 'There was an error updating your password.',
                    passwordSubmitMessageDisplay: true,
                    passwordSubmitStatus: 'danger'
                })
            }

            this.toggleBlocking();            
        })
        .catch(error => {
            this.toggleBlocking();
            alert(error);
        });
    }

    render() {
        return(
            <BlockUi tag="div" blocking={this.state.blocking}>
                
                <div className="">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-3"></div>
                            <div className="col-md-6">
                                <center>
                                    <Link to="/"><img src={whiteLogo} alt="Ticketsasa"></img></Link>
                                </center>
                                <br /><br />
                                <Alert color="danger" style={{display: this.state.showTokenError ? '' : 'none'}}>
                                    {this.state.tokenErrorMessage}
                                </Alert>
                                <div className="card">
                                    <div className="card-block">
                                    {this.state.showMessage=== true ? <Alert color="success">{'An email with instructions to reset your password has been sent.'}</Alert>:"" }
                                    {this.state.showMessage === false ? <Alert color="danger">{this.state.resetMessage}</Alert>:"" }

                                        <Alert color="success" style={{display: this.state.showMessage ? '' : 'none'}}>
                                            {'An email with instructions to reset your password has been sent.'}
                                        </Alert>
                                        <form className="form-horizontal form-material" onSubmit={this.handleSubmit} style={{display: this.state.showPasswordsForm ? 'none' : ''}}>
                                            <div className="form-group">
                                                <label htmlFor="example-email" className="col-md-12">Email</label>
                                                <div className="col-md-12">
                                                    <input type="email" placeholder="" className="form-control form-control-line" name="email" value={this.state.email} onChange={this.handleInputChange} required autoComplete="off" />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="col-md-12">
                                                    <button className="btn btn-success" type="submit">Reset</button>
                                                    <br /><br />

                                                    <p><Link to="/login">Go back to login page</Link></p>
                                                </div>
                                            </div>
                                        </form>

                                        <form className="form-horizontal form-material" onSubmit={this.handlePasswordsSubmit} style={{display: this.state.showPasswordsForm ? '' : 'none'}}>
                                            <Alert color="danger" style={{display: (this.state.password === this.state.confirmPassword) ? 'none' : ''}}>
                                                {'Passwords do not match.'}
                                            </Alert>
                                            <Alert color={this.state.passwordSubmitStatus ? this.state.passwordSubmitStatus : 'info'} style={{display: this.state.passwordSubmitMessageDisplay ? '' : 'none'}}>
                                                {this.state.passwordSubmitMessage}
                                            </Alert>
                                            <div className="form-group">
                                                <label htmlFor="example-email" className="col-md-12">Password</label>
                                                <div className="col-md-12">
                                                    <input type="password" placeholder="" className="form-control form-control-line" name="password" value={this.state.password} onChange={this.handleInputChange} required autoComplete="off" />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="example-email" className="col-md-12">Confirm Password</label>
                                                <div className="col-md-12">
                                                    <input type="password" placeholder="" className="form-control form-control-line" name="confirmPassword" value={this.state.confirmPassword} onChange={this.handleInputChange} required autoComplete="off" />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="col-sm-12">
                                                    <button className="btn btn-success" type="submit">Update Password</button>
                                                    <br /><br />
                                                    
                                                    <p><Link to="/login">Go back to login page</Link></p>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                            </div>
                        </div>
                    </div>
                </div>

            </BlockUi>
        )
    }
}

export default ResetPassword;
