import React, {Component} from 'react'
import axios from 'axios';
import qs from 'qs';
import moment from 'moment'
import { Input, Alert } from 'reactstrap'

class TicketDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            username: '',
			addTickets: [
				{count: 0, name: 'ticketName1', priceName: 'ticketPrice1', ticketsAvailable: 'ticketsAvailable1', soldOut: 'soldOut1', startDate: 'startDate1', startTime: 'startTime1', endDate: 'endDate1', endTime: 'endTime1'}
			],
			formErrors: [],
			eventId: null,
			eventDetails: {}
        }
    }

	componentWillReceiveProps(nextProps) {
        // Check if the events array matches what's been passed
        if(nextProps.eventId !== this.state.eventId) {
			this.setState({eventId: nextProps.eventId});
			
			// Get ticket data if this is available
			if(nextProps.eventId) {
				this.getTicketTypes(nextProps.eventId, localStorage.getItem('username'))
			}
        }

        if(nextProps.eventDetails !== this.state.eventDetails) {
            this.setState({eventDetails: nextProps.eventDetails});
		}

        if(nextProps.userName !== this.state.username) {
            this.setState({username: nextProps.userName});
        }
	}

	getTicketTypes = (eventId = null, userName = null) => {
        let url = "https://www.ticketsasa.com/index.php?option=com_enmasse&controller=merchantservice&task=getEventTicketTypes";

        var dataObj = {
			'user_id' : this.state.username ? this.state.username : userName,
			'deal_id' : eventId
        }

		axios({
            method: 'POST',
            url: url,
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(dataObj)
		})
        .then(response => {
			// console.log(response)
            // Check status
            if(response.data.status === "success") {
				// Loop over the results and populate the addTickets array of objects
				// Adding an extra ticket type
				if(response.data.data.ticket_data.length > 0) {
					const ticketsCount = response.data.data.ticket_data.length
					let ticketsArray = []
					const tickets = response.data.data.ticket_data

					for(let ij = 0; ij < ticketsCount; ij++) {
						let ticketObj = {count: ticketsCount, name: 'ticketName'+(ij+1), priceName: 'ticketPrice'+(ij+1), ticketsAvailable: 'ticketsAvailable'+(ij+1), soldOut: 'soldOut'+(ij+1), startDate: 'startDate'+(ij+1), startTime: 'startTime'+(ij+1), endDate: 'endDate'+(ij+1), endTime: 'endTime'+(ij+1)}
						ticketsArray.push(ticketObj)

						// Create form data
						this.setState({
							[ticketObj.name]: tickets[ij].name,
							[ticketObj.priceName]: tickets[ij].price,
							[ticketObj.ticketsAvailable]: tickets[ij].number_of_tickets,
							[ticketObj.soldOut]: tickets[ij].sold_out,
							[ticketObj.startDate]: moment(tickets[ij].start_date).format('YYYY-MM-DD'),
							[ticketObj.startTime]: moment(tickets[ij].start_date).format('HH:mm'),
							[ticketObj.endDate]: moment(tickets[ij].end_date).format('YYYY-MM-DD'),
							[ticketObj.endTime]: moment(tickets[ij].end_date).format('HH:mm')
						})
					}

					this.setState({
						addTickets: ticketsArray
					});
				}

            } else if(response.data.status === "error") {
                // Error

            } else {
                // Error
            }
        })
        .catch(error => {
        });
	}

    toggleBlocking = () => {
        this.props.toggleBlocking()
    }

	handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
	
		this.setState({
			[name]: value
		});
	}

	handleTicketDataSubmit = (event) => {
		event.preventDefault();

		// Validate form
		let validateForm = this.validateTicketData()
		
		this.setState({
			formErrors: validateForm
		})

		if(validateForm.length < 1) {
			this.toggleBlocking()

			// If there are no errors
			// Submit form details
			let url = "https://www.ticketsasa.com/index.php?option=com_enmasse&controller=merchantservice&task=manageTicketTypes"

			// Getting ticket data
			let ticketTypes = []

			this.state.addTickets.forEach(ticketType => {
				// Format date strings
				let startDate, endDate
				
				startDate = moment(this.state[ticketType.startDate] + ' ' + this.state[ticketType.startTime]).format('YYYY-MM-DD HH:mm:ss')
				endDate = moment(this.state[ticketType.endDate] + ' ' + this.state[ticketType.endTime]).format('YYYY-MM-DD HH:mm:ss')

				// Adding data to TicketTypes array
				ticketTypes.push({name: ticketType.name, nameValue: this.state[ticketType.name], price: ticketType.priceName, priceValue: this.state[ticketType.priceName], ticketsAvailable: ticketType.ticketsAvailable, ticketsAvailableValue: this.state[ticketType.ticketsAvailable], soldOut: ticketType.soldOut, soldOutValue: this.state[ticketType.soldOut], startDate: ticketType.startDate, startDateValue: startDate, endDate: ticketType.endDate, endDateValue: endDate})
			});

			var dataObj = {
				'user_id': this.state.username,
				'ticketTypes': JSON.stringify(ticketTypes),
				'dealId': this.state.eventId
			}

			axios({
				method: 'POST',
				url: url,
				headers: { 'content-type': 'application/x-www-form-urlencoded' },
				data: qs.stringify(dataObj)
			})
			.then(response => {
				// console.log(response)
				// Check response
				if(response.data.status === 'success') {
					this.setState({
						formSubmittedStatus: 'success',
						formSubmittedShow: true,
						formSubmittedMessageColour: 'success',
						formSubmittedMessage: 'Your new event data was successfully submitted! Track progress on the events page.'
					})

				} else if(response.data.status === 'error') {
					this.setState({
						formSubmittedStatus: 'error',
						formSubmittedShow: true,
						formSubmittedMessageColour: 'danger',
						formSubmittedMessage: response.data.message ? response.data.message : 'There was an error submitting your new event data'
					})

				} else {
					// Show error (something went wrong)
					this.setState({
						formSubmittedStatus: 'error',
						formSubmittedShow: true,
						formSubmittedMessageColour: 'danger',
						formSubmittedMessage: 'There was an error submitting your new event data.'
					})
				}

				this.toggleBlocking()
			})
			.catch(error => {
				// Show error (something went wrong)
				this.setState({
					formSubmittedStatus: false,
					formSubmittedMessage: 'There was an error submitting your new event data.'
				})

				this.toggleBlocking()
			})
		}

	}

	validateTicketData = () => {
		let errorArray = []

		// 3. Ticket data & pricing
		if(this.state.addTickets.length > 0) {
			// Validate each entry
			let ticketTypeErrors = []

			// Looping over each and validating
			this.state.addTickets.forEach(ticketType => {
				// Check ticket types 
				// Check for a ticket name && a valid price
				// console.log('state: ', this.state)
				if(!this.state[ticketType.name] || !this.state[ticketType.priceName] || !this.state[ticketType.ticketsAvailable] || !this.state[ticketType.soldOut] || !this.state[ticketType.startDate] || !this.state[ticketType.startTime] || !this.state[ticketType.endDate] || !this.state[ticketType.endTime]) {
					// TODO: Add ticket details 
					ticketTypeErrors.push({fieldName: ticketType.name, errorMessage: 'Please enter missing ticket type details.'})
				}
				
			});

			// Check if there are any errors within the ticket types submitted
			if(ticketTypeErrors.length > 0) {
				// Show error message
				errorArray.push({errorType: 'ticketDetails', errorTitle: 'Ticket Types & Pricing', errorMessage: 'Please enter at least one ticket type and make sure the name and price are input.', errorData: ticketTypeErrors});

				this.setState({
					validTicketTypes: false 
				});
			}

		} else {
			// If there is no ticket type
			errorArray.push({errorType: 'ticketDetails', errorTitle: 'Ticket Types & Pricing', errorMessage: 'Please enter at least one ticket type.'});

			this.setState({
				validTicketTypes: false 
			});
		}

		return errorArray.length > 0 ? errorArray : []

	}

	addTicketType = () => {
		// Adding an extra ticket type
		if(this.state.addTickets.length < 5) {
			const ticketsCount = this.state.addTickets.length
			const addTickets = [
				...this.state.addTickets, 
				{count: ticketsCount, name: 'ticketName'+(ticketsCount+1), priceName: 'ticketPrice'+(ticketsCount+1), ticketsAvailable: 'ticketsAvailable'+(ticketsCount+1), soldOut: 'soldOut'+(ticketsCount+1), startDate: 'startDate'+(ticketsCount+1), startTime: 'startTime'+(ticketsCount+1), endDate: 'endDate'+(ticketsCount+1), endTime: 'endTime'+(ticketsCount+1)}
			];

			this.setState({
				addTickets
			});
		}
	}

	deleteTicketType = () => {
		// Removing a ticket type
		let addTickets = [...this.state.addTickets]

		if(addTickets.length > 1) {
			this.state.addTickets.pop()
			this.setState({addTickets: this.state.addTickets})
		}
	}

    render() {
        return (

            <form className="form-horizontal form-material" onSubmit={this.handleTicketDataSubmit} name="ticketDetailsForm" style={{display: this.state.eventId ? '' : 'none'}}>
				<Alert color={this.state.formSubmittedMessageColour} style={{display: this.state.formSubmittedShow ? '' : 'none'}}>
					{this.state.formSubmittedMessage}
				</Alert>

				{
					// Looping over errors form errors if any
					this.state.formErrors.map((errorDetails, key) => {
						return (
							<Alert color="danger" key={key}>
								{errorDetails.errorMessage}
							</Alert>
						)
					})
				}

                <p>Please enter the ticket types &amp; their corresponding prices for your event</p>

                {
                    this.state.addTickets.map((ticket, key) => {
                        return (
							<div key={key} className="col-md-12">
								<div className="row">
									<div className="col-md-5">
										<div className="form-group">
											<label className="col-md-12"><strong>{(key + 1)}. Ticket Name</strong></label>
											<input type="text" placeholder="" className="form-control form-control-line" value={this.state[ticket.name]} name={ticket.name} onChange={this.handleInputChange} />
										</div>
									</div>
									<div className="col-md-2">
										<div className="form-group">
											<label className="col-md-12"><strong>Price</strong></label>
											<input type="number" placeholder="" className="form-control form-control-line" value={this.state[ticket.priceName]} name={ticket.priceName} onChange={this.handleInputChange} min="0" max="70000" />
										</div>
									</div>
									<div className="col-md-3">
										<div className="form-group">
											<label className="col-md-12"><strong>Tickets Available</strong></label>
											<input type="number" placeholder="" className="form-control form-control-line" value={this.state[ticket.ticketsAvailable]} name={ticket.ticketsAvailable} onChange={this.handleInputChange} min="0" max="70000" />
										</div>
									</div>
									<div className="col-md-2">
										<div className="form-group">
											<label className="col-md-12"><strong>Sold Out</strong></label>
											<Input type="select" placeholder="" className="form-control form-control-line" value={this.state[ticket.soldOut]} name={ticket.soldOut} onChange={this.handleInputChange}>
												<option value="">- Please select an option -</option>
												<option value="0">No</option>
												<option value="1">Yes</option>
											</Input>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-3">
										<div className="form-group">
											<label className="col-md-12"><strong>Start Date</strong></label>
											<input type="date" placeholder="" className="form-control form-control-line" value={this.state[ticket.startDate]} name={ticket.startDate} onChange={this.handleInputChange} />
										</div>
									</div>
									<div className="col-md-3">
										<div className="form-group">
											<label className="col-md-12"><strong>Start Time</strong></label>
											<input type="time" placeholder="" className="form-control form-control-line" value={this.state[ticket.startTime]} name={ticket.startTime} onChange={this.handleInputChange} />
										</div>
									</div>
									<div className="col-md-3">
										<div className="form-group">
											<label className="col-md-12"><strong>End Date</strong></label>
											<input type="date" placeholder="" className="form-control form-control-line" value={this.state[ticket.endDate]} name={ticket.endDate} onChange={this.handleInputChange} />
										</div>
									</div>
									<div className="col-md-3">
										<div className="form-group">
											<label className="col-md-12"><strong>End Time</strong></label>
											<input type="time" placeholder="" className="form-control form-control-line" value={this.state[ticket.endTime]} name={ticket.endTime} onChange={this.handleInputChange} />
										</div>
									</div>
								</div>
								<br /><br />
							</div>
                        )
                    })
                }

                <button type="button" className="btn btn-info" onClick={this.addTicketType}>Add new ticket type </button>
                &nbsp;
                <button type="button" className="btn btn-warning" onClick={this.deleteTicketType}>Delete ticket type</button>

				<br /><br />

                <button type="submit" className="btn btn-success">Submit Ticket Details </button>
                &nbsp;
                <button type="reset" className="btn btn-default">Reset Form </button>

            </form>
        )
    }
}

export default TicketDetails
