import React, { Component } from 'react';
import { Redirect, Link  } from 'react-router-dom';
import { Alert } from 'reactstrap';
import axios from 'axios';
import qs from 'qs';
import Footer from '../../../components/Footer/Footer.jsx';
import TopMenu from '../../../components/TopMenu/TopMenu.jsx';
import Sidebar from '../../../components/Sidebar/Sidebar.jsx';
import ReactHtmlParser from 'react-html-parser';

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

class ComplimentaryTickets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle: 'Event Complimentary Tickets - Ticketsasa Merchants',
            eventId: null,
            comps:[],
            isLoggedIn: false,
            loginRedirect: false,
            activeTab: '1',
            merchant: {},
            deal:{},
            blocking: false,
            message:"",
            totalComps:0,
            createComps:0
        }

        this.getCompsData = this.getCompsData.bind(this);
        this.getEvent = this.getEvent.bind(this);
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.checkIfLoggedIn = this.checkIfLoggedIn.bind(this);
    }

    componentWillMount() {
        const { eventId } = this.props.match.params;
         this.setState({eventId: eventId});
     }

    componentDidMount() {
        this.checkIfLoggedIn();
        this.getEvent();

        // Get single event orders
        this.getCompsData();
    }

    checkIfLoggedIn() {
        // Check local storage for the isLoggedIn value
        if(localStorage.getItem('isLoggedIn')) {
            // Get user details
            this.setState({merchant: JSON.parse(localStorage.getItem('merchant'))}); // Converting JSON string to object and storing it to state
            this.setState({name: localStorage.getItem('name')});
            this.setState({email: localStorage.getItem('email')});
            this.setState({username: localStorage.getItem('username')});
 
 
        } else {
            // Redirect to the login page with an error message
            this.setState({loginRedirect: true});
        }
    }

    toggleBlocking() {
        this.setState({blocking: !this.state.blocking});
    }

    getEvent(){
        // this.toggleBlocking();
        // Make call to api
       var url = 'https://www.ticketsasa.com/index.php?option=com_enmasse&controller=merchantservice&task=getEventDetails';

       var dataObj = {
           'user_id': JSON.parse(localStorage.getItem('merchant')).user_name,
           'deal_id': this.state.eventId

       }

       // Send a POST request
       axios({
           method: 'POST',
           url: url,
           headers: { 'content-type': 'application/x-www-form-urlencoded' },
           data: qs.stringify(dataObj)
       })
       .then(response => {
           // Display response
            
           // Store data in state    
           if(response.data.success) {

                this.setState({deal: response.data.deal})
                var EventDate = response.data.deal.end_at;
                var CurrentDate = new Date();
                EventDate = new Date(EventDate);
                if(EventDate > CurrentDate)this.setState({createComps: 1});
                
           }

           // Remove UI blocker
        //    this.toggleBlocking();
       })
       .catch(error => {
           // Hanandle error
           alert(error);
           
           // Remove UI blocker
        //    this.toggleBlocking();
       })
       .then(data => {
           // always executed
        //    this.toggleBlocking();

       });
    }
    
    getCompsData() {
        // Get single event orders
        // Add loader
        this.toggleBlocking();

         // Make call to api
        var url = 'https://www.ticketsasa.com/index.php?option=com_enmasse&controller=merchantservice&task=getEventComps';

        var dataObj = {
            'user_id': JSON.parse(localStorage.getItem('merchant')).user_name,
            'deal_id': this.state.eventId

        }

		// Send a POST request
		axios({
            method: 'POST',
            url: url,
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(dataObj)
		})
        .then(response => {
            // Display response
             
            // Store data in state    
            if(response.data.success) {
                 this.setState({comps: response.data.comps});
            }

            // Remove UI blocker
            this.toggleBlocking();
        })
        .catch(error => {
            // Hanandle error
            alert(error);
            
            // Remove UI blocker
            this.toggleBlocking();
        })
        .then(data => {
            // always executed
            // this.toggleBlocking();

        });
    }     

    render () {
        const { loginRedirect } = this.state;

        if (loginRedirect) {
            return <Redirect to='/login?isloggedin=false'/>;
        }

        const ListComps  = () => {
            var varTotalC=0;  
            const getList = this.state.comps.map((comp,key) => {
              
             varTotalC=varTotalC+ comp.tickets;
                return (
                    <tr key={key}>
                        <td style={{width:'50px'}}><span className="round">{key+1}</span></td>

                        <td>
                            <h6>{comp.shop ? ReactHtmlParser(comp.shop) : ''}</h6>
                            <small className="text-muted"><strong> </strong> {comp.email ? comp.email : ''}</small><br />
                         </td>
                        <td>
                            <p>{isNaN(comp.ticket_type )? comp.ticket_type : comp.description}</p>
                            <small className="text-muted"> Order ID: <strong> {comp.order_id ? comp.order_id : 'N/A'}</strong></small>

                        </td>
                        <td>
                            <p>{comp.tickets ? comp.tickets : 0}</p>
                        </td>
                        <td>
                            <p>{comp.amount ? comp.amount : 0}</p>

                        </td>
                        <td>
                            <p>{comp.amount * comp.tickets}</p>
 
                        </td>
                       
                    </tr>
                )
            })
            /* this.setState((prevState) => {
                console.log(varTotalC);
                return { totalComps: prevState.totalComps + varTotalC }
            }) */
            return (getList);
        }
        
        return (

            <BlockUi tag="div" blocking={this.state.blocking}>
                <div id="main-wrapper">
                    <TopMenu />
                    <Sidebar />

                    <div className="page-wrapper" style={{minHeight: "643px"}}>
                        
                        <div className="container-fluid">
                            <div className="row page-titles">
                                <div className="col-md-12 col-12 align-self-center">
                                    <h3 className="text-themecolor m-b-0 m-t-0">Complimentary Tickets</h3>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item"><Link to="/events">Events</Link></li>
                                        <li className="breadcrumb-item"><Link to={"/event/" + this.state.eventId}>{this.state.deal.name ? this.state.deal.name : "Single Event"}</Link></li>
                                        <li className="breadcrumb-item active"> Complimentary Tickets</li>
                                    </ol>
                                </div> 
                            </div>

                            <div className="row">
                            
                                <div className="col-sm-12">
                                <div className="card">
                                    
                                    {this.state.message === "success"? <Alert color="success">{"Update Successful"} </Alert>:"" }
                                    {this.state.message === "failed"? <Alert color="warning">{"Update Failed"} </Alert>:"" }

                                </div>
                                {this.state.createComps === 1 ?
                                <div className="row">
                                        <div className="col-md-6">
                                            <Link to={"/event/"+this.state.eventId+"/comps/new"} className="btn btn-default"><i className="fa fa-plus" aria-hidden="true"></i> Generate Complimentary Tickets</Link>
                                        </div>                                    
                                </div>:"" }

                                    <br />

                                    <div className="card">
                                        <div className="card-block">
                                            <h4 className="card-title">{this.state.deal.name}</h4>
                                            <h6 className="card-subtitle">List of all complimentary ticket types</h6>
                                            {/* <form className="form-horizontal form-material" onSubmit={this.handleSubmit}> */}
                                                <table className="table stylish-table">
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th>Name</th>
                                                            <th>Ticket Type</th>
                                                            <th>Quantity</th>
                                                            <th>Amount</th>
                                                            <th>Total</th>
                                                        </tr>
                                                    </thead>
                                                        <tbody>
                                                            <ListComps />
                                                        </tbody>
                                                </table>
                                            {/* </form> */}
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>

                        <Footer />
                    </div>
                </div>
            </BlockUi>
                
        )
    }
}

export default ComplimentaryTickets;

