import React, { Component } from 'react'
import axios from 'axios'
import qs from 'qs'
import moment from 'moment'

class EventDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            blocking: false,
            eventDetails: {},
            eventId: null
        }

        this.getEventDetails = this.getEventDetails.bind(this)
		this.toggleBlocking = this.toggleBlocking.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        if(this.state.eventId !== nextProps.eventId) {
            // Update
            this.setState({eventId: nextProps.eventId})
            this.getEventDetails(nextProps.eventId)
        }
    }

    getEventDetails(eventId) {
        // Getting event details
        // Block UI
        this.toggleBlocking();

        let url = 'https://www.ticketsasa.com/index.php?option=com_enmasse&controller=merchantservice&task=getEventDetails';

        var dataObj = {
            'deal_id': eventId,
            'user_id': localStorage.getItem('username')
        }

		axios({
            method: 'POST',
            url: url,
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(dataObj)
		})
        .then(response => {
            // Prepare data
            // Check if request was successful
            if(response.data.success) {
                // Get required event data
                this.setState({eventDetails: response.data.deal})

            } else {
                // Get error details
                // TODO: Display TOAST message on the page
                alert('There was an error getting event data.')

            }
        })
        .catch(error => {
            // Display error message on the page
            alert(error);

        })
        .then(data => {
            // Always executed
            this.toggleBlocking();
        });
    }

    toggleBlocking() {
        this.setState({blocking: !this.state.blocking});
    }

    render() {
        return (

            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-block">
                            <h4 className="card-title">{this.state.eventDetails.name}</h4>
                            <h6 className="card-subtitle">{this.state.eventDetails.deal_code}</h6>

                            <div className="row">

                                <div className="col-md-6">
                                    {/* <p></p> */}
                                    <img src={this.state.eventDetails ? decodeURIComponent(this.state.eventDetails.imageBase) + this.state.eventDetails.pic_dir : this.state.eventDetails.name} alt={this.state.eventDetails.name} height="150" width="150"></img>
                                </div>

                                <div className="col-md-6">
                                    <p><strong>Location</strong> {this.state.eventDetails.loc}</p>
                                    <p><strong></strong></p>
                                    <p><strong>Deal Code</strong> {this.state.eventDetails.deal_code}</p>
                                    <p><strong>Start At</strong> {moment(this.state.eventDetails.start_at).format('ddd, Do MMM YYYY h:mma')}</p>
                                    <p><strong>End At</strong> {moment(this.state.eventDetails.end_at).format('ddd, Do MMM YYYY h:mma')}</p>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

                <div className="col-sm-12">

                    <div className="card">
                        <div className="card-block">
                            <h4 className="card-title">Description</h4>
                            <p dangerouslySetInnerHTML={{__html: this.state.eventDetails.description}}></p>
                        </div>
                    </div>

                </div>

            </div>
        
        )
    }
}

export default EventDetails
