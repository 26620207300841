import React, { Component } from 'react';
import { Alert, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';

class RecentOrders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderList: [],
            currentPage: 1,
            ordersPerPage: 10,
            showPagination: true,
            filterCompsOnly: null
        }
        this.handleClickPagination = this.handleClickPagination.bind(this);
        this.paginationPrevious = this.paginationPrevious.bind(this);
        this.paginationNext = this.paginationNext.bind(this);
    }

    handleClickPagination(event) {
		this.setState({
			currentPage: Number(event.target.id)
		});
    }

    paginationPrevious(event) {
        // console.log('Pagination previous clicked');
        this.setState({
            currentPage: this.state.currentPage - 1
        })
    }

    paginationNext(event) {
        // console.log('Pagination next clicked');
        this.setState({
            currentPage: this.state.currentPage + 1
        })
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.orderList !== this.state.orderList) {
            this.setState({orderList: nextProps.orderList});
        }

        if(nextProps.showPagination !== this.state.showPagination) {
            this.setState({showPagination: nextProps.showPagination});
        }

        if(nextProps.filterCompsOnly !== this.state.filterCompsOnly) {
            this.setState({filterCompsOnly: nextProps.filterCompsOnly});
        }
    }

    render() {
		const { orderList, currentPage, ordersPerPage } = this.state;
	
		// Logic for displaying orders
		const indexOfLastOrder = currentPage * ordersPerPage;
		const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
		const currentOrders = orderList.slice(indexOfFirstOrder, indexOfLastOrder);
	
		const RenderOrders = currentOrders.map((order, key) => {
            return (
                <tr key={key}>
                    <td><h6>{order.id}</h6></td>
                    <td>
                        <strong>{order.description ? ReactHtmlParser(order.description) : 'N/A'}</strong>
                    </td>
                    <td>
                        <h6>{order.delivery_detail ? JSON.parse(order.delivery_detail).name : 'N/A'}</h6>
                        <small className="text-muted">{order.delivery_detail ? JSON.parse(order.delivery_detail).email : 'N/A'}</small><br />
                        <small className="text-muted">{order.delivery_detail ? JSON.parse(order.delivery_detail).phone : 'N/A'}</small>
                    </td>
                    <td>{order.total_price ? new Intl.NumberFormat('en-KE', { style: 'currency', currency: 'KES', minimumFractionDigits: 2 }).format(parseInt(order.total_price)) : 0}</td>
                    <td>{order.status ? order.status : 'N/A'}</td>
                    <td>{moment(order.created_at).isValid() ? moment(order.created_at).format('ddd, Do MMM YYYY h:mma') : order.created_at}</td>
                </tr>
            )
        });
	
		// Logic for displaying page numbers
		const pageNumbers = [];
		for (let i = 1; i <= Math.ceil(orderList.length / ordersPerPage); i++) {
			pageNumbers.push(i);
        }
        
        const ShowPagination = props => {
            const showPagination = props.showPagination;
            const currentPage = props.currentPage;

            const PageNumbers = pageNumbers.map((number, key) => {
                return(
                    <PaginationItem key={key} active={currentPage === number ? true : false}>
                        <PaginationLink id={number} onClick={this.handleClickPagination}>
                            {number}
                        </PaginationLink>
                    </PaginationItem>
                )
            })
            
            return (
            
                <Pagination aria-label="Orders List Pagination" style={{display: showPagination ? orderList.length < 1 ? 'none' : '' : 'none'}}>
                    <PaginationItem>
                        <PaginationLink previous disabled={currentPage === 1 ? true : false} onClick={this.paginationPrevious}/>
                    </PaginationItem>
                    {PageNumbers}
                    <PaginationItem>
                        <PaginationLink next onClick={this.paginationNext} disabled={currentPage === pageNumbers[pageNumbers.length - 1] ? true : false}/>
                    </PaginationItem>
                </Pagination>

            )
        }

        return (

            <div className="table-responsive m-t-40">
                <Alert color="warning" style={{display: this.state.orderList.length < 1 ? '' : 'none'}}>
                    There are no orders to display at the moment <i ></i>
                </Alert>
            
                <ShowPagination showPagination={this.state.showPagination} currentPage={this.state.currentPage}></ShowPagination>

                <table className="table stylish-table" style={{display: this.state.orderList.length < 1 ? 'none' : ''}}>
                    <thead>
                        <tr>
                            <th>ID</th>   
                            <th>Ticket</th>
                            <th>Customer Details</th>
                            <th>Total Price</th>
                            <th>Status</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* <OrdersLoop orderList={this.state.orderList}></OrdersLoop> */}
                        {RenderOrders}
                    </tbody>
                </table>
            
                <ShowPagination showPagination={this.state.showPagination} currentPage={this.state.currentPage}></ShowPagination>
            </div>
    
        )
    }
}

export default RecentOrders;