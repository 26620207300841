import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: []
        }
    }

    componentWillReceiveProps(nextProps) {
 
        if(nextProps.users !== this.state.users) {
            this.setState({users: nextProps.users});
        }
     }

    render () {

        const ListUsers = props => {
            var users = props.users;

            const getUsers = users.map((user,key) => {
                return (
                    <tr key={key}>
                        <td style={{width:'50px'}}><span className="round">{user.name.charAt(0)}</span></td>

                        <td>
                            <h6>{ReactHtmlParser(user.name)}</h6>
                            <small className="text-muted"><strong>Role: </strong> {user.role}</small><br />
                         </td>
                        <td>
                            <p>{user.email}</p>
                        </td>
                        <td>
                        <small className="text-muted"> {user.lastvisitDate}</small>

                        </td>
                        <td>
                            <button className={user.status ? "btn-success btn" :  "btn-warning btn" }>{user.status? "Disable" : 'Enable' }</button>
                        </td>
                    </tr>
                )
            })

            return (getUsers);
        }

         

        return (

            <tbody>
                <ListUsers users={this.state.users}></ListUsers>
            </tbody>
        
        )
    }
}

export default Users;