import React, {Component} from 'react';
import { Redirect, Link } from 'react-router-dom';
import qs from 'qs';
import axios from 'axios';

import Footer from '../../components/Footer/Footer.jsx';
import TopMenu from '../../components/TopMenu/TopMenu.jsx';
import Sidebar from '../../components/Sidebar/Sidebar.jsx';

import RecentOrders from '../../components/Dashboard/RecentOrders';

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle: 'Dashboard - Ticketsasa Merchants',
            isLoggedIn: false,
            loginRedirect: false,
            merchant: {},
            email: '',
            name: '',
            username: '',
            users: [],
            totalUsers: 0,
            totalEvents: 0,
            totalSales: '',
            currency: '',
            recentOrders: [],
            blocking: false
        }

        this.checkIfLoggedIn = this.checkIfLoggedIn.bind(this);
        this.getMerchantSummary = this.getMerchantSummary.bind(this);
        this.toggleBlocking = this.toggleBlocking.bind(this);
    }

    componentWillMount() {
        this.checkIfLoggedIn();
    }

	componentDidMount() {
		// Update page title
		document.title = this.state.pageTitle;
	}

    toggleBlocking() {
        this.setState({blocking: !this.state.blocking});
    }

    checkIfLoggedIn() {
        // Check local storage for the isLoggedIn value
        if(localStorage.getItem('isLoggedIn')) {
            // Get user details
            this.setState({merchant: JSON.parse(localStorage.getItem('merchant'))}); // Converting JSON string to object and storing it to state
            this.setState({name: localStorage.getItem('name')});
            this.setState({email: localStorage.getItem('email')});
            this.setState({username: localStorage.getItem('username')});

            // Get merchant details
            this.getMerchantSummary(JSON.parse(localStorage.getItem('merchant')));  

        } else {
            // Redirect to the login page with an error message
            this.setState({loginRedirect: true});
        } 
    }

    getMerchantSummary(merchant) {
        // Get event details
        // Add loader
        this.toggleBlocking();

        // Getting event and ticket sales
        let url = 'https://www.ticketsasa.com/index.php?option=com_enmasse&controller=merchantservice&task=getSummary';

        var dataObj = {
            'user_id': merchant.user_name
        }

		axios({
            method: 'POST',
            url: url,
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(dataObj)
		})
        .then(response => {
            // Prepare data
            // Check if request was successful
            //console.log('Summary: ', response)

            if(response.data.response === "success") {
                // Get required event data
                // console.log('Summary: ', response.data);
                localStorage.setItem('currency', response.data.data.currency);
                this.setState({users: response.data.data.users});
                this.setState({totalUsers: response.data.data.totalUsers});
                this.setState({totalEvents: response.data.data.totalEvents});
                this.setState({totalSales: response.data.data.totalSales});
                this.setState({currency: response.data.data.currency});
                this.setState({recentOrders: response.data.data.recent_orders});

            } else {
                // Get error details
                // console.log('Error response: ', response);

                // TODO: Display TOAST message on the page
                // alert('There was an error getting event data.');

            }

            // Remove UI blocker
            this.toggleBlocking();
        })
        .catch(error => {
            // Display error message on the page
            // alert(error);

            // Remove UI blocker
            this.toggleBlocking();

        })
        .then(function () {
            // Always executed

            // Remove UI blocker
            // this.toggleBlocking();
        });
    }

    render () {
        const { loginRedirect } = this.state;

        if (loginRedirect) {
            return <Redirect to='/login?isloggedin=false'/>;
        }

        return (
            <BlockUi tag="div" blocking={this.state.blocking}>
                <div id="main-wrapper">
                    <TopMenu />
                    <Sidebar />
                    <div className="page-wrapper" style={{minHeight: "643px"}}>
                        <div className="container-fluid">
                            <div className="row page-titles">
                                <div className="col-md-6 col-8 align-self-center">
                                    <h3 className="text-themecolor m-b-0 m-t-0">Dashboard</h3>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active">Dashboard</li>
                                    </ol>
                                </div>
                                
                            </div>
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="card">
                                        <div className="card-block">
                                            <h4 className="card-title">Total Sales</h4>
                                            <div className="text-right">
                                                {/* <h2 className="font-light m-b-0"><i className="ti-plus text-success"></i> {this.state.totalSales ? new Intl.NumberFormat('en-KE', { style: 'currency', currency: 'KES' }).format(parseInt(this.state.totalSales)) : 'N/A'} </h2> */}
                                                <h2 className="font-light m-b-0"><i className="ti-plus text-success"></i> {this.state.totalSales ? this.state.currency + ' ' + new Intl.NumberFormat('en-KE', {minimumFractionDigits: 2}).format(this.state.totalSales) : 0} </h2>
                                                <span className="text-muted">Total Sales</span>
                                            </div>
                                            {/* <span className="text-success">80%</span> */}
                                            <div className="progress">
                                                <div className="progress-bar bg-success" role="progressbar" style={{width: '100%', height: '6px'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="card">
                                        <div className="card-block">
                                            <h4 className="card-title">Total Users</h4>
                                            <div className="text-right">
                                                <h2 className="font-light m-b-0"><i className="fa fa-users text-info"></i> {this.state.totalUsers ? this.state.totalUsers : 0} </h2>
                                                <span className="text-muted">Total Users</span>
                                            </div>
                                            {/* <span className="text-info">30%</span> */}
                                            <div className="progress">
                                                <div className="progress-bar bg-info" role="progressbar" style={{width: '100%', height: '6px'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="card">
                                        <div className="card-block">
                                            <h4 className="card-title">Total Events</h4>
                                            <div className="text-right">
                                                <h2 className="font-light m-b-0"><i className="fa fa-fort-awesome text-warning"></i> {this.state.totalEvents ? this.state.totalEvents : 0} </h2>
                                                <span className="text-muted">Total Events</span>
                                            </div>
                                            {/* <span className="text-info">30%</span> */}
                                            <div className="progress">
                                                <div className="progress-bar bg-warning" role="progressbar" style={{width: '100%', height: '6px'}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-block">
                                            {/* <select className="custom-select pull-right">
                                                <option defaultValue>January</option>
                                                <option value="1">February</option>
                                                <option value="2">March</option>
                                                <option value="3">April</option>
                                            </select> */}
                                            <h4 className="card-title">Recent Orders</h4>
                                            <h6 className="card-subtitle">List of most recent purchases</h6>
                                            <RecentOrders orderList={this.state.recentOrders} showPagination={false}></RecentOrders>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">

                                {/* <div className="col-lg-4">
                                    <div className="card">
                                        <img className="card-img-top img-responsive" src={cardImgOne} alt="Card" />
                                        <div className="card-block">
                                            <ul className="list-inline font-14">
                                                <li className="p-l-0">20 May {moment().year()}</li>
                                                <li><a href="javascript:void(0)" className="link">3 Comment</a></li>
                                            </ul>
                                            <h3 className="font-normal">How to Set up an Event(Step-by-step Guide)</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="card">
                                        <img className="card-img-top img-responsive" src={cardImgTwo} alt="Card" />
                                        <div className="card-block">
                                            <ul className="list-inline font-14">
                                                <li className="p-l-0">20 May {moment().year()}</li>
                                                <li><a href="javascript:void(0)" className="link">3 Comment</a></li>
                                            </ul>
                                            <h3 className="font-normal">How to Make Sense of Event Survey data</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="card">
                                        <img className="card-img-top img-responsive" src={cardImgThree} alt="Card" />
                                        <div className="card-block">
                                            <ul className="list-inline font-14">
                                                <li className="p-l-0">20 May {moment().year()}</li>
                                                <li><a href="javascript:void(0)" className="link">3 Comment</a></li>
                                            </ul>
                                            <h3 className="font-normal">Featured Merchants - {moment().format('MMMM YYYY')} </h3>
                                        </div>
                                    </div>
                                </div>
                            */}
                            </div>
                        </div>

                        <Footer />
                    </div>
                </div>
                        
            </BlockUi>
        )
    }
}

export default Home;
