import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Alert, Pagination, PaginationItem, PaginationLink } from 'reactstrap';

class EventsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventsArray: [],
            imageBase: 'https://www.ticketsasa.com/',
            currentPage: 1,
            eventsPerPage: 5,
            showPagination: true
        }
    }

	componentWillReceiveProps(nextProps) {
        // Check if the events array matches what's been passed
        if(nextProps.eventsArray !== this.state.eventsArray) {
            this.setState({eventsArray: nextProps.eventsArray});
        }

        if(nextProps.imageBase !== this.state.imageBase) {
            this.setState({imageBase: nextProps.imageBase});
        }

        if(nextProps.showPagination !== this.state.showPagination) {
            this.setState({showPagination: nextProps.showPagination});
        }
    }

    exportMasterlist(eventId = null) {
        // TODO: Add export from masterlist functionality
        alert('Masterlist successfully exported');

        // // Getting event and ticket sales
        // let url = 'https://www.ticketsasa.com/index.php?option=com_enmasse&controller=merchantservice&task=generateReport';

        // // Getting merchant
        // const merchant = JSON.parse(localStorage.getItem('merchant'));

        // var dataObj = {
        //     'user_id': merchant.user_name,
        //     'deal_id' : eventId ? eventId : ''
        // }

		// axios({
        //     method: 'POST',
        //     url: url,
        //     headers: { 'content-type': 'application/x-www-form-urlencoded' },
        //     data: qs.stringify(dataObj)
		// })
        // .then(response => {
        //     // Get response and display
        // })
        // .catch(error => {
        //     // TODO: Display error message on the page

        //     // Remove UI blocker
        //     // this.toggleBlocking();

        // });
    }

    handleClickPagination = (event) => {
		this.setState({
			currentPage: Number(event.target.id)
		});
    }

    paginationPrevious = (event) => {
        // console.log('Pagination previous clicked');
        this.setState({
            currentPage: this.state.currentPage - 1
        })
    }

    paginationNext = (event) => {
        // console.log('Pagination next clicked');
        this.setState({
            currentPage: this.state.currentPage + 1
        })
    }

    render () {
        const { eventsArray, currentPage, eventsPerPage } = this.state;
	
		// Logic for displaying events
		const indexOfLastOrder = currentPage * eventsPerPage;
		const indexOfFirstOrder = indexOfLastOrder - eventsPerPage;
		const currentEvents = eventsArray.slice(indexOfFirstOrder, indexOfLastOrder);

        // const EventsLoop = props => {
        //     var events = props.eventsArray;

            const eventTr = currentEvents.map((event,key) => {
                return (
                    <tr key={key}>
                        {/* <td style={{width:'50px'}}>{key+1}</td> */}
                        <td>
                            <Link to={{pathname: '/event/' + event.deal_id}} style={{color: 'inherit', textDecoration: 'none'}} >
                                <p><strong>{event.name}</strong></p><br/>
                                <img src={decodeURIComponent(this.state.imageBase) + event.pic_dir} alt={event.name} height="150" width="150"></img><br /><br />
                            </Link>
                        </td>
                        <td>
                            <Link to={{pathname: '/event/' + event.deal_id}} style={{color: 'inherit', textDecoration: 'none'}} >
                                {/* <small dangerouslySetInnerHTML={{__html: event.description}}></small> */}
                                <h6><small>{event.deal_code}</small></h6><br />
                                <p><strong>Start date</strong> {moment(event.start_at).format('ddd, Do MMM YYYY h:mma')} </p>
                                <p><strong>End date</strong> {moment(event.end_at).format('ddd, Do MMM YYYY h:mma')} </p>
                                <p><strong>Location</strong> {event.city_name + ' ' + event.country}</p>
                                
                                <button className="btn btn-success btn-sm" disabled style={{display: event.published === '1' ? '' : 'none'}}>Published</button><span> </span>
                                
                                <button className="btn btn-warning" disabled style={{display: (event.published === '0') ? '' : 'none'}}>{event.status ? event.status : 'Pending'}</button><span> </span>
                            </Link>
                            <Link to={'/event/' + event.deal_id} className="btn btn-default"><small>More Details</small></Link><br /><br />
                        </td>
                        <td>
                            <Link to={{pathname: '/event/' + event.deal_id}} style={{color: 'inherit', textDecoration: 'none'}} >
                                <table>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Tickets Bought</td>
                                            <td>{event.tickets_bought ? event.tickets_bought : 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>Comps Issued</td>
                                            <td>{event.comps_issued ? event.comps_issued : 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>Total</td>
                                            <td>{event.total ? event.currency.toUpperCase() + ' ' + new Intl.NumberFormat('en-KE', {minimumFractionDigits: 2}).format(event.total) : 'N/A'}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Link>
                        </td>
                        <td>
                            <button className="btn btn-default" onClick={this.exportMasterlist}disabled>Export Masterlist</button><br></br>
                            <Link to={'/event/' + event.deal_id + '/edit'} className="btn btn-default" style={{display: (event.published === '0') ? '' : 'none'}}>Edit Event</Link><br></br>
                            <Link to={'/event/' + event.deal_id + '/sales/'} className="btn btn-default">View Sales</Link><br></br>
                            <Link to={'/event/' + event.deal_id + '/survey'} className="btn btn-default">View Survey</Link><br></br>
                            <Link to={'/event/' + event.deal_id + '/comps'} className="btn btn-default">Manage Comps</Link><br></br>
                        </td>
                        {/* <td> */}
                            {/* <table>
                                <thead>
                                    <tr>
                                        <th>Ticket type</th>
                                        <th>Comps issued</th>
                                        <th>Worth</th>
                                        <th>Sold</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <TicketTypes ticketTypes={event.ticket_types} currency={event.currency} eventId={event.id}></TicketTypes> */}
                                    
                                    {/* <tr style={{display: event.complimentary_ticket_types.length < 1 ? 'none' : ''}}>
                                        <td><em>Complimentary Tickets</em></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr> */}
                                    
                                    {/* <ComplimentaryTickets tickets={event.complimentary_ticket_types} currency={event.currency} eventId={event.id}></ComplimentaryTickets> */}
                                    {/* <tr>
                                        <td></td>
                                        <td></td>
                                        <td>{this.state['totalTickets-' + event.id]}</td>
                                        <td style={{color: 'red'}}>KES {this.state['totalTicketSales-' + event.id]}</td>
                                    </tr> */}
                                    {/* <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>10</td>
                                        <td style={{color: 'red'}}>KES 50,000</td>
                                    </tr>
                                </tbody>
                            </table> */}
                        {/* </td> */}
                    </tr> 
                )
            })

        //     return (eventTr)
        // }
	
		// Logic for displaying page numbers
		const pageNumbers = [];
		for (let i = 1; i <= Math.ceil(eventsArray.length / eventsPerPage); i++) {
			pageNumbers.push(i);
        }
        
        const ShowPagination = props => {
            const showPagination = props.showPagination;
            const currentPage = props.currentPage;

            const PageNumbers = pageNumbers.map((number, key) => {
                return(
                    <PaginationItem key={key} active={currentPage === number ? true : false}>
                        <PaginationLink id={number} onClick={this.handleClickPagination}>
                            {number}
                        </PaginationLink>
                    </PaginationItem>
                )
            })
            
            return (
            
                <Pagination aria-label="Orders List Pagination" style={{display: showPagination ? eventsArray.length < 1 ? 'none' : '' : 'none'}}>
                    <PaginationItem>
                        <PaginationLink previous disabled={currentPage === 1 ? true : false} onClick={this.paginationPrevious}/>
                    </PaginationItem>
                    {PageNumbers}
                    <PaginationItem>
                        <PaginationLink next onClick={this.paginationNext} disabled={currentPage === pageNumbers[pageNumbers.length - 1] ? true : false}/>
                    </PaginationItem>
                </Pagination>

            )
        }
        
        return (
            <div className="table-responsive m-t-40">
                <Alert color="warning" style={{display: this.state.eventsArray.length < 1 ? '' : 'none'}}>
                    There were no events to display. Please create one or contact support to get started.
                </Alert>
            
                <ShowPagination showPagination={this.state.showPagination} currentPage={this.state.currentPage}></ShowPagination>

                <table className="table stylish-table" style={{display: this.state.eventsArray.length < 1 ? 'none' : ''}}>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Event details</th>
                            <th>Date & Location</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* Return the whole tbody record */}
                        {/* <EventsLoop eventsArray={this.state.eventsArray} imageBase={this.state.imageBase}></EventsLoop> */}
                        {/* Show events body */}
                        {eventTr}
                    </tbody>
                </table>
            
                <ShowPagination showPagination={this.state.showPagination} currentPage={this.state.currentPage}></ShowPagination>

            </div>
        )
    }
}

export default EventsTable;
