import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import playstoreImg from '../../assets/assets/images/download-app.png';

class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pathname: window.location.pathname,
            name: '',
            email: '',
            username: '',
            role: '',
            merchant: {}
        }
    }

    componentWillMount() {
        this.getUserDetails();
    }

    getUserDetails() {
        // Get user details from localStorage
        this.setState({name: localStorage.getItem('name')});
        this.setState({email: localStorage.getItem('email')});
        this.setState({merchant: JSON.parse(localStorage.getItem('merchant'))});
        this.setState({username: localStorage.getItem('username')});
        this.setState({role: localStorage.getItem('role')});
    }

    render () {
        return (
            <aside className="left-sidebar">
                <div className="scroll-sidebar">
                    <nav className="sidebar-nav">
                        <ul id="sidebarnav">
                            <li style={{display: this.state.role === "verify" ? 'none' : ''}}>
                                <Link to="/" className={this.state.pathname === "/" ? "waves-effect active" : "waves-effect"}><i className="fa fa-clock-o m-r-10" aria-hidden="true"></i><span className="menuDisapper">Dashboard</span></Link>
                            </li>
                            {/* <li>
                                <Link to="/profile" className="waves-effect"><i className="fa fa-user m-r-10" aria-hidden="true"></i>Profile</Link>
                            </li> */}
                            <li style={{display: this.state.role === "verify" ? 'none' : ''}}>
                                <Link to="/events" className={this.state.pathname.match(/event/gi) ? "waves-effect active" : "waves-effect"}><i className="fa fa-table m-r-10" aria-hidden="true"></i><span className="menuDisapper">Events</span></Link>
                            </li>
                            {/* <li>
                                <Link to="/sales" className="waves-effect"><i className="fa fa-list-alt m-r-10" aria-hidden="true"></i>Sales</Link>
                            </li> */}
                            <li>
                                <Link to="/verify" className={this.state.pathname === "/verify" || this.state.pathname === "/merchant/verify" ? "waves-effect active" : "waves-effect"}><i className="fa fa-check-square-o m-r-10" aria-hidden="true"></i><span className="menuDisapper">Verify</span></Link>
                            </li>
                            {/* <li>
                                <Link to="/comps" className="waves-effect"><i className="fa fa-columns m-r-10" aria-hidden="true"></i>Complementary Tickets</Link>
                            </li> */}
                            <li>
                                <Link to="/users" className={this.state.pathname.match(/users/gi) ? "waves-effect active" : "waves-effect"}><i className="fa fa-columns m-r-10" aria-hidden="true"></i><span className="menuDisapper">Users</span></Link>
                            </li> 
                        </ul>
                        
                        <div className="text-center m-t-30 menuDisapper">
                            <a href="https://play.google.com/store/apps/details?id=com.pesapal.ticketsasa" target="_blank" rel="noopener noreferrer" className="btn btn-link btn-sm"><img src={playstoreImg} alt="Download App"/><br />Download merchant app</a>
                        </div>
                    </nav>
                </div>
            </aside>
        )
    }
}

export default Sidebar;
