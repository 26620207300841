import React, {Component} from 'react';

class Footer extends Component {
    render () {
        return (
            <footer className="footer text-center">
                © 2010-{new Date().getFullYear()} Ticketsasa
            </footer>
        )
    }
}

export default Footer;
