import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Alert } from 'reactstrap';
import qs from 'qs';
import axios from 'axios';

import Footer from '../../components/Footer/Footer.jsx';
import TopMenu from '../../components/TopMenu/TopMenu.jsx';
import Sidebar from '../../components/Sidebar/Sidebar.jsx';

import TicketData from '../../components/SingleEvent/Sales/TicketData';
import EventDetails from '../../components/SingleEvent/EventDetails/EventDetails';

import BlockUi from 'react-block-ui'
import 'react-block-ui/style.css'

class SingleEvent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle : 'Single Event View - Ticketsasa Merchants',
            isLoggedIn: false,
            loginRedirect: false,
            merchant: {},
            eventId: null,
            ticketTypes: [],
            generalCompsIssued: null,
            eventDetails: {},
            blocking: false
        }

        this.getEventTickets = this.getEventTickets.bind(this);
        this.getEventDetails = this.getEventDetails.bind(this);
        this.exportMasterlist = this.exportMasterlist.bind(this);
        this.checkIfLoggedIn = this.checkIfLoggedIn.bind(this);
    }

    componentWillMount() {
        this.checkIfLoggedIn();
    }

    toggleBlocking = () => {
        this.setState({blocking: !this.state.blocking});
    }

    checkIfLoggedIn() {
        // Check local storage for the isLoggedIn value
        if(localStorage.getItem('isLoggedIn')) {
            // Get user details
            this.setState({merchant: JSON.parse(localStorage.getItem('merchant'))}); // Converting JSON string to object and storing it to state
            // this.setState({name: localStorage.getItem('name')});
            // this.setState({email: localStorage.getItem('email')});
            // this.setState({username: localStorage.getItem('username')});

        } else {
            // Redirect to the login page with an error message
            this.setState({loginRedirect: true});
        } 
    }

    componentDidMount() {
		// Update page title
        document.title = this.state.pageTitle;
        
        const { eventId } = this.props.match.params;
        this.setState({eventId: eventId});

        // Get event details
        this.getEventTickets(eventId);
        this.getEventDetails(eventId);
    }

    getEventTickets(eventId) {
        // Get event details
        // Getting event and ticket sales
        let url = 'https://www.ticketsasa.com/index.php?option=com_enmasse&controller=merchantservice&task=getEventTicketsData';

        var dataObj = {
            'deal_id': eventId
        }

		axios({
            method: 'POST',
            url: url,
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(dataObj)
		})
        .then(response => {
            // Prepare data
            // Check if request was successful

            if(response.data.success) {
                // Get required event data
                // console.log('Ticket data: ', response.data);

                this.setState({ticketTypes: response.data.ticket_types});
                this.setState({generalCompsIssued: response.data.general_complimentaries_issued});

            } else {
                // Get error details
                // console.log('Error response: ', response);

                // TODO: Display TOAST message on the page
                alert('There was an error getting event data.');

            }
        })
        .catch(error => {
            // Display error message on the page
            alert(error);

        });
    }

    getEventDetails(eventId) {
        // Getting event details
        // Block UI
        this.toggleBlocking();

        let url = 'https://www.ticketsasa.com/index.php?option=com_enmasse&controller=merchantservice&task=getEventDetails';

        var dataObj = {
            'deal_id': eventId,
            'user_id': localStorage.getItem('username')
        }

		axios({
            method: 'POST',
            url: url,
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(dataObj)
		})
        .then(response => {
            // Prepare data
            // Check if request was successful
            if(response.data.success) {
                // Get required event data
                this.setState({eventDetails: response.data.deal})

            } else {
                // Get error details
                // TODO: Display TOAST message on the page
                alert('There was an error getting event data.')

            }

            this.toggleBlocking()
        })
        .catch(error => {
            // Display error message on the page
            alert(error);
            
            this.toggleBlocking()

        });
    }

    exportMasterlist() {
        // TODO: Add export from masterlist functionality
        alert('Masterlist successfully exported');
    }
    
    render() {
        const { loginRedirect } = this.state;

        if (loginRedirect) {
            return <Redirect to='/login?isloggedin=false'/>;
        }

        return (

            <BlockUi tag="div" blocking={this.state.blocking}>

                <div id="main-wrapper">
                    <TopMenu />
                    <Sidebar />
                    <div className="page-wrapper" style={{minHeight: "643px"}}>
                        <div className="container-fluid">
                            <div className="row page-titles">
                                <div className="col-md-12 col-12 align-self-center">
                                    <h3 className="text-themecolor m-b-0 m-t-0">Events</h3>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item"><Link to="/events">Events</Link></li>
                                        <li className="breadcrumb-item active">{this.state.eventDetails.name ? this.state.eventDetails.name : 'Single Event'}</li>
                                    </ol>
                                </div>
                            </div>

                            {/* <div className="col-md-12"> */}
                                <div className="row">
                                    <div className="col-md-3">
                                        {/* <button className="btn btn-default" onClick={this.exportMasterlist}>Export Masterlist</button> */}
                                        <button className="btn btn-default" onClick={this.exportMasterlist} style={{opacity: '0.5', pointerEvents: 'none'}}><i className="fa fa-newspaper-o" aria-hidden="true"></i> Export Masterlist</button>
                                    </div>
                                    <div className="col-md-3">
                                        <Link to={'/event/' + this.state.eventId + '/sales/'} className="btn btn-default"><i className="fa fa-money" aria-hidden="true"></i> View Sales</Link>
                                    </div>
                                    <div className="col-md-3">
                                        <Link to={'/event/' + this.state.eventId + '/survey'} className="btn btn-default"><i className="fa fa-file-text-o" aria-hidden="true"></i> View Survey</Link>
                                    </div>
                                    <div className="col-md-3">
                                        <Link to={'/event/' + this.state.eventId + '/comps'} className="btn btn-default"><i className="fa fa-plus" aria-hidden="true"></i> Manage Comps</Link>
                                    </div>                                    
                                </div>
                            {/* </div> */}

                            <br />

                            {/* Event details component */}
                            <EventDetails eventId={this.state.eventId}></EventDetails>
                        
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-block">
                                            <h4 className="card-title">Ticket Sales Summary</h4>
                                            <h6 className="card-subtitle">Sales Summary</h6>
                                            <div className="table-responsive m-t-40">
                                                <Alert color="warning" style={{display: this.state.ticketTypes.length < 1 ? '' : 'none'}}>
                                                    There is no ticket data to display.
                                                </Alert>
                                                <table className="table stylish-table" style={{display: this.state.ticketTypes.length < 1 ? 'none' : ''}}>
                                                    <thead>
                                                        <tr>
                                                            <th>Ticket Type</th>
                                                            <th>Comps Issued</th>
                                                            {/* <th>Number of Tickets</th> */}
                                                            <th>Price</th>
                                                            <th>Sold</th>
                                                            <th>Total</th>
                                                            <th>Tickets Verified</th>
                                                        </tr>
                                                    </thead>
                                                    <TicketData ticketTypes={this.state.ticketTypes} generalCompsIssued={this.state.generalCompsIssued}></TicketData>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Footer />
                    </div>
                </div>
            
            </BlockUi>

        )
    }
}

export default SingleEvent;
