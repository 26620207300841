import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink, Alert } from 'reactstrap';
import classnames from 'classnames';
import axios from 'axios';
import Footer from '../../components/Footer/Footer.jsx';
import TopMenu from '../../components/TopMenu/TopMenu.jsx';
import Sidebar from '../../components/Sidebar/Sidebar.jsx';
import moment from 'moment'
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

class CreateNew extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pageTitle: 'Create New Event - Merchant Dashboard',
			isLoggedIn: false,
			loginRedirect: false,
			blocking: false,
			merchant: {},
			email: '',
			name: '',
			phone: '',
			contactEmail: '',
			contactName: '',
			contactPhoneNumber: '',
			username: '',
			eventId: null,
			activeTab: '1',
			eventName: '',
			eventDescription: '',
			eventVenue: '',
			eventStartDate: '',
			eventStartTime: '',
			eventTags: '',
			tags: '',
			addTickets: [
				{count: 0, name: 'ticketName1', priceName: 'ticketPrice1'}
			],
			eventPhoto: [],
			slideShowImage: [],
			sponsorImage: [],
			sponsorImages: [],
			multipleSponsorImages: false,
			formErrors: [],
			sendEmailTo: ''
		}

		this.toggle = this.toggle.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.checkIfLoggedIn = this.checkIfLoggedIn.bind(this);
		this.toggleBlocking = this.toggleBlocking.bind(this);
	}

	componentWillMount() {
		// Check if the user is logged in and get merchant details
		this.checkIfLoggedIn();
	}

	componentDidMount() {
		// Update page title
		document.title = this.state.pageTitle;        
	}

	checkIfLoggedIn() {
		// Check local storage for the isLoggedIn value
		if(localStorage.getItem('isLoggedIn')) {
			// Get user details
			this.setState({merchant: JSON.parse(localStorage.getItem('merchant'))}); // Converting JSON string to object and storing it to state
			this.setState({name: localStorage.getItem('name')});
			this.setState({email: localStorage.getItem('email')});
			this.setState({phone: localStorage.getItem('phone')});
			
			this.setState({contactName: localStorage.getItem('name')});
			this.setState({contactEmail: localStorage.getItem('email')});
			this.setState({contactPhoneNumber: localStorage.getItem('phone')});

		} else {
			// Redirect to the login page with an error message
			this.setState({loginRedirect: true});
		} 
	}

	toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
	}

    toggleBlocking() {
		// console.log(!this.state.blocking)
        this.setState({blocking: !this.state.blocking});
    }

	imageUpload = (event) => {
		const name = event.target.name
		this.setState({
			[name]: event.target.files // Array of objects. Objects with single image properties.
		})

		this.validateCreateEventForm();
	}

	handleInputChange(event) {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
	
		this.setState({
			[name]: value
		});

		this.validateCreateEventForm();
	}

	handleSubmit(event) {
		event.preventDefault();

		// Validate form
		let validateForm = this.validateCreateEventForm()
		
		this.setState({
			formErrors: validateForm
		})

		if(validateForm.length < 1) {
			this.toggleBlocking()

			// If there are no errors
			// Submit form details
			let url = "https://www.ticketsasa.com/index.php?option=com_enmasse&controller=merchantservice&task=createEvent"

			// Getting ticket data
			let ticketTypes = []

			this.state.addTickets.forEach(ticketType => {
				// Adding data to TicketTypes array
				ticketTypes.push({name: ticketType.name, nameValue: this.state[ticketType.name], price: ticketType.priceName, priceValue: this.state[ticketType.priceName]})
			});

			let formData = new FormData();

			formData.append('contactName', this.state.contactName);
			formData.append('contactEmail', this.state.contactEmail);
			formData.append('contactPhoneNumber', this.state.contactPhoneNumber);
			formData.append('eventName', this.state.eventName);
			formData.append('eventDescription', this.state.eventDescription);
			formData.append('eventVenue', this.state.eventVenue);
			formData.append('eventStartDate', this.state.eventStartDate);
			formData.append('eventStartTime', this.state.eventStartTime);
			formData.append('eventTags', this.state.eventTags);
			formData.append('eventPhoto', this.state.eventPhoto[0]);
			formData.append('ticketTypes', JSON.stringify(ticketTypes));
			formData.append('slideShowImage', this.state.slideShowImage[0]);
			formData.append('sponsorImageCount', this.state.multipleSponsorImages ?  this.state.sponsorImages.length :  this.state.sponsorImage.length);
			formData.append('merchantName', localStorage.getItem('merchantName'));
			formData.append('userName', localStorage.getItem('name'));
			formData.append('sendEmailTo', this.state.sendEmailTo);

			// Looping over sponsor images
			if(this.state.multipleSponsorImages) {
				for(let i = 0; i < this.state.sponsorImages.length; i++) {
					formData.append('sponsorImage'+i, this.state.sponsorImages[i]);
				}
			} else {
				formData.append('sponsorImage0',  this.state.sponsorImage[0]);
			}

			axios.post(url, formData)
			.then(response => {
				// Check response
				// console.log(response.data)

				if(response.data.status === 'success') {
					this.setState({
						formSubmittedStatus: 'success',
						formSubmittedShow: true,
						formSubmittedMessageColour: 'success',
						formSubmittedMessage: 'Your new event data was successfully submitted! Track progress on the events page.'
					})

				} else if(response.data.status === 'error') {
					this.setState({
						formSubmittedStatus: 'error',
						formSubmittedShow: true,
						formSubmittedMessageColour: 'danger',
						formSubmittedMessage: response.data.message ? response.data.message : 'There was an error submitting your new event data'
					})

				} else {
					// Show error (something went wrong)
					this.setState({
						formSubmittedStatus: 'error',
						formSubmittedShow: true,
						formSubmittedMessageColour: 'danger',
						formSubmittedMessage: 'There was an error submitting your new event data.'
					})
				}

				this.toggleBlocking()
			})
			.catch(error => {
				// Show error (something went wrong)
				this.setState({
					formSubmittedStatus: 'error',
					formSubmittedShow: true,
					formSubmittedMessage: 'There was an error submitting your new event data.'
				})

				this.toggleBlocking()
			})

		}
	}

	validateEmail(mail) {
		if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
			return true
		}
		return false
	}
	
	validateCreateEventForm = () => {
		let errorArray = []

		// Check for required data
		// 1. Contact details
		if(!this.state.contactName || !this.state.contactEmail || !this.state.contactPhoneNumber) {
			this.setState({
				validContactDetails: false 
			})
			
			errorArray.push({errorType: 'contactDetails', errorTitle: 'Contact Details', errorMessage: 'There are missing contact details.'})
		}

		// 2. Event details
		if(!this.state.eventName || !this.state.eventDescription || !this.state.eventStartDate || !this.state.eventStartTime || !this.state.eventVenue) {
			this.setState({
				validEventDetails: false 
			})
			errorArray.push({errorType: 'eventDetails', errorTitle: 'Event Details', errorMessage: 'Please enter the missing event details.'})
		}

		// 3. Ticket data & pricing
		if(this.state.addTickets.length > 0) {
			// Validate each entry
			let ticketTypeErrors = []

			// Looping over each and validating
			this.state.addTickets.forEach(ticketType => {
				// Check ticket types 
				// Check for a ticket name && a valid price
				if(!this.state[ticketType.name] || !this.state[ticketType.priceName]) {
					ticketTypeErrors.push({fieldName: ticketType.name, errorMessage: 'Please enter ticket type name and price.'})
				}
				
			});

			// Check if there are any errors within the ticket types submitted
			if(ticketTypeErrors.length > 0) {
				// Show error message
				errorArray.push({errorType: 'ticketDetails', errorTitle: 'Ticket Types & Pricing', errorMessage: 'Please enter at least one ticket type and make sure the name and price are input.', errorData: ticketTypeErrors});

				this.setState({
					validTicketTypes: false 
				});
			}

		} else {
			// If there is no ticket type
			errorArray.push({errorType: 'ticketDetails', errorTitle: 'Ticket Types & Pricing', errorMessage: 'Please enter at least one ticket type.'});

			this.setState({
				validTicketTypes: false 
			});
		}

		// 4. Images upload
		if((this.state.eventPhoto.length > 0) && (this.state.slideShowImage.length > 0)) {
			// Check for sponsor images
			if(this.state.multipleSponsorImages) {
				// Check for multiple images
				if(this.state.sponsorImages.length < 1) {
					this.setState({
						validSponsorImages: false
					})
					errorArray.push({errorType: 'imagesUpload', errorTitle: 'Ticket Types & Pricing', errorMessage: 'Please enter the required sponsor images.'});
				}
			}
		} else {
			// If there are missing images required
			errorArray.push({errorType: 'imagesUpload', errorTitle: 'Ticket Types & Pricing', errorMessage: 'Please enter the required images.'});
			this.setState({
				validImageUploads: false 
			});
		}

		return errorArray.length > 0 ? errorArray : []
	}

	returnFileSize = (number) => {
		if (number < 1024) {
			return number + 'bytes';
		} else if (number >= 1024 && number < 1048576) {
			return (number / 1024).toFixed(1) + 'KB';
		} else if (number >= 1048576) {
			return (number / 1048576).toFixed(1) + 'MB';
		}
	}

	addTicketType = () => {
		// Adding an extra ticket type
		if(this.state.addTickets.length < 5) {
			const ticketsCount = this.state.addTickets.length
			const addTickets = [
				...this.state.addTickets, 
				{count: ticketsCount, name: 'ticketName'+(ticketsCount+1), priceName: 'ticketPrice'+(ticketsCount+1)}
			];

			this.setState({
				addTickets
			});
		}
	}

	deleteTicketType = () => {
		// Removing a ticket type
		let addTickets = [...this.state.addTickets]

		if(addTickets.length > 1) {
			this.state.addTickets.pop()
			this.setState({addTickets: this.state.addTickets})
		}
	}

	render() {
        const { loginRedirect } = this.state;

        if (loginRedirect) {
            return <Redirect to='/login?isloggedin=false'/>;
		}
		
		return(
			<ErrorBoundary>
				<BlockUi tag="div" blocking={this.state.blocking}>
					
				<div id="main-wrapper">
					<TopMenu />
					<Sidebar />

					<div className="page-wrapper" style={{minHeight: "643px"}}>
						<div className="container-fluid">
							<div className="row page-titles">
								<div className="col-md-6 col-8 align-self-center">
									<h3 className="text-themecolor m-b-0 m-t-0">Create New</h3>
									<ol className="breadcrumb">
										<li className="breadcrumb-item"><Link to="/">Home</Link></li>
										<li className="breadcrumb-item"><Link to="/events">Events</Link></li>
										<li className="breadcrumb-item active">Create New</li>
									</ol>
								</div>
							</div>
								
							<div className="row">
								<div className="col-12">
									<div className="card">
										<div className="card-block">

											{/* <Alert color="success" style={{display: this.state.events.length < 1 ? '' : 'none'}}>
												Coming soon!
											</Alert> */}

											<form className="form-horizontal form-material" onSubmit={this.handleSubmit} name="createEventForm">
		
												<Nav tabs>
													<NavItem>
														<NavLink
														className={classnames({ active: this.state.activeTab === '1' })}
														onClick={() => { this.toggle('1'); }}
														>
															Get Started
														</NavLink>
													</NavItem>
													<NavItem style={{pointerEvents: (!this.state.contactName || !this.state.contactEmail || !this.state.contactPhoneNumber) ? 'none' : '', opacity: (!this.state.contactName || !this.state.contactEmail || !this.state.contactPhoneNumber) ? '0.5' : ''}}>
														<NavLink
														className={classnames({ active: this.state.activeTab === '2' })}
														onClick={() => { this.toggle('2'); }}>
															Event Details
														</NavLink>
													</NavItem>
													{/* <NavItem style={{pointerEvents: (!this.state.contactName || !this.state.contactEmail || !this.state.contactPhoneNumber || !this.state.eventName || !this.state.eventDescription || !this.state.eventVenue || !this.state.eventStartDate || !this.state.eventStartTime || !this.state.eventTags) ? 'none' : '', opacity: (!this.state.contactName || !this.state.contactEmail || !this.state.contactPhoneNumber || !this.state.eventName || !this.state.eventDescription || !this.state.eventVenue || !this.state.eventStartDate || !this.state.eventStartTime || !this.state.eventTags) ? '0.5' : ''}}> */}
													<NavItem>
														<NavLink
														className={classnames({ active: this.state.activeTab === '3' })}
														onClick={() => { this.toggle('3'); }}
														>
															Tickets &amp; Pricing
														</NavLink>
													</NavItem>
													<NavItem>
														<NavLink
														className={classnames({ active: this.state.activeTab === '4' })}
														onClick={() => { this.toggle('4'); }}
														>
															Images Upload
														</NavLink>
													</NavItem>
													<NavItem>
														<NavLink
														className={classnames({ active: this.state.activeTab === '5' })}
														onClick={() => { this.toggle('5'); }}
														>
															Submit
														</NavLink>
													</NavItem>
												</Nav>
												
												<br></br>

												<TabContent activeTab={this.state.activeTab}>

													<TabPane tabId="1">
														<p>Thank you for showing interest in listing your event. Using this tool, you'll be able to share the required information or save the process to continue later.</p>
														<br />
														<p>Before you proceed,</p>
														<p><strong>1.</strong> If you haven't already, as the event owner you will need to go to <a href="https://www.pesapal.com" target="_blank" rel="noopener noreferrer">www.pesapal.com</a> and register as a merchant</p>
														<p><strong>2.</strong> You will receive two emails from <a href="mailto:info@pesapal.com" target="_blank" rel="noopener noreferrer">info@pesapal.com</a>, one to activate your account and another with a consumer key and secret. Keep these at reach as you will need the details to receive payments for your events.</p>
														<br />
														<p><strong>Please enter your contact details</strong></p>

														<div className="row">

															<div className="col-md-6">

																<div className="form-group">
																	<label className="col-md-12">Name</label>
																	<div className="col-md-12">
																		<input type="text" placeholder="" className="form-control form-control-line" value={this.state.contactName} name="contactName" onChange={this.handleInputChange} />
																	</div>
																</div>

																<div className="form-group">
																	<label className="col-md-12">Email</label>
																	<div className="col-md-12">
																		<input type="email" placeholder="" className="form-control form-control-line" value={this.state.contactEmail} name="contactEmail" onChange={this.handleInputChange} />
																	</div>
																	{/* <p style={{color: 'red', display: this.state.validContactEmail ? 'none' : ''}}><small><em>Please enter a valid email.</em></small></p> */}
																</div>
																	
															</div>

															<div className="col-md-6">

																<div className="form-group">
																	<label className="col-md-12">Phone Number</label>
																	<div className="col-md-12">
																		<input type="text" placeholder="" className="form-control form-control-line" value={this.state.contactPhoneNumber} name="contactPhoneNumber" onChange={this.handleInputChange} />
																	</div>
																</div>
															</div>

														</div>
													
													</TabPane>

													<TabPane tabId="2">
														<p><strong>Event Details</strong></p>

														<div className="row">
															<div className="col-md-6">

																<div className="form-group">
																	<label className="col-md-12">Name</label>
																	<div className="col-md-12">
																		<input type="text" placeholder="" className="form-control form-control-line" value={this.state.eventName} name="eventName" onChange={this.handleInputChange} />
																	</div>
																</div>

																<div className="form-group">
																	<label className="col-md-12">Venue</label>
																	<div className="col-md-12">
																		<input type="text" placeholder="" className="form-control form-control-line" value={this.state.eventVenue} name="eventVenue" onChange={this.handleInputChange} />
																	</div>
																</div>

																<div className="form-group">
																	<label className="col-md-12">Description</label>
																	<textarea className="form-control form-control-line" name="eventDescription" rows="4" value={this.state.eventDescription} onChange={this.handleInputChange} form="createEventForm" maxLength="340" placeholder="(340 words)"></textarea>
																</div>

															</div>

															<div className="col-md-6">

																<div className="form-group">
																	<label className="col-md-12">Start Date</label>
																	<div className="col-md-12">
																		<input type="date" placeholder="" min={moment().format('YYYY-MM-DD')} className="form-control form-control-line" value={this.state.eventStartDate} name="eventStartDate" onChange={this.handleInputChange} />
																	</div>
																</div>

																<div className="form-group">
																	<label className="col-md-12">Start Time<small>(24 hour clock format)</small></label>
																	<div className="col-md-12">
																		<input type="time" placeholder="" className="form-control form-control-line" value={this.state.eventStartTime} name="eventStartTime" onChange={this.handleInputChange} />
																	</div>
																</div>

																<div className="form-group">
																	<label className="col-md-12">Tags <small>(use a comma to separate the tags)</small></label>
																	<textarea className="form-control form-control-line" name="eventTags" rows="4" value={this.state.eventTags} onChange={this.handleInputChange} form="createEventForm" maxLength="340" ></textarea>
																</div>

															</div>

														</div>
													</TabPane>

													<TabPane tabId="3">
														<p>Please enter the ticket types &amp; their corresponding prices for your event</p>

														{
															this.state.addTickets.map((ticket, key) => {
																return (
																	<div key={key} className="row">
																		<div className="col-md-6">
																			<div className="form-group">
																				<label className="col-md-12">Ticket Name</label>
																				<input type="text" placeholder="" className="form-control form-control-line" value={this.state[ticket.name]} name={ticket.name} onChange={this.handleInputChange} />
																			</div>
																		</div>
																		<div className="col-md-6">
																			<div className="form-group">
																				<label className="col-md-12">Price</label>
																				<input type="number" placeholder="" className="form-control form-control-line" value={this.state[ticket.priceName]} name={ticket.priceName} onChange={this.handleInputChange} min="0" max="70000" />
																			</div>
																		</div>
																	</div>
																)
															})
														}

														<button type="button" className="btn btn-success" onClick={this.addTicketType}>Add new ticket type </button>
														&nbsp;
														<button type="button" className="btn btn-danger" onClick={this.deleteTicketType}>Delete ticket type</button>

														{/* <button type="submit" className="btn">Add new ticket type</button> */}

													</TabPane>

													<TabPane tabId="4">
														<p>Please upload the images required</p>

														<br />
														<p><strong>1. Event Image<small>(Recommended dimensions: 330px by 320px)</small></strong></p>

														<div className="row">

															<div className="col-md-6">
																<p style={{display: (this.state.eventPhoto.length > 0) ? 'none' : ''}}><em><strong>No image selected</strong></em></p>
																<div style={{display: (this.state.eventPhoto.length > 0) ? '' : 'none'}}>
																	<div className="form-group">
																		<div className="row">
																			<div className="col-md-12">
																				<img src={(this.state.eventPhoto.length > 0) ? window.URL.createObjectURL(this.state.eventPhoto[0]) : null} height="330" width="320" alt="event" />
																			</div>

																			<div className="col-md-6">
																				<small>
																					<strong>Name</strong>
																					<p>{this.state.eventPhoto.length > 0 ? this.state.eventPhoto[0].name : 'N/A'}</p>
																				</small>
																				<small>
																					<strong>Size</strong>
																					<p>{this.state.eventPhoto.length > 0 ? this.returnFileSize(this.state.eventPhoto[0].size) : 'N/A'}</p>
																				</small>
																			</div>

																			<div className="col-md-6">
																				<small>
																					<strong>Type</strong>
																					<p>{this.state.eventPhoto.length > 0 ? this.state.eventPhoto[0].type : 'N/A'}</p>
																				</small>
																				<small>
																					<strong>Last Modified</strong>
																					<p>{this.state.eventPhoto.length > 0 ? moment(this.state.eventPhoto[0].lastModified).format('ddd, Do MMM YYYY h:mma') : 'N/A'}</p>
																				</small>
																			</div>
																		
																		</div>
																	</div>
																</div>
															</div>

															<div className="col-md-6">

																<div className="form-group">
																	<div className="col-md-12">
																		<input type="file" className="form-control" name="eventPhoto" files={this.state.eventPhoto ? this.state.eventPhoto : ""} onChange={this.imageUpload} accept=".png, .jpg, .jpeg" />
																	</div>
																</div>

															</div>

														</div>

														<br />
														<p><strong>2. Slideshow Image<small>(Recommended dimensions: 1500px by 500px)</small></strong></p>

														<div className="row">

															<div className="col-md-6">
																<p style={{display: (this.state.slideShowImage.length > 0) ? 'none' : ''}}><em><strong>No image selected</strong></em></p>
																<div style={{display: (this.state.slideShowImage.length > 0) ? '' : 'none'}}>
																	<div className="form-group">
																		<div className="row">
																			<div className="col-md-12">
																				<img src={(this.state.slideShowImage.length > 0) ? window.URL.createObjectURL(this.state.slideShowImage[0]) : null} height="500" width="1500" alt="slide-show" />
																			</div>

																			<div className="col-md-6">
																				<small>
																					<strong>Name</strong>
																					<p>{this.state.slideShowImage.length > 0 ? this.state.slideShowImage[0].name : 'N/A'}</p>
																				</small>
																				<small>
																					<strong>Size</strong>
																					<p>{this.state.slideShowImage.length > 0 ? this.returnFileSize(this.state.slideShowImage[0].size) : 'N/A'}</p>
																				</small>
																			</div>

																			<div className="col-md-6">
																				<small>
																					<strong>Type</strong>
																					<p>{this.state.slideShowImage.length > 0 ? this.state.slideShowImage[0].type : 'N/A'}</p>
																				</small>
																				<small>
																					<strong>Last Modified</strong>
																					<p>{this.state.slideShowImage.length > 0 ? moment(this.state.slideShowImage[0].lastModified).format('ddd, Do MMM YYYY h:mma') : 'N/A'}</p>
																				</small>
																			</div>
																		
																		</div>
																	</div>
																</div>
															</div>

															<div className="col-md-6">

																<div className="form-group">
																	<div className="col-md-12">
																		<input type="file" placeholder="" className="form-control" files={this.state.slideShowImage ? this.state.slideShowImage : ""} name="slideShowImage" onChange={this.imageUpload} accept=".png, .jpg, .jpeg" />
																	</div>
																</div>

															</div>

														</div>
													
														<br />
														<p><strong>3. Sponsor Image(s)<small>(Recommended dimensions: {this.state.multipleSponsorImages ? "80px by 80px" : "800px by 305px"})</small></strong></p>

														<div className="row" style={{display: this.state.multipleSponsorImages ? 'none' : ''}}>

															<div className="col-md-6">
																<p style={{display: (this.state.sponsorImage.length > 0) ? 'none' : ''}}><em><strong>No image selected</strong></em></p>
																<div style={{display: (this.state.sponsorImage.length > 0) ? '' : 'none'}}>
																	<div className="form-group">
																		<div className="row">
																			<div className="col-md-12">
																				<img src={(this.state.sponsorImage.length > 0) ? window.URL.createObjectURL(this.state.sponsorImage[0]) : null} height="300" width="300" alt="sponsor" />
																			</div>

																			<div className="col-md-6">
																				<small>
																					<strong>Name</strong>
																					<p>{this.state.sponsorImage.length > 0 ? this.state.sponsorImage[0].name : 'N/A'}</p>
																				</small>
																				<small>
																					<strong>Size</strong>
																					<p>{this.state.sponsorImage.length > 0 ? this.returnFileSize(this.state.sponsorImage[0].size) : 'N/A'}</p>
																				</small>
																			</div>

																			<div className="col-md-6">
																				<small>
																					<strong>Type</strong>
																					<p>{this.state.sponsorImage.length > 0 ? this.state.sponsorImage[0].type : 'N/A'}</p>
																				</small>
																				<small>
																					<strong>Last Modified</strong>
																					<p>{this.state.sponsorImage.length > 0 ? moment(this.state.sponsorImage[0].lastModified).format('ddd, Do MMM YYYY h:mma') : 'N/A'}</p>
																				</small>
																			</div>
																		
																		</div>
																	</div>
																</div>
															</div>

															<div className="col-md-6">

																<div className="form-group">
																	<div className="col-md-12">
																		<input type="file" placeholder="" className="form-control" files={this.state.sponsorImage ? this.state.sponsorImage : ""} name="sponsorImage" onChange={this.imageUpload} accept=".png, .jpg, .jpeg" />
																	</div>
																</div>

															</div>

														</div>
														
														<div className="row" style={{display: this.state.multipleSponsorImages ? '' : 'none'}}>

															<div className="col-md-6">
																<p style={{display: (this.state.sponsorImages.length > 0) ? 'none' : ''}}><em><strong>No images selected</strong></em></p>
																<div style={{display: (this.state.sponsorImages.length > 0) ? '' : 'none'}}>
																	<div className="form-group">
																		{
																			Object.values(this.state.sponsorImages).map((image, key) => {
																				return (
																					<div className="row" key={key}>
																						<div className="col-md-12">
																							<img src={image ? window.URL.createObjectURL(image) : null} height="80" width="80" alt="sponsor" />
																						</div>
				
																						<div className="col-md-6">
																							<small>
																								<strong>Name</strong>
																								<p>{image.name ? image.name : 'N/A'}</p>
																							</small>
																							<small>
																								<strong>Size</strong>
																								<p>{image.size ? this.returnFileSize(image.size) : 'N/A'}</p>
																							</small>
																						</div>
				
																						<div className="col-md-6">
																							<small>
																								<strong>Type</strong>
																								<p>{image.type ? image.type : 'N/A'}</p>
																							</small>
																							<small>
																								<strong>Last Modified</strong>
																								<p>{image.lastModified ? moment(image.lastModified).format('ddd, Do MMM YYYY h:mma') : 'N/A'}</p>
																							</small>
																						</div>
																					
																					</div>
																				)
																			})
																		}
																	</div>
																</div>
															</div>

															<div className="col-md-6">

																<div className="form-group">
																	<div className="col-md-12">
																		<input type="file" placeholder="" className="form-control" files={this.state.sponsorImages ? this.state.sponsorImages : ""} name="sponsorImages" onChange={this.imageUpload} accept=".png, .jpg, .jpeg" multiple />
																	</div>
																</div>

															</div>

														</div>

														<div className="row">
															<div className="col-md-12">
																<div className="">
																	<input type="checkbox" id="multipleImages" placeholder="" className="" checked={this.state.multipleSponsorImages} name="multipleSponsorImages" onChange={this.handleInputChange} />
																	<label htmlFor="multipleImages">I have more than one sponsor</label>
																</div>
															</div>
														</div>

													</TabPane>

													<TabPane tabId="5">
														<Alert color={this.state.formSubmittedMessageColour} style={{display: this.state.formSubmittedShow ? '' : 'none'}}>
															{this.state.formSubmittedMessage}
														</Alert>

														{
															// Looping over errors form errors if any
															this.state.formErrors.map((errorDetails, key) => {
																return (
																	<Alert color="danger" key={key}>
																		{errorDetails.errorMessage}
																	</Alert>
																)
															})
														}

														<p>Click the button below to submit your event</p>

														{/* <input type="email" name="sendEmailTo" value={this.state.sendEmailTo} className="form-control form-control-line" onChange={this.handleInputChange} placeholder="Enter email to receive the submission"/>
														<br /><br /> */}

														<button type="submit" className="btn btn-success">Submit New Event</button>
													
													</TabPane>

												</TabContent>
											</form>

										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				
					<Footer /> 
				</div>

				</BlockUi>
			</ErrorBoundary>
		)
	}
}

class ErrorBoundary extends React.Component {
	constructor(props) {
	super(props);
	this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, info) {
		// You can also log the error to an error reporting service
		// logErrorToMyService(error, info);
		console.log(error)
		console.log(info)
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return <h1>Something went wrong.</h1>;
		}

		return this.props.children; 
	}
}

export default CreateNew;
