import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Button, Input } from 'reactstrap';
import axios from 'axios';
import qs from 'qs';

import Footer from '../../components/Footer/Footer.jsx';
import TopMenu from '../../components/TopMenu/TopMenu.jsx';
import Sidebar from '../../components/Sidebar/Sidebar.jsx';

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

class Verify extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle: 'Verify Event Tickets - Ticketsasa Merchants',
            formEventName: '',
            formTicketCode: '',
            blocking: false,
            validate: false,
            validateResult: false,
            eventListArray: [],
            validateMessage: '',
            loginRedirect: false,
            merchantId: '',
            name: '',
            username: '',
            email: '',
            merchant: {}
        };

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.toggleBlocking = this.toggleBlocking.bind(this);
		this.getEvents = this.getEvents.bind(this);
        this.checkIfLoggedIn = this.checkIfLoggedIn.bind(this);
    }

	componentWillMount() {
        // Check if the user is logged in and get their details
        this.checkIfLoggedIn();
    }

	componentDidMount() {
		// Update page title
		document.title = this.state.pageTitle;
	}

    checkIfLoggedIn() {
        // Check local storage for the isLoggedIn value
        if(localStorage.getItem('isLoggedIn')) {
            // Get user details
            this.setState({merchant: JSON.parse(localStorage.getItem('merchant'))}); // Converting JSON string to object and storing it to state
            this.setState({name: localStorage.getItem('name')});
            this.setState({email: localStorage.getItem('email')});
            this.setState({username: localStorage.getItem('username')});
            this.setState({merchantId: localStorage.getItem('id')});

            this.getEvents(JSON.parse(localStorage.getItem('merchant')));

        } else {
            // Redirect to the login page with an error message
            this.setState({loginRedirect: true});
        }
    }

    getEvents(merchant) {
        // Get events to populate dropdown
        let url = 'https://www.ticketsasa.com/index.php?option=com_enmasse&controller=merchantservice&task=getEvents';

        var dataObj = {
            'user_id': merchant.user_name
        }

		axios({
            method: 'POST',
            url: url,
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(dataObj)
		})
        .then(response => {

            if(response.data.response === "success") {
                // Get required event data
                this.setState({eventListArray: response.data.data.events});
                this.setState({imageBase: response.data.data.imageBase});

            }

        })
        .catch(function (error) {
            // handle error
            // console.log('Error ', error);
        })
        .then(function () {
            // always executed
        });
    }
    
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
            [name]: value
        });
    }

    toggleBlocking() {
        this.setState({blocking: !this.state.blocking});
    }

	handleSubmit(event) {
        event.preventDefault();

        // Add loader
        this.toggleBlocking();

        // Make call to api
        var url = 'https://www.ticketsasa.com/index.php?option=com_enmasse&controller=MobileMerchant&task=verify';
        
        var dataObj = {
            'coupon_serial': this.state.formTicketCode,
            'deal_id': this.state.formEventName
        }

		// Send a POST request
		axios({
            method: 'POST',
            url: url,
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(dataObj)
		})
			.then(response => {
                // Display response
                this.setState({validate: true});

                if(response.data.error) {
                    this.setState({validateResult: false})
                    this.setState({validateMessage: response.data.error_message})
                }

                if(response.data.success) {
                    this.setState({validateResult: true})
                    this.setState({validateMessage: response.data.success_message})
                    // console.log(response)
                }

                // Remove UI blocker
                this.toggleBlocking();
			})
			.catch(error => {
				// Hanandle error
                alert('There was an error: ' + error);
                // console.log('Verify ticket error: ', error);

                // Remove UI blocker
                this.toggleBlocking();
			})
			.then(data => {
				// always executed
            });
    }

    render () {
        const { loginRedirect } = this.state;

        if (loginRedirect) {
            return <Redirect to='/login?isloggedin=false'/>;
        }

        const EventList = props => {

            var eventArray = props.eventArray;

            const eventOptions = eventArray.map((event,key) => {
                return (
                    <option key={key} value={event.id}>{event.name}</option> 
                )
            })

            return (
                <Input type="select" name="formEventName" placeholder="Event Name" value={this.state.formEventName} onChange={this.handleInputChange} required>
                    <option value="">-Please select an event-</option>
                    {eventOptions}
                </Input>
            )
        }

        return (
            <BlockUi tag="div" blocking={this.state.blocking}>
                <div id="main-wrapper">
                    <TopMenu />
                    <Sidebar />
                    <div className="page-wrapper" style={{minHeight: "643px"}}>
                        <div className="container-fluid">
                            <div className="row page-titles">
                                <div className="col-md-6 col-8 align-self-center">
                                    <h3 className="text-themecolor m-b-0 m-t-0">Verify</h3>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active">Verify</li>
                                    </ol>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-block">
                                            <p>Select/search for an event</p>
                                            <form className="form-horizontal" onSubmit={this.handleSubmit}>
                                                <div className="row">
                                                    <div className="col-md-6 col-sm-6">
                                                        <div className="form-group">
                                                            <EventList eventArray={this.state.eventListArray}></EventList>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-sm-4">
                                                        <div className="form-group">
                                                            <Input type="text" name="formTicketCode" placeholder="Ticket Code" value={this.state.formTicketCode} onChange={this.handleInputChange} required/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2 col-sm-2">
                                                        <Button color="success" className="btn">Search</Button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row" style={{display: this.state.validate  ? this.state.validateResult ? '' : 'none' : 'none'}}>
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-block">
                                            <strong style={{fontSize: '400', color: 'green'}}>{this.state.validateMessage !== "" ? this.state.validateMessage : 'Coupon verified. Ticket was successfully scanned.'}</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row" style={{display: this.state.validate ? this.state.validateResult ? 'none' : '' : 'none'}}>
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-block">
                                            <strong style={{fontSize: '400', color: 'red'}}>{!this.state.validateMessage === "" ? this.state.validateMessage : 'Coupon does not exist. Error scanning ticket.'}</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>  
            </BlockUi>
        )
    }
}

export default Verify;
