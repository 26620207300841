import React, { Component } from 'react';

class TicketData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ticketTypes: [],
            totalSales: 0,
            generalCompsIssued: 0,
            totalTicketsVerified: 0,
            totalComps: 0
        }
    }

    componentWillReceiveProps(nextProps) {
        var tickets = nextProps.ticketTypes;
        var ticketsTotal = 0;
        var totalTicketsVerified = 0;
        var totalComps = 0;

        // Looping over ticket types & get total
        for(var i = 0; i < tickets.length; i++) {
            // Getting subtotal
            ticketsTotal += tickets[i].sub_total;
            totalTicketsVerified += tickets[i].tickets_verified;
            totalComps += parseInt(tickets[i].comps_issued);
        }

        // console.log('Total ticket price: ', totalComps);
        this.setState({totalSales: ticketsTotal});
        this.setState({totalTicketsVerified: totalTicketsVerified});
        this.setState({totalComps: totalComps});

        // Check if the events array matches what's been passed
        if(nextProps.ticketTypes !== this.state.ticketTypes) {
            this.setState({ticketTypes: nextProps.ticketTypes});
        }

        if(nextProps.generalCompsIssued !== this.state.generalCompsIssued) {
            this.setState({generalCompsIssued: nextProps.generalCompsIssued});
        }
    }

    render() {
        const TicketsLoop = props => {
            var tickets = props.ticketTypes;

            const ticketTr = tickets.map((ticket, key) => {
                return (
                    <tr key={key}>
                        <td>{ticket.name ? ticket.name : 0}</td>
                        <td>{ticket.comps_issued ? ticket.comps_issued : 0}</td>
                        {/* <td>{ticket.number_of_tickets ? ticket.number_of_tickets : 0}</td> */}
                        <td>{new Intl.NumberFormat('en-KE', {minimumFractionDigits: 2}).format(ticket.price ? ticket.price : 0)}</td>
                        <td>{ticket.sold ? ticket.sold + '/' + ticket.number_of_tickets : 0}</td>
                        <td>{new Intl.NumberFormat('en-KE', {minimumFractionDigits: 2}).format(ticket.sub_total)}</td>
                        <td>{ticket.tickets_verified ? ticket.tickets_verified : 0}</td>
                    </tr>
                )
            })

            return (ticketTr)
        }

        return (
            <tbody>
                <TicketsLoop ticketTypes={this.state.ticketTypes}></TicketsLoop>
                <tr>
                    <td>*General Complimentaries Issued</td>
                    <td>{this.state.generalCompsIssued ? this.state.generalCompsIssued : 0}</td>
                    {/* <td></td> */}
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td style={{color: 'red'}}>TOTALS</td>
                    <td>{(this.state.totalComps + (parseInt(this.state.generalCompsIssued) ? parseInt(this.state.generalCompsIssued) : 0))}</td>
                    <td></td>
                    {/* <td></td> */}
                    <td></td>
                    <td>KES {new Intl.NumberFormat('en-KE', {minimumFractionDigits: 2}).format(this.state.totalSales)}</td>
                    <td>{this.state.totalTicketsVerified}</td>
                </tr>
            </tbody>
        )
    }
}

export default TicketData;
