import React, {Component} from 'react'
import axios from 'axios';
import moment from 'moment'
import { Alert } from 'reactstrap'

class ImagesUpload extends Component {
    constructor(props) {
        super(props)
        this.state = {
            username: '',
			eventId: null,
			eventPhoto: [],
			slideShowImage: [],
			sponsorImage: [],
			sponsorImages: [],
			multipleSponsorImages: false,
			formErrors: [],
            eventDetails: {},
            sponsors: []
        }
    }

    toggleBlocking = () => {
        this.props.toggleBlocking()
    }

	componentWillReceiveProps(nextProps) {
        // Check if the events array matches what's been passed
        if(nextProps.eventId !== this.state.eventId) {
            this.setState({eventId: nextProps.eventId});
        }

        if(nextProps.userName !== this.state.username) {
            this.setState({username: nextProps.userName});
        }

        if(nextProps.eventDetails !== this.state.eventDetails) {
            this.setState({eventDetails: nextProps.eventDetails});
            let sponsors = nextProps.eventDetails.sponsors

            if(sponsors) {
                sponsors = sponsors.replace('[', '')
                sponsors = sponsors.replace(']', '')
                sponsors = sponsors.replace(/['"]+/g, '')
                sponsors = sponsors.replace(/\//g, '') 
                
                this.setState({sponsors: sponsors.split(',')})
            }
        }
	}

	imageUpload = (event) => {
		const name = event.target.name
		this.setState({
			[name]: event.target.files // Array of objects. Objects with single image properties.
		})
	}

	handleImageSubmit = (event) => {
		event.preventDefault();

		// Validate form
		let validateForm = this.validateEventImages()
		
		this.setState({
			formErrors: validateForm
		})

		if(validateForm.length < 1) {
			this.toggleBlocking()

			// If there are no errors
			// Submit form details
			let url = "https://www.ticketsasa.com/index.php?option=com_enmasse&controller=merchantservice&task=updateMerchantPhotos"

			let formData = new FormData();

			formData.append('eventPhoto', this.state.eventPhoto[0]);
			formData.append('slideShowImage', this.state.slideShowImage[0]);
			formData.append('sponsorImageCount', this.state.multipleSponsorImages ?  this.state.sponsorImages.length :  this.state.sponsorImage.length);
			formData.append('merchantName', localStorage.getItem('merchantName'));
			formData.append('userName', localStorage.getItem('name'));

			// Looping over sponsor images
			if(this.state.multipleSponsorImages) {
				for(let i = 0; i < this.state.sponsorImages.length; i++) {
					formData.append('sponsorImage'+i, this.state.sponsorImages[i]);
				}
			} else {
				formData.append('sponsorImage0',  this.state.sponsorImage[0]);
			}

			axios.post(url, formData)
			.then(response => {
				// Check response
				if(response.data.status === 'success') {
					this.setState({
						formSubmittedStatus: 'success',
						formSubmittedShow: true,
						formSubmittedMessageColour: 'success',
						formSubmittedMessage: 'Your new event data was successfully submitted! Track progress on the events page.'
					})

				} else if(response.data.status === 'error') {
					this.setState({
						formSubmittedStatus: 'error',
						formSubmittedShow: true,
						formSubmittedMessageColour: 'danger',
						formSubmittedMessage: response.data.message ? response.data.message : 'There was an error submitting your new event data'
					})

				} else {
					// Show error (something went wrong)
					this.setState({
						formSubmittedStatus: 'error',
						formSubmittedShow: true,
						formSubmittedMessageColour: 'danger',
						formSubmittedMessage: 'There was an error submitting your new event data.'
					})
				}

				this.toggleBlocking()
			})
			.catch(error => {
				// Show error (something went wrong)
				this.setState({
                    formSubmittedStatus: 'error',
                    formSubmittedShow: true,
                    formSubmittedMessageColour: 'danger',
					formSubmittedMessage: 'There was an error submitting your new event data.'
				})

				this.toggleBlocking()
			})
		}

    }
    
    handleMainImageSubmit = (event) => {
		event.preventDefault();

		// Validate form
		let validateForm = this.validateMainEventImage()
		
		this.setState({
			formErrors: validateForm
        })

		if(validateForm.length < 1) {
			this.toggleBlocking()

			// If there are no errors
			// Submit form details
			let url = "https://www.ticketsasa.com/index.php?option=com_enmasse&controller=merchantservice&task=updateMainEventImage"

			let formData = new FormData();

			formData.append('eventMainImage', this.state.eventPhoto[0]);
			formData.append('user_id', this.state.username);
            formData.append('dealId', this.state.eventId);

			axios.post(url, formData)
			.then(response => {
				// Check response
				if(response.data.status === 'success') {
					this.setState({
						formSubmittedStatus: 'success',
						formSubmittedShow: true,
						formSubmittedMessageColour: 'success',
						formSubmittedMessage: 'Main event photo successfullly updated!'
					})

				} else if(response.data.status === 'error') {
					this.setState({
						formSubmittedStatus: 'error',
						formSubmittedShow: true,
						formSubmittedMessageColour: 'danger',
						formSubmittedMessage: response.data.message ? response.data.message : 'There was an error submitting your main event photo.'
					})

				} else {
					// Show error (something went wrong)
					this.setState({
						formSubmittedStatus: 'error',
						formSubmittedShow: true,
						formSubmittedMessageColour: 'danger',
						formSubmittedMessage: 'There was an error submitting your main event photo.'
					})
				}

				this.toggleBlocking()
			})
			.catch(error => {
				// Show error (something went wrong)
				this.setState({
                    formSubmittedStatus: 'error',
                    formSubmittedShow: true,
                    formSubmittedMessageColour: 'danger',
					formSubmittedMessage: 'There was an error submitting your new event data.'
				})

				this.toggleBlocking()
			})
		}

    }

	handleSponsorImagesSubmit = (event) => {
		event.preventDefault();

		// Validate form
		let validateForm = this.validateSponsorImages()
		
		this.setState({
			formErrors: validateForm
		})

		if(validateForm.length < 1) {
			this.toggleBlocking()

			// If there are no errors
			// Submit form details
			let url = "https://www.ticketsasa.com/index.php?option=com_enmasse&controller=merchantservice&task=updateSponsorImages"

            let formData = new FormData();
            
			formData.append('user_id', this.state.username);
            formData.append('dealId', this.state.eventId);
			formData.append('sponsorImageCount', this.state.multipleSponsorImages ?  this.state.sponsorImages.length :  this.state.sponsorImage.length);

			// Looping over sponsor images
			if(this.state.multipleSponsorImages) {
				for(let i = 0; i < this.state.sponsorImages.length; i++) {
					formData.append('sponsorImage'+i, this.state.sponsorImages[i]);
				}
			} else {
				formData.append('sponsorImage0',  this.state.sponsorImage[0]);
			}

			axios.post(url, formData)
			.then(response => {
                //console.log(response)
				// Check response
				if(response.data.status === 'success') {
					this.setState({
						formSubmittedStatus: 'success',
						formSubmittedShow: true,
						formSubmittedMessageColour: 'success',
						formSubmittedMessage: 'Your sponsor images were successfully updated!'
					})

				} else if(response.data.status === 'error') {
					this.setState({
						formSubmittedStatus: 'error',
						formSubmittedShow: true,
						formSubmittedMessageColour: 'danger',
						formSubmittedMessage: response.data.message ? response.data.message : 'There was an error submitting your sponsor images.'
					})

				} else {
					// Show error (something went wrong)
					this.setState({
						formSubmittedStatus: 'error',
						formSubmittedShow: true,
						formSubmittedMessageColour: 'danger',
						formSubmittedMessage: 'There was an error submitting your sponsor images.'
					})
				}

				this.toggleBlocking()
			})
			.catch(error => {
				// Show error (something went wrong)
				this.setState({
                    formSubmittedStatus: 'error',
                    formSubmittedShow: true,
                    formSubmittedMessageColour: 'danger',
					formSubmittedMessage: 'There was an error submitting your sponsor images.'
				})

				this.toggleBlocking()
			})
		}

    }

	handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
	
		this.setState({
			[name]: value
		});
	}

	returnFileSize = (number) => {
		if (number < 1024) {
			return number + 'bytes';
		} else if (number >= 1024 && number < 1048576) {
			return (number / 1024).toFixed(1) + 'KB';
		} else if (number >= 1048576) {
			return (number / 1048576).toFixed(1) + 'MB';
		}
    }
    
    validateMainEventImage = () => {
		let errorArray = []

		// 4. Images upload
		if((this.state.eventPhoto.length < 1)) {
			// If there are missing images required
			errorArray.push({errorType: 'imagesUpload', errorTitle: 'Main Image Missing', errorMessage: 'Please enter the required image.'});
			this.setState({
				validImageUploads: false 
			});
		}

		return errorArray.length > 0 ? errorArray : []
    }

    validateSponsorImages = () => {
        let errorArray = []
        
        // Check for sponsor images
        if(this.state.multipleSponsorImages) {
            // Check for multiple images
            if(this.state.sponsorImages.length < 1) {
                this.setState({
                    validSponsorImages: false
                })
                errorArray.push({errorType: 'imagesUpload', errorTitle: 'Sponsor Images', errorMessage: 'Please enter the required sponsor images.'});
            }
        }

		return errorArray.length > 0 ? errorArray : []
    }

	validateEventImages = () => {
		let errorArray = []

		// 4. Images upload
		if((this.state.eventPhoto.length > 0) && (this.state.slideShowImage.length > 0)) {
			// Check for sponsor images
			if(this.state.multipleSponsorImages) {
				// Check for multiple images
				if(this.state.sponsorImages.length < 1) {
					this.setState({
						validSponsorImages: false
					})
					errorArray.push({errorType: 'imagesUpload', errorTitle: 'Ticket Types & Pricing', errorMessage: 'Please enter the required sponsor images.'});
				}
			}
		} else {
			// If there are missing images required
			errorArray.push({errorType: 'imagesUpload', errorTitle: 'Ticket Types & Pricing', errorMessage: 'Please enter the required images.'});
			this.setState({
				validImageUploads: false 
			});
		}

		return errorArray.length > 0 ? errorArray : []
	}

    render() {

        return(
            <div>

                <Alert color={this.state.formSubmittedMessageColour} style={{display: this.state.formSubmittedShow ? '' : 'none'}}>
                    {this.state.formSubmittedMessage}
                </Alert>

                <p>Please upload the images required</p>

                <br />
                <p><strong>1. Event Image<small>(Recommended dimensions: 330px by 320px)</small></strong></p>

                <div className="col-md-12" style={{display: this.state.eventDetails.pic_dir ? '' : 'none'}}>
                    <p><em>Current Image</em></p>
                    <img src={decodeURIComponent(this.state.eventDetails.imageBase) + this.state.eventDetails.pic_dir} height="330" width="320" alt="event" />
                    <br /><br />
                    <p><em>Upload New Image</em></p>
                    <br />
                </div>

                <form onSubmit={this.handleMainImageSubmit} name="mainImageUpload">

                    <div className="row" style={{display: this.state.eventId ? '' : 'none'}}>

                        <div className="col-md-6">
                            <p style={{display: (this.state.eventPhoto.length > 0) ? 'none' : ''}}><em><strong>No image selected</strong></em></p>
                            <div style={{display: (this.state.eventPhoto.length > 0) ? '' : 'none'}}>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-12" style={{display: this.state.eventDetails.pic_dir ? 'none' : ''}}>
                                            <img src={(this.state.eventPhoto.length > 0) ? window.URL.createObjectURL(this.state.eventPhoto[0]) : null} height="330" width="320" alt="event" />
                                        </div>

                                        <div className="col-md-6">
                                            <small>
                                                <strong>Name</strong>
                                                <p>{this.state.eventPhoto.length > 0 ? this.state.eventPhoto[0].name : 'N/A'}</p>
                                            </small>
                                            <small>
                                                <strong>Size</strong>
                                                <p>{this.state.eventPhoto.length > 0 ? this.returnFileSize(this.state.eventPhoto[0].size) : 'N/A'}</p>
                                            </small>
                                        </div>

                                        <div className="col-md-6">
                                            <small>
                                                <strong>Type</strong>
                                                <p>{this.state.eventPhoto.length > 0 ? this.state.eventPhoto[0].type : 'N/A'}</p>
                                            </small>
                                            <small>
                                                <strong>Last Modified</strong>
                                                <p>{this.state.eventPhoto.length > 0 ? moment(this.state.eventPhoto[0].lastModified).format('ddd, Do MMM YYYY h:mma') : 'N/A'}</p>
                                            </small>
                                        </div>                                
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">

                            <div className="form-group">
                                <div className="col-md-12">
                                    <input type="file" className="form-control" name="eventPhoto" files={this.state.eventPhoto ? this.state.eventPhoto : ""} onChange={this.imageUpload} accept=".png, .jpg, .jpeg" />
                                </div>
                            </div>

                        </div>

                        <div className="col-md-12">

                            <button type="submit" className="btn btn-success">Upload </button>
                            &nbsp;
                            <button type="reset" className="btn btn-default">Reset Form </button>
                            
                        </div>

                    </div>

                </form>

                {/* <br />
                <p><strong>2. Slideshow Image<small>(Recommended dimensions: 1500px by 500px)</small></strong></p>

                <div className="row">

                    <div className="col-md-6">
                        <p style={{display: (this.state.slideShowImage.length > 0) ? 'none' : ''}}><em><strong>No image selected</strong></em></p>
                        <div style={{display: (this.state.slideShowImage.length > 0) ? '' : 'none'}}>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-md-12">
                                        <img src={(this.state.slideShowImage.length > 0) ? window.URL.createObjectURL(this.state.slideShowImage[0]) : null} height="500" width="1500" alt="slide-show" />
                                    </div>

                                    <div className="col-md-6">
                                        <small>
                                            <strong>Name</strong>
                                            <p>{this.state.slideShowImage.length > 0 ? this.state.slideShowImage[0].name : 'N/A'}</p>
                                        </small>
                                        <small>
                                            <strong>Size</strong>
                                            <p>{this.state.slideShowImage.length > 0 ? this.returnFileSize(this.state.slideShowImage[0].size) : 'N/A'}</p>
                                        </small>
                                    </div>

                                    <div className="col-md-6">
                                        <small>
                                            <strong>Type</strong>
                                            <p>{this.state.slideShowImage.length > 0 ? this.state.slideShowImage[0].type : 'N/A'}</p>
                                        </small>
                                        <small>
                                            <strong>Last Modified</strong>
                                            <p>{this.state.slideShowImage.length > 0 ? moment(this.state.slideShowImage[0].lastModified).format('ddd, Do MMM YYYY h:mma') : 'N/A'}</p>
                                        </small>
                                    </div>
                                
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">

                        <div className="form-group">
                            <div className="col-md-12">
                                <input type="file" placeholder="" className="form-control" files={this.state.slideShowImage ? this.state.slideShowImage : ""} name="slideShowImage" onChange={this.imageUpload} accept=".png, .jpg, .jpeg" />
                            </div>
                        </div>

                    </div>

                </div>
             */}
                <br />
                <p><strong>2. Sponsor Image(s)<small>(Recommended dimensions: {this.state.multipleSponsorImages ? "80px by 80px" : "800px by 305px"})</small></strong></p>

                <div className="row"  style={{display: this.state.sponsors.length < 1 ? 'none' : ''}}>
                    <div className="col-md-12">
                        <p><em>Current Sponsor Images</em></p>
                        <div className="row">
                            {
                                this.state.sponsors.map((sponsor, key) => {
                                    return(
                                        <div className="col-md-6" key={key}>
                                            <img src={decodeURIComponent(this.state.eventDetails.imageBase) + 'components/com_enmasse/upload/' + this.state.eventId + '/sponsors/' + sponsor} alt={"sponsor" + key} />  
                                            <br /><br /> 
                                        </div>                        
                                    )
                                })
                            }
                        </div>
                        <br /><br />
                        <p><em>Upload New Image(s)</em></p>
                        <br />
                    </div>
                </div>

                <form onSubmit={this.handleSponsorImagesSubmit} name="sponsorImagesUpload">

                    <div className="row" style={{display: this.state.multipleSponsorImages ? 'none' : ''}}>

                        <div className="col-md-6">
                            <p style={{display: (this.state.sponsorImage.length > 0) ? 'none' : ''}}><em><strong>No image selected</strong></em></p>
                            <div style={{display: (this.state.sponsorImage.length > 0) ? '' : 'none'}}>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <img src={(this.state.sponsorImage.length > 0) ? window.URL.createObjectURL(this.state.sponsorImage[0]) : null} height="300" width="300" alt="sponsor" />
                                        </div>

                                        <div className="col-md-6">
                                            <small>
                                                <strong>Name</strong>
                                                <p>{this.state.sponsorImage.length > 0 ? this.state.sponsorImage[0].name : 'N/A'}</p>
                                            </small>
                                            <small>
                                                <strong>Size</strong>
                                                <p>{this.state.sponsorImage.length > 0 ? this.returnFileSize(this.state.sponsorImage[0].size) : 'N/A'}</p>
                                            </small>
                                        </div>

                                        <div className="col-md-6">
                                            <small>
                                                <strong>Type</strong>
                                                <p>{this.state.sponsorImage.length > 0 ? this.state.sponsorImage[0].type : 'N/A'}</p>
                                            </small>
                                            <small>
                                                <strong>Last Modified</strong>
                                                <p>{this.state.sponsorImage.length > 0 ? moment(this.state.sponsorImage[0].lastModified).format('ddd, Do MMM YYYY h:mma') : 'N/A'}</p>
                                            </small>
                                        </div>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">

                            <div className="form-group">
                                <div className="col-md-12">
                                    <input type="file" placeholder="" className="form-control" files={this.state.sponsorImage ? this.state.sponsorImage : ""} name="sponsorImage" onChange={this.imageUpload} accept=".png, .jpg, .jpeg" />
                                </div>
                            </div>

                        </div>

                    </div>
                    
                    <div className="row" style={{display: this.state.multipleSponsorImages ? '' : 'none'}}>

                        <div className="col-md-6">
                            <p style={{display: (this.state.sponsorImages.length > 0) ? 'none' : ''}}><em><strong>No images selected</strong></em></p>
                            <div style={{display: (this.state.sponsorImages.length > 0) ? '' : 'none'}}>
                                <div className="form-group">
                                    {
                                        Object.values(this.state.sponsorImages).map((image, key) => {
                                            return (
                                                <div className="row" key={key}>
                                                    <div className="col-md-12">
                                                        <img src={image ? window.URL.createObjectURL(image) : null} height="80" width="80" alt="sponsor" />
                                                    </div>

                                                    <div className="col-md-6">
                                                        <small>
                                                            <strong>Name</strong>
                                                            <p>{image.name ? image.name : 'N/A'}</p>
                                                        </small>
                                                        <small>
                                                            <strong>Size</strong>
                                                            <p>{image.size ? this.returnFileSize(image.size) : 'N/A'}</p>
                                                        </small>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <small>
                                                            <strong>Type</strong>
                                                            <p>{image.type ? image.type : 'N/A'}</p>
                                                        </small>
                                                        <small>
                                                            <strong>Last Modified</strong>
                                                            <p>{image.lastModified ? moment(image.lastModified).format('ddd, Do MMM YYYY h:mma') : 'N/A'}</p>
                                                        </small>
                                                    </div>
                                                
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">

                            <div className="form-group">
                                <div className="col-md-12">
                                    <input type="file" placeholder="" className="form-control" files={this.state.sponsorImages ? this.state.sponsorImages : ""} name="sponsorImages" onChange={this.imageUpload} accept=".png, .jpg, .jpeg" multiple />
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="">
                                <input type="checkbox" id="multipleImages" placeholder="" className="" checked={this.state.multipleSponsorImages} name="multipleSponsorImages" onChange={this.handleInputChange} />
                                <label htmlFor="multipleImages">I have more than one sponsor</label>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">

                            <button type="submit" className="btn btn-success">Upload </button>
                            &nbsp;
                            <button type="reset" className="btn btn-default">Reset Form </button>
                            
                        </div>
                    </div>
                
                </form>
            </div>
        )
    }
}

export default ImagesUpload
