import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { TabContent, TabPane, Alert} from 'reactstrap';
import Footer from '../../../components/Footer/Footer.jsx';
import TopMenu from '../../../components/TopMenu/TopMenu.jsx';
import Sidebar from '../../../components/Sidebar/Sidebar.jsx';
import axios from 'axios';
import qs from 'qs';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

class EditComplimentary extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pageTitle: 'Event Complimentary Tickets - Ticketsasa Merchants',
			eventId: null,
			userdets:[],
			isLoggedIn: false,
			loginRedirect: false,
			activeTab: '1',
			merchant: {},
			deal:{},
			dealtypes:[],
			deal_type: "",
			comp_qty:"",
			comp_name: '',
			email: '',
			user_id: '',
			amount:0,
			message:"",
			blocking: false,
			postRedirect: false,
			error:""
			
		}
	
		this.getEvent = this.getEvent.bind(this);
		this.getTicketTypes = this.getTicketTypes.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.checkIfLoggedIn = this.checkIfLoggedIn.bind(this);
		this.toggleBlocking = this.toggleBlocking.bind(this);
	}

	componentWillMount() {
		const { eventId } = this.props.match.params;
		this.setState({eventId: eventId});
	}

	componentDidMount() {
		
		this.checkIfLoggedIn();
		// Get single event orders
		this.getEvent();
		this.getTicketTypes ();
	}

	handleInput(e) {
		this.setState({ [e.target.name]: e.target.value });

   	}
	
	toggleBlocking() {
        this.setState({blocking: !this.state.blocking});
	}
	
	handleSubmit(comps) {
		 
		comps.preventDefault();

		this.toggleBlocking();
		
        var url = 'https://www.ticketsasa.com/index.php?option=com_enmasse&controller=merchantservice&task=createComps';

		var dataObj = {
			'user_id': JSON.parse(localStorage.getItem('merchant')).user_name,
			'deal_id': this.state.eventId,
			'deal_type': this.state.deal_type,
			'comp_qty': this.state.comp_qty,
			'email': this.state.email,
			'amount': this.state.amount,
			'comp_name': this.state.comp_name
		}

		// Send a POST request
		axios({
				method: 'POST',
				url: url,
				headers: {
					'content-type': 'application/x-www-form-urlencoded'
				},
				data: qs.stringify(dataObj)
			})
			.then(response => {
				
				// Display response
 				// Store data in state    
				if (response.data.success) {
					
					this.setState({
						message: "Comps Successfully Generated",
						comp_name:"",
						comp_qty:0,
						email:'',
						deal_type:'',
						amount:0
					});
					this.setState({postRedirect: true});
				}else{
					
					var req_fields="";
					var index=0;
					while (index < response.data.required_fields.length) { 
						req_fields=req_fields+", "+response.data.required_fields[index];
						index++;
					}
					this.setState({error: response.data.error_message+" "+req_fields});
					
					
				}
				this.toggleBlocking();
				// Remove UI blocker
			})
			.catch(error => {
				// Hanandle error
				alert(error);

				// Remove UI blocker
			})
			.then(data => {
				// always executed

			});
    }

	checkIfLoggedIn() {
		// Check local storage for the isLoggedIn value
		if(localStorage.getItem('isLoggedIn')) {
			// Get user details
			this.setState({merchant: JSON.parse(localStorage.getItem('merchant'))}); // Converting JSON string to object and storing it to state
			

		} else {
			// Redirect to the login page with an error message
			this.setState({loginRedirect: true});
		}
	}

	getEvent() {
		// Make call to api
		var url = 'https://www.ticketsasa.com/index.php?option=com_enmasse&controller=merchantservice&task=getEventDetails';

		var dataObj = {
			'user_id': JSON.parse(localStorage.getItem('merchant')).user_name,
			'deal_id': this.state.eventId,
			
		}

		// Send a POST request
		axios({
				method: 'POST',
				url: url,
				headers: {
					'content-type': 'application/x-www-form-urlencoded'
				},
				data: qs.stringify(dataObj)
			})
			.then(response => {
				// Display response
				// Store data in state    
				if (response.data.success) {
					this.setState({
						deal: response.data.deal
					});
				}

				// Remove UI blocker
			})
			.catch(error => {
				// Hanandle error
				alert(error);

				// Remove UI blocker
			})
			.then(data => {
				// always executed

			});
	}

	getTicketTypes() {
		// Make call to api
		var url = 'https://www.ticketsasa.com/index.php?option=com_enmasse&controller=merchantservice&task=getTicketTypes';

		var dataObj = {
			'user_id': JSON.parse(localStorage.getItem('merchant')).user_name,
			'deal_id': this.state.eventId
		}

		// Send a POST request
		axios({
				method: 'POST',
				url: url,
				headers: {
					'content-type': 'application/x-www-form-urlencoded'
				},
				data: qs.stringify(dataObj)
			})
			.then(response => {
				
				// Display response
				// Store data in state    
				if (response.data.success) {
					this.setState({
						dealtypes: response.data.ticket_types
					});
				}
				

				// Remove UI blocker
			})
			.catch(error => {
				// Hanandle error
				alert(error);

				// Remove UI blocker
			})
			.then(data => {
				// always executed

			});
	}

	render() {
		
		const Tickettypes = () => {

			const dealTypes=this.state.dealtypes.map((dealtype,key) => {
				return (
					<option key={key} value={dealtype.id} >{dealtype.name}</option>

				)
			})

			return (dealTypes);
		}
		const postRedirect = this.state.postRedirect;

		if (postRedirect === true) {

			this.toggleBlocking();

			var path='/event/'+this.state.eventId+'/comps';
			return	<Redirect to={path}/>
		}
		
		return (
			<BlockUi tag="div" blocking={this.state.blocking}>
			<div id="main-wrapper">
				<TopMenu />
				<Sidebar />
				<div className="page-wrapper" style={{minHeight: "643px"}}>
					<div className="container-fluid">
						<div className="row page-titles">
							<div className="col-md-12 col-12 align-self-center">
								<h3 className="text-themecolor m-b-0 m-t-0">Create New</h3>
								<ol className="breadcrumb">
									<li className="breadcrumb-item"><Link to="/">Home</Link></li>
									<li className="breadcrumb-item"><Link to="/events">Events</Link></li>
									<li className="breadcrumb-item"><Link to={"/event/" + this.state.eventId}>{this.state.deal.name}</Link></li>
									<li className="breadcrumb-item active">Create New Complimentary Ticket</li>
								</ol>
							</div> 
						</div>

						<div className="row">
						
							<div className="col-md-12">
								<div className="card">
										
										{this.state.message === ""? "":<Alert color="success">{this.state.message} </Alert> }
										{this.state.error === ""? "":<Alert color="danger"><b>{this.state.error}</b></Alert> }
			
								</div>
								<div className="card">
									<div className="card-block">
										<h4 className="card-title">{this.state.deal.name}</h4>
										<h6 className="card-subtitle">Create new complimentary ticket</h6>
										<form className="form-horizontal form-material"  >

											<TabContent activeTab={this.state.activeTab}>
												<TabPane tabId="1">
													<div className="form-group">
														<label className="col-md-12">Company/Client Name</label>
														<div className="col-md-12">
															<input type="text" placeholder="Name" onChange={this.handleInput} name="comp_name" className="form-control form-control-line" defaultValue={this.state.comp_name}  />
														</div>
													</div>

													<div className="form-group">
														<label className="col-md-12">Email</label>
														<div className="col-md-12">
															<input type="email" name="email" placeholder="User's Email" onChange={this.handleInput} className="form-control form-control-line" defaultValue={this.state.email} />
														</div>
													</div>                                          

													<div className="form-group">
														<label className="col-md-12"> Quantity</label>
														<div className="col-md-12">
															<input type="text" name="comp_qty" placeholder="0" onChange={this.handleInput} className="form-control form-control-line" defaultValue={this.state.comp_qty}   />
														</div>
													</div>
													<div className="form-group">
														<label className="col-md-12"> Amount</label>
														<div className="col-md-12">
															<input type="text" placeholder="0" name= "amount" onChange={this.handleInput} className="form-control form-control-line" defaultValue={this.state.amount}   />
														</div>
													</div>
													 <div className="form-group">
														<label className="col-sm-12">Select Ticket Type</label>
														<div className="col-sm-12">
															<select name="deal_type" className="form-control form-control-line required"  onChange={this.handleInput} value={this.state.deal_type}>
																<option value="" >Select</option>
																<Tickettypes  />
															</select>
														</div>
													</div>

												</TabPane>

											</TabContent>
											
											
											<div className="form-group">
												<div className="col-sm-12">
													<input type="submit" value="Generate" className="btn btn-success" onClick={this.handleSubmit}/> 
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
					<Footer />
				</div>
			</div>
			</BlockUi>
		)
	}
}

export default EditComplimentary;
