import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
// import { Alert } from 'reactstrap';
import axios from 'axios';
import qs from 'qs';

import Footer from '../../components/Footer/Footer.jsx';
import TopMenu from '../../components/TopMenu/TopMenu.jsx';
import Sidebar from '../../components/Sidebar/Sidebar.jsx';

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

import SurveyResponses from '../../components/SingleEvent/Survey/SurveyResponses.jsx';

class Survey extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle: 'Survey - Ticketsasa Merchants',
            isLoggedIn: false,
            loginRedirect: false,
            merchant: {},
            email: '',
            name: '',
            username: '',
            blocking: false,
            responses: [],
            eventDetails: {}
        }

        this.getSurveyData = this.getSurveyData.bind(this);
        this.getEventDetails = this.getEventDetails.bind(this);
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.checkIfLoggedIn = this.checkIfLoggedIn.bind(this);
    }

    componentWillMount() {
        this.checkIfLoggedIn();
    }

    checkIfLoggedIn() {
        // Check local storage for the isLoggedIn value
        if(localStorage.getItem('isLoggedIn')) {
            // Get user details
            this.setState({merchant: JSON.parse(localStorage.getItem('merchant'))}); // Converting JSON string to object and storing it to state
            this.setState({name: localStorage.getItem('name')});
            this.setState({email: localStorage.getItem('email')});
            this.setState({username: localStorage.getItem('username')});

        } else {
            // Redirect to the login page with an error message
            this.setState({loginRedirect: true});
        } 
    }

    componentDidMount() {
		// Update page title
        document.title = this.state.pageTitle;
        
        const { eventId } = this.props.match.params;
        this.setState({eventId: eventId});

        // Get single event orders
        this.getSurveyData(eventId);
        this.getEventDetails(eventId);
    }

    toggleBlocking() {
        this.setState({blocking: !this.state.blocking});
    }

    getSurveyData(eventId) {
        // Fetch survey data
        // Add loader
        this.toggleBlocking();

        // Getting event and ticket sales
        let url = 'https://www.ticketsasa.com/index.php?option=com_eventsurvey&task=survey.getSurveyResponses';

        var dataObj = {
            'eventId': eventId
        }

		axios({
            method: 'POST',
            url: url,
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(dataObj)
		})
        .then(response => {
            // Prepare data
            // Check if request was successful
            if(response.data.status === "success") {
                // Get required event data
                // console.log('Survey data: ', response.data);
                if(response.data.data.responses) {
                    this.setState({responses: response.data.data.responses});
                }

            } else {
                // Get error details
                // console.log('Error response: ', response);

            }

            // Remove UI blocker
            this.toggleBlocking();
        })
        .catch(error => {
            // Remove UI blocker
            this.toggleBlocking();

        })
        .then(function () {
            // Always executed

            // Remove UI blocker
            // this.toggleBlocking();
        });

    }

    getEventDetails(eventId) {
        // Getting event details
        // Block UI
        // this.toggleBlocking();

        let url = 'https://www.ticketsasa.com/index.php?option=com_enmasse&controller=merchantservice&task=getEventDetails';

        var dataObj = {
            'deal_id': eventId,
            'user_id': localStorage.getItem('username')
        }

		axios({
            method: 'POST',
            url: url,
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(dataObj)
		})
        .then(response => {
            // Prepare data
            // Check if request was successful
            if(response.data.success) {
                // Get required event data
                this.setState({eventDetails: response.data.deal})

            } else {
                // Get error details
                // TODO: Display TOAST message on the page
                alert('There was an error getting event data.')

            }
        })
        .catch(error => {
            // Display error message on the page
            alert(error);

        })
        .then(data => {
            // Always executed
            // this.toggleBlocking();
        });
    }

    render() {
        const { loginRedirect } = this.state;

        if (loginRedirect) {
            return <Redirect to='/login?isloggedin=false'/>;
        }

        return (
            <BlockUi tag="div" blocking={this.state.blocking}>
            
                <div id="main-wrapper">
                    <TopMenu />
                    <Sidebar />
                    <div className="page-wrapper" style={{minHeight: "643px"}}>
                        
                        <div className="container-fluid">
                            <div className="row page-titles">
                                <div className="col-md-12 col-12 align-self-center">
                                    <h3 className="text-themecolor m-b-0 m-t-0">Events</h3>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item"><Link to="/events">Events</Link></li>
                                        <li className="breadcrumb-item"><Link to={"/event/" + this.state.eventId}>{this.state.eventDetails.name ? this.state.eventDetails.name : 'Single Event'}</Link></li>
                                        <li className="breadcrumb-item active">Survey</li>
                                    </ol>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">

                                    <div className="card">
                                        <div className="card-block">
                                            <h4 className="card-title">Survey Responses</h4>
                                            <h6 className="card-subtitle">A list of all survey responses</h6>
                                            <SurveyResponses responses={this.state.responses}></SurveyResponses>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>

                        <Footer />
                    </div>
                </div>

            </BlockUi>
        )
    }
}

export default Survey;