import React, { Component } from 'react';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import { Alert, Pagination, PaginationItem, PaginationLink } from 'reactstrap';

class SurveyResponses extends Component {
    constructor(props) {
        super(props);
        this.state = {
            responses: [],
            currentPage: 1,
            responsesPerPage: 10,
            showPagination: true
        }
        this.surveyTable = React.createRef();
        this.getStars = this.getStars.bind(this);
    }
    
    componentWillReceiveProps(nextProps) {
        if(nextProps.responses !== this.state.responses && nextProps.responses) {
            // console.log('Responses updated');
            this.setState({responses: nextProps.responses});
        }
    }

    getStars(count) {
        var starHtml = '';
        for(var r = 0; r < count; r++) {
            // Append html
            starHtml += '<i class="fa fa-star m-r-10" style="color: gold;"></i>'
        }

        return starHtml;
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
            [name]: value
        });
    }

    handleClickPagination = (event) => {
		this.setState({
			currentPage: Number(event.target.id)
		});
    }

    paginationPrevious = (event) => {
        // console.log('Pagination previous clicked');
        this.setState({
            currentPage: this.state.currentPage - 1
        })
    }

    paginationNext = (event) => {
        // console.log('Pagination next clicked');
        this.setState({
            currentPage: this.state.currentPage + 1
        })
    }

    printTable = () => {
        // Trigger table print
        const divToPrint = this.surveyTable.current;
        const newWin = window.open("");
        newWin.document.write(divToPrint.outerHTML);
        newWin.print();
        newWin.close();
    }

    render() {
        const { responses, currentPage, responsesPerPage } = this.state;
	
		// Logic for displaying events
		const indexOfLastOrder = currentPage * responsesPerPage;
		const indexOfFirstOrder = indexOfLastOrder - responsesPerPage;
		const currentResponses = responses.slice(indexOfFirstOrder, indexOfLastOrder);

        // const ResponsesList = props => {

            const items = currentResponses.map((response, key) => {
                return (
                    <tr key={key}>
                        <td>
                            <h6>{response.name ? response.name : 'N/A'}</h6>
                            <p>{response.email ? response.email : 'N/A'}</p>
                            <p>{response.phone ? response.phone : 'N/A'}</p>
                        </td>
                        <td>
                            {ReactHtmlParser(this.getStars(response.event_overall_satisfaction))}<br />
                            <small>{'(' + response.event_overall_satisfaction + '/5)'}</small>
                        </td>
                        <td>
                            {ReactHtmlParser(this.getStars(response.event_venue))}<br />
                            <small>{'(' + response.event_venue + '/5)'}</small>
                        </td>
                        <td>
                            {ReactHtmlParser(this.getStars(response.event_security))}<br />
                            <small>{'(' + response.event_security + '/5)'}</small>
                        </td>
                        <td>{response.additional_comments ? response.additional_comments : 'N/A'}</td>
                        <td>{moment(response.date_created).format('ddd, Do MMM YYYY h:mma')}</td>
                    </tr>
                )
            })

        //     return (
        //         <tbody>
        //             {items}
        //         </tbody>
        //     )
        // }
	
		// Logic for displaying page numbers
		const pageNumbers = [];
		for (let i = 1; i <= Math.ceil(responses.length / responsesPerPage); i++) {
			pageNumbers.push(i);
        }
        
        const ShowPagination = props => {
            const showPagination = props.showPagination;
            const currentPage = props.currentPage;

            const PageNumbers = pageNumbers.map((number, key) => {
                return(
                    <PaginationItem key={key} active={currentPage === number ? true : false}>
                        <PaginationLink id={number} onClick={this.handleClickPagination}>
                            {number}
                        </PaginationLink>
                    </PaginationItem>
                )
            })
            
            return (
            
                <Pagination aria-label="Orders List Pagination" style={{display: showPagination ? responses.length < 1 ? 'none' : '' : 'none'}}>
                    <PaginationItem>
                        <PaginationLink previous disabled={currentPage === 1 ? true : false} onClick={this.paginationPrevious}/>
                    </PaginationItem>
                    {PageNumbers}
                    <PaginationItem>
                        <PaginationLink next onClick={this.paginationNext} disabled={currentPage === pageNumbers[pageNumbers.length - 1] ? true : false}/>
                    </PaginationItem>
                </Pagination>

            )
        }

        return (
            <div>
                <Alert color="warning" style={{display: this.state.responses.length < 1 ? '' : 'none'}}>
                    There was no survey data to display.
                </Alert>

                <div className="row" style={{display: this.state.responses.length < 1 ? 'none' : ''}}>
                    <div className="col-md-6">
                        <button onClick={this.printTable} className="btn btn-default"><i className="fa fa-print" aria-hidden="true" style={{display: this.state.responses.length < 1 ? 'none' : ''}}></i> Print</button>
                    </div>
                    <div className="col-md-6">
                        <p>Number of responses to show</p>
                        <select name="responsesPerPage" className="form-control form-control-line" value={this.state.responsesPerPage} onChange={this.handleInputChange}>
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="40">40</option>
                            <option value={this.state.responses.length}>All</option>
                        </select>
                    </div>
                </div>
            
                <ShowPagination showPagination={this.state.showPagination} currentPage={this.state.currentPage}></ShowPagination>

                <table className="table stylish-table" style={{display: this.state.responses.length < 1 ? 'none' : ''}} ref={this.surveyTable}>
                    <thead>
                        <tr>
                            <th>Customer</th>
                            <th>Overall</th>
                            <th>Venue</th>
                            <th>Security</th>
                            <th>Comment</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    {/* <ResponsesList responses={this.state.responses}></ResponsesList> */}
                    {items}
                </table>
            
                <ShowPagination showPagination={this.state.showPagination} currentPage={this.state.currentPage}></ShowPagination>
            </div>
        )
    }
}

export default SurveyResponses;
