import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink, UncontrolledAlert, Alert } from 'reactstrap';
import classnames from  'classnames';
// import profileImg from '../../assets/assets/images/users/5.jpg';
import axios from 'axios';
import qs from 'qs';
import Footer from '../../components/Footer/Footer.jsx';
import TopMenu from '../../components/TopMenu/TopMenu.jsx';
import Sidebar from '../../components/Sidebar/Sidebar.jsx';
import moment from 'moment';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle: 'Manage Profile - Ticketsasa Merchants',
            isLoggedIn: false,
            loginRedirect: false,
            blocking: false,
            activeTab: '1',
            merchant: {},
            email: '',
            name: '',
            username: '',
            contactName: '',
            role: '',
            phone: '',
            lastVisitDate: '',
            registerDate: '',
            description: '',
            website: '',
            facebookLink: '',
            instagramLink: '',
            twitterLink: '',
            merchantName: '',
            updateMerchantNotif: false,
            updateMerchantMessage: '',
            updateMerchantMessageType: 'info',
            updateUserDetailsNotif: false,
            updateUserMessage: '',
            updateUserMessageType: 'info',
            merchantKey: '',
            merchantSecret: '',
            merchantPhoto: [],
            logo_url: '',
            merchantId: ''
        }

        this.checkIfLoggedIn = this.checkIfLoggedIn.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleMerchantFormSubmit = this.handleMerchantFormSubmit.bind(this);
        this.toggleBlocking = this.toggleBlocking.bind(this);
    }

    componentWillMount() {
        // Check if the user is logged in and get merchant details
        this.checkIfLoggedIn();
    }

	componentDidMount() {
		// Update page title
		document.title = this.state.pageTitle;
    }

    toggleBlocking() {
        this.setState({blocking: !this.state.blocking});
    }
    
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
            activeTab: tab
            });
        }
    }

	imageUpload = (event) => {
		const name = event.target.name
		this.setState({
			[name]: event.target.files // Array of objects. Objects with single image properties.
		})
	}

    checkIfLoggedIn() {
        // Check local storage for the isLoggedIn value
        if(localStorage.getItem('isLoggedIn')) {
            // Get user details
            this.setState({merchant: JSON.parse(localStorage.getItem('merchant'))}); // Converting JSON string to object and storing it to state
            // console.log('Merchant details: ', JSON.parse(localStorage.getItem('merchant')))
            this.setState({name: localStorage.getItem('name')});
            this.setState({email: localStorage.getItem('email')});
            this.setState({username: localStorage.getItem('username')});
            this.setState({contactName: localStorage.getItem('contactName')});
            this.setState({phone: localStorage.getItem('phone')});
            this.setState({role: localStorage.getItem('role')});
            this.setState({lastVisitDate: localStorage.getItem('lastVisitDate')});
            this.setState({registerDate: localStorage.getItem('registerDate')});

            // console.log('merchant details: ', JSON.parse(localStorage.getItem('merchant')));
            // Merchant details
            this.setState({website: localStorage.getItem('merchantWebsite')});
            this.setState({description: localStorage.getItem('merchantDescription')});
            this.setState({merchantName: localStorage.getItem('merchantName')});
            this.setState({merchantKey: JSON.parse(localStorage.getItem('merchant')).merchant_key});
            this.setState({merchantSecret: JSON.parse(localStorage.getItem('merchant')).merchant_secret});

            let facebook = localStorage.getItem('facebookLink') ? localStorage.getItem('facebookLink') : JSON.parse(localStorage.getItem('merchant'))['fb-link'];
            this.setState({facebookLink: facebook});

            let instagram = localStorage.getItem('instagramLink') ? localStorage.getItem('instagramLink') : JSON.parse(localStorage.getItem('merchant'))['ig-link'];
            this.setState({instagramLink: instagram});

            let twitter = localStorage.getItem('twitterLink') ? localStorage.getItem('twitterLink') : JSON.parse(localStorage.getItem('merchant'))['tw-link'];
            this.setState({twitterLink: twitter});

            let merchantImage = localStorage.getItem('merchantImage') ? localStorage.getItem('merchantImage') : JSON.parse(localStorage.getItem('merchant')).logo_url;
            this.setState({logo_url: merchantImage});
            this.setState({merchantId: JSON.parse(localStorage.getItem('merchant')).id});

        } else {
            // Redirect to the login page with an error message
            this.setState({loginRedirect: true});
        }
    }
    
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        this.toggleBlocking();
        
        // Update user details
        // Make call to api
        var url = 'https://www.ticketsasa.com/index.php?option=com_enmasse&controller=merchantservice&task=updateUser';

        var dataObj = {
            'user_id': this.state.username,
            'name': this.state.name,
            'phone': this.state.phone
        }

		// Send a POST request
		axios({
            method: 'POST',
            url: url,
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(dataObj)
		})
        .then(response => {
            // Display response
            // console.log('Data response: ', response);
            
            // Store data in state    
            if(response.data.success) {
                localStorage.setItem('phone', this.state.phone);
                localStorage.setItem('name', this.state.name);
                // this.setState({orderDetails: response.data.orders});

                alert('Updated user details.');
            } else {
                alert(response.data.message ? response.data.message : 'There was an error updating your details.');
            }

            this.toggleBlocking();
        })
        .catch(error => {
            // Hanandle error
            alert(error);

            this.toggleBlocking();
        })
        .then(data => {
            // always executed
        });
    }

    handleMerchantFormSubmit(event) {
        // Getting details to be updated
        event.preventDefault();

        this.toggleBlocking();
        
        // Update user details
        // Make call to api
        var url = 'https://www.ticketsasa.com/index.php?option=com_enmasse&controller=merchantservice&task=updateMerchantInfo';

        var dataObj = {
            'user_id': this.state.username,
            'website': this.state.website,
            'name': this.state.merchantName,
            'description': this.state.description,
            'twitterLink': this.state.twitterLink,
            'instagramLink': this.state.instagramLink,
            'facebookLink': this.state.facebookLink
        }

		// Send a POST request
		axios({
            method: 'POST',
            url: url,
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(dataObj)
		})
        .then(response => {
            // Store data in state    
            if(response.data.success) {
                localStorage.setItem('merchantDescription', this.state.description);
                localStorage.setItem('merchantName', this.state.merchantName);
                localStorage.setItem('merchantWebsite', this.state.website);
                localStorage.setItem('facebookLink', this.state.facebookLink);
                localStorage.setItem('instagramLink', this.state.instagramLink);
                localStorage.setItem('twitterLink', this.state.twitterLink);

                alert('Updated merchant details.');

            } else {
                alert(response.data.message ? response.data.message : 'There was an error updating your details.');
                
            }

            this.toggleBlocking();
        })
        .catch(error => {
            // Hanandle error
            alert(error);

            this.toggleBlocking();
        })
        .then(data => {
            // always executed
        });
    }

    handlePaymentDetailsSubmit = (event) => {
        event.preventDefault();

        // Submitting payment details
        this.toggleBlocking();

        // Make call to api
        var url = 'https://www.ticketsasa.com/index.php?option=com_enmasse&controller=merchantservice&task=updateMerchantPaymentDetails';

        var dataObj = {
            'user_id': this.state.username,
            'merchantKey': this.state.merchantKey,
            'merchantSecret': this.state.merchantSecret
        }

		// Send a POST request
		axios({
            method: 'POST',
            url: url,
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(dataObj)
		})
        .then(response => {
            // Store data in state    
            if(response.data.status === "success") {
                localStorage.setItem('merchantKey', this.state.merchantKey);
                localStorage.setItem('merchantSecret', this.state.merchantSecret);

                alert('Successfully updated your payment details.');

            } else {
                alert(response.data.message ? response.data.message : 'There was an error updating your payment details.');
                
            }

            this.toggleBlocking();
        })
        .catch(error => {
            // Hanandle error
            alert(error);

            this.toggleBlocking();
        });

    }

	returnFileSize = (number) => {
		if (number < 1024) {
			return number + 'bytes';
		} else if (number >= 1024 && number < 1048576) {
			return (number / 1024).toFixed(1) + 'KB';
		} else if (number >= 1048576) {
			return (number / 1048576).toFixed(1) + 'MB';
		}
    }

    handleMerchantImageSubmit = (event) => {
		event.preventDefault();

		// Validate form
		let validateForm = this.validateMerchantImage()
		
		this.setState({
			formErrors: validateForm
        })

		if(validateForm.length < 1) {
			this.toggleBlocking()

			// If there are no errors
			// Submit form details
			let url = "https://www.ticketsasa.com/index.php?option=com_enmasse&controller=merchantservice&task=updateMerchantImage"

			let formData = new FormData();

			formData.append('merchantProfileImage', this.state.merchantPhoto[0]);
			formData.append('user_id', this.state.username);
            formData.append('merchantId', JSON.parse(localStorage.getItem('merchant')).id);

			axios.post(url, formData)
			.then(response => {
                // Check response
                // console.log('image: ', response)
				if(response.data.status === 'success') {
                    alert('Merchant photo was successfully updated!')

                    localStorage.setItem('merchantImage', response.data.data.upload_results.logo_url)

					this.setState({
                        logo_url: response.data.data.upload_results.logo_url,
						formSubmittedStatus: 'success',
						formSubmittedShow: true,
						formSubmittedMessageColour: 'success',
						formSubmittedMessage: 'Merchant photo successfully updated!'
					})

				} else if(response.data.status === 'error') {
                    alert(response.data.message ? response.data.message : 'There was an error submitting your merchant photo.')

					this.setState({
						formSubmittedStatus: 'error',
						formSubmittedShow: true,
						formSubmittedMessageColour: 'danger',
						formSubmittedMessage: response.data.message ? response.data.message : 'There was an error submitting your merchant photo.'
					})

				} else {
                    alert(response.data.message ? response.data.message : 'There was an error submitting your merchant photo.')

					// Show error (something went wrong)
					this.setState({
						formSubmittedStatus: 'error',
						formSubmittedShow: true,
						formSubmittedMessageColour: 'danger',
						formSubmittedMessage: 'There was an error submitting your merchant photo.'
					})
				}

				this.toggleBlocking()
			})
			.catch(error => {
                alert('There was an error encountered. Please try again or contact support@ticketsasa.com if the issue persists.')

				// Show error (something went wrong)
				this.setState({
                    formSubmittedStatus: 'error',
                    formSubmittedShow: true,
                    formSubmittedMessageColour: 'danger',
					formSubmittedMessage: 'There was an error submitting your merchant photo.'
				})

				this.toggleBlocking()
			})
		}
    }
    
    validateMerchantImage = () => {
		let errorArray = []

		// 4. Images upload
		if((this.state.merchantPhoto.length < 1)) {
			// If there are missing images required
			errorArray.push({errorType: 'imagesUpload', errorTitle: 'Merchant Photo is Missing', errorMessage: 'Please enter the required image.'});
			this.setState({
				validImageUploads: false 
			});
		}

		return errorArray.length > 0 ? errorArray : []
    }

    render () {
        const { loginRedirect } = this.state;

        if (loginRedirect) {
            return <Redirect to='/login?isloggedin=false'/>;
        }

        return (
            <BlockUi tag="div" blocking={this.state.blocking}>
                <div id="main-wrapper">
                    <TopMenu />
                    <Sidebar />
                    <div className="page-wrapper">
                        <div className="container-fluid">
                            <div className="row page-titles">
                                <div className="col-md-6 col-8 align-self-center">
                                    <h3 className="text-themecolor m-b-0 m-t-0">Profile</h3>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active">Profile</li>
                                    </ol>
                                </div> 
                            </div>

                            <div className="row">
                                <div className="col-md-8 center">
                                    <UncontrolledAlert color={this.state.updateMerchantMessageType ? this.state.updateMerchantMessageType : "info"} style={{display: this.state.updateMerchantNotif ? '' : 'none'}}>
                                        {this.state.updateMerchantMessage ? this.state.updateMerchantMessage : 'There was an error displaying this message'}
                                    </UncontrolledAlert>
                                    <UncontrolledAlert color={this.state.updateUserMessageType ? this.state.updateUserMessageType : "info"} style={{display: this.state.updateUserDetailsNotif ? '' : 'none'}}>
                                        {this.state.updateUserMessage ? this.state.updateUserMessage : 'There was an error displaying this message'}
                                    </UncontrolledAlert>
                                </div>
                            </div>

                            <div className="row">

                                <div className="col-lg-4 col-xlg-3 col-md-5">
                                    <div className="card">
                                        <div className="card-block">
                                            <center className="m-t-30"> 
                                                <img src={this.state.logo_url ? "https://www.ticketsasa.com/" + decodeURIComponent(this.state.logo_url) : (this.state.merchantPhoto.length > 0) ? window.URL.createObjectURL(this.state.merchantPhoto[0]) : "https://via.placeholder.com/150"} alt="profile" className="img-circle" height="150" width="150" />

                                                <h4 className="card-title m-t-10">{this.state.name}</h4>
                                                <h6 className="card-subtitle">{this.state.email}</h6>
                                                <br />
                                                <h4 className="card-title m-t-10">{this.state.merchantName}</h4>
                                                <h6 className="card-subtitle">{this.state.description}</h6>
                                                <h6 className="card-subtitle"><a href={this.state.website} target="_blank" rel="noopener noreferrer">{this.state.website}</a></h6>

                                                <h6 className="card-subtitle"><a href={this.state.instagramLink} target="_blank" rel="noopener noreferrer" style={{display: this.state.instagramLink ? '' : 'none'}}><i className="fa fa-instagram"></i></a>&nbsp;<a href={this.state.facebookLink} target="_blank" rel="noopener noreferrer" style={{display: this.state.facebookLink ? '' : 'none'}}><i className="fa fa-facebook"></i></a>&nbsp;<a href={this.state.twitterLink} target="_blank" rel="noopener noreferrer" style={{display: this.state.twitterLink ? '' : 'none'}}><i className="fa fa-twitter"></i></a></h6>

                                                <h6 className="card-subtitle"><small>Created at: {moment(this.state.merchant.created_at).format('DD MMM YYYY')}</small></h6>
                                                <h6 className="card-subtitle"><small>Updated at: {moment(this.state.merchant.updated_at).format('DD MMM YYYY')}</small></h6>
                                            </center>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-8 col-xlg-9 col-md-7">
                                    <div className="card">
                                        <div className="card-block">
                                                <Nav tabs>
                                                    <NavItem>
                                                        <NavLink
                                                        className={classnames({ active: this.state.activeTab === '1' })}
                                                        onClick={() => { this.toggle('1'); }}
                                                        >
                                                        User Details
                                                        </NavLink>
                                                    </NavItem>

                                                    <NavItem style={{display: this.state.role === "verify" ? 'none' : ''}}>
                                                        <NavLink
                                                        className={classnames({ active: this.state.activeTab === '2' })}
                                                        onClick={() => { this.toggle('2'); }}
                                                        >
                                                        Merchant Details
                                                        </NavLink>
                                                    </NavItem>

                                                    <NavItem style={{display: this.state.role === "verify" || this.state.role === "admin" ? 'none' : ''}}>
                                                        <NavLink
                                                        className={classnames({ active: this.state.activeTab === '3' })}
                                                        onClick={() => { this.toggle('3'); }}
                                                        >
                                                        Payment Details
                                                        </NavLink>
                                                    </NavItem>

                                                    <NavItem style={{display: this.state.role === "verify" ? 'none' : ''}}>
                                                        <NavLink
                                                        className={classnames({ active: this.state.activeTab === '4' })}
                                                        onClick={() => { this.toggle('4'); }}
                                                        >
                                                        Upload Merchant Photo
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav>

                                                <TabContent activeTab={this.state.activeTab}>
                                                    <TabPane tabId="1">

                                                        <br></br>

                                                        <form className="form-horizontal form-material" onSubmit={this.handleSubmit}>

                                                            <div className="form-group">
                                                                <label className="col-md-12">Name</label>
                                                                <div className="col-md-12">
                                                                    <input type="text" placeholder="" className="form-control form-control-line" value={this.state.name} name="name" onChange={this.handleInputChange} />
                                                                </div>
                                                            </div>

                                                            <div className="form-group">
                                                                <label htmlFor="example-email" className="col-md-12">Username</label>
                                                                <div className="col-md-12">
                                                                    {/* <input type="text" placeholder="" className="form-control form-control-line" name="username" value={this.state.username} onChange={this.handleInputChange} /> */}
                                                                    <p>{this.state.username ? this.state.username : 'N/A'}</p>
                                                                </div>
                                                            </div>

                                                            <div className="form-group">
                                                                <label htmlFor="example-email" className="col-md-12">Email</label>
                                                                <div className="col-md-12">
                                                                    {/* <input type="email" placeholder="" className="form-control form-control-line" name="email" value={this.state.email} onChange={this.handleInputChange} /> */}
                                                                    <p>{this.state.email ? this.state.email : 'N/A'}</p>
                                                                </div>
                                                            </div>

                                                            <div className="form-group">
                                                                <label className="col-md-12">Phone Number</label>
                                                                <div className="col-md-12">
                                                                    <input type="text" placeholder="e.g. 0700111222" className="form-control form-control-line" name="phone" value={this.state.phone} onChange={this.handleInputChange} />
                                                                </div>
                                                            </div>
                                                    
                                                            <div className="form-group">
                                                                <div className="col-sm-12">
                                                                    <button className="btn btn-success">Update Profile</button>
                                                                </div>
                                                            </div>
                                                            {/* TODO: Add form to update password. Add a clickable link that will show the form. It would be hidden save for the link. Make sure the user reenters their password so as to complete changing the password. */}

                                                            <p><small><em>Last login: {this.state.lastVisitDate ? this.state.lastVisitDate : 'N/A'}</em></small></p>
                                                            <p><small><em>Register date: {this.state.registerDate ? this.state.registerDate : 'N/A'}</em></small></p>
                                                            <p><small><em>Role: {this.state.role ? this.state.role : 'N/A'}</em></small></p>
                                                        </form>

                                                    </TabPane>

                                                    <TabPane tabId="2" style={{display: this.state.role === "verify"  ? 'none' : ''}}>

                                                        <br></br>

                                                        <form className="form-horizontal form-material" onSubmit={this.handleMerchantFormSubmit} name="merchantProfileForm">
                                                            <div className="form-group">
                                                                <label className="col-md-12">Merchant Name</label>
                                                                <div className="col-md-12">
                                                                    <input type="text" placeholder="" className="form-control form-control-line" value={this.state.merchantName} name="merchantName" onChange={this.handleInputChange} />
                                                                </div>
                                                            </div>

                                                            <div className="form-group">
                                                                <label className="col-md-12">Description</label>
                                                                <div className="col-md-12">
                                                                    <textarea className="form-control form-control-line" name="description" rows="3" value={this.state.description} onChange={this.handleInputChange} form="merchantProfileForm"></textarea>
                                                                </div>
                                                            </div>

                                                            <div className="form-group">
                                                                <label className="col-md-12">Website</label>
                                                                <div className="col-md-12">
                                                                    <input type="text" placeholder="" className="form-control form-control-line" value={this.state.website} name="website" onChange={this.handleInputChange} />
                                                                </div>
                                                            </div>

                                                            <div className="form-group">
                                                                <label className="col-md-12">Twitter Link (e.g. twitter.com/ticketsasa)</label>
                                                                <div className="col-md-12">
                                                                    <input type="text" placeholder="" className="form-control form-control-line" value={this.state.twitterLink} name="twitterLink" onChange={this.handleInputChange} />
                                                                </div>
                                                            </div>

                                                            <div className="form-group">
                                                                <label className="col-md-12">Facebook Link (e.g. facebook.com/ticketsasa)</label>
                                                                <div className="col-md-12">
                                                                    <input type="text" placeholder="" className="form-control form-control-line" value={this.state.facebookLink} name="facebookLink" onChange={this.handleInputChange} />
                                                                </div>
                                                            </div>

                                                            <div className="form-group">
                                                                <label className="col-md-12">Instagram Link (e.g. instagram.com/ticketsasa)</label>
                                                                <div className="col-md-12">
                                                                    <input type="text" placeholder="" className="form-control form-control-line" value={this.state.instagramLink} name="instagramLink" onChange={this.handleInputChange} />
                                                                </div>
                                                            </div>
                                                    
                                                            <div className="form-group">
                                                                <div className="col-sm-12">
                                                                    <button className="btn btn-success" type="submit">Update Merchant Details</button>
                                                                </div>
                                                            </div>

                                                        </form>

                                                    </TabPane>

                                                    <TabPane tabId="3" style={{display: this.state.role === "verify" || this.state.role === "admin" ? 'none' : ''}}>

                                                        <br></br>

                                                        <form className="form-horizontal form-material" onSubmit={this.handlePaymentDetailsSubmit} name="merchantPaymentDetailsForm">

                                                            <p>Please note that these are the keys to your Pesapal Merchant account. If you have not registered as one, kindly do so <a href="https://www.pesapal.com" target="_blank" rel="noopener noreferrer">here</a>. Once done, enter your keys here then you'll be able to receive payments to your Pesapal account.</p>

                                                            <div className="form-group">
                                                                <label className="col-md-12">Consumer Key</label>
                                                                <div className="col-md-12">
                                                                    <input type="text" placeholder="" className="form-control form-control-line" value={this.state.merchantKey} name="merchantKey" onChange={this.handleInputChange} />
                                                                </div>
                                                            </div>

                                                            <div className="form-group">
                                                                <label className="col-md-12">Consumer Secret</label>
                                                                <div className="col-md-12">
                                                                    <input type="text" placeholder="" className="form-control form-control-line" value={this.state.merchantSecret} name="merchantSecret" onChange={this.handleInputChange} />
                                                                </div>
                                                            </div>
                                                    
                                                            <div className="form-group">
                                                                <div className="col-sm-12">
                                                                    <button className="btn btn-success" type="submit">Update Payment Details</button>
                                                                </div>
                                                            </div>

                                                        </form>

                                                    </TabPane>

                                                    <TabPane tabId="4" style={{display: this.state.role === "verify" ? 'none' : ''}}>

                                                        <br></br>

                                                        <Alert color={this.state.formSubmittedMessageColour} style={{display: this.state.formSubmittedShow ? '' : 'none'}}>
                                                            {this.state.formSubmittedMessage}
                                                        </Alert>

                                                        <form onSubmit={this.handleMerchantImageSubmit} name="mainImageUpload">
                                                        
                                                            <div className="col-md-12">
                                                                <p style={{display: (this.state.merchantPhoto.length > 0) ? 'none' : ''}}><em><strong>No image selected</strong></em></p>
                                                                <div style={{display: (this.state.merchantPhoto.length > 0) ? '' : 'none'}}>
                                                                    <div className="form-group">
                                                                        <div className="row">
                                                                            <div className="col-md-12" style={{display: (this.state.merchantPhoto.length > 0) ? '' : 'none'}}>
                                                                                <img src={(this.state.merchantPhoto.length > 0) ? window.URL.createObjectURL(this.state.merchantPhoto[0]) : null} height="150" width="150" alt="event" />
                                                                            </div>

                                                                            <div className="col-md-6">
                                                                                <small>
                                                                                    <strong>Name</strong>
                                                                                    <p>{this.state.merchantPhoto.length > 0 ? this.state.merchantPhoto[0].name : 'N/A'}</p>
                                                                                </small>
                                                                                <small>
                                                                                    <strong>Size</strong>
                                                                                    <p>{this.state.merchantPhoto.length > 0 ? this.returnFileSize(this.state.merchantPhoto[0].size) : 'N/A'}</p>
                                                                                </small>
                                                                            </div>

                                                                            <div className="col-md-6">
                                                                                <small>
                                                                                    <strong>Type</strong>
                                                                                    <p>{this.state.merchantPhoto.length > 0 ? this.state.merchantPhoto[0].type : 'N/A'}</p>
                                                                                </small>
                                                                                <small>
                                                                                    <strong>Last Modified</strong>
                                                                                    <p>{this.state.merchantPhoto.length > 0 ? moment(this.state.merchantPhoto[0].lastModified).format('ddd, Do MMM YYYY h:mma') : 'N/A'}</p>
                                                                                </small>
                                                                            </div>                                
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-12">

                                                                <div className="form-group">
                                                                    <div className="col-md-12">
                                                                        <input type="file" className="form-control" name="merchantPhoto" files={this.state.merchantPhoto ? this.state.merchantPhoto : ""} onChange={this.imageUpload} accept=".png, .jpg, .jpeg" />
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div className="col-md-12">

                                                                <button type="submit" className="btn btn-success">Upload </button>
                                                                
                                                            </div>

                                                        </form>

                                                    </TabPane>
                                                </TabContent>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
                    
            </BlockUi>
        )
    }
}

export default Profile;
