import React, { Component } from 'react';
import { Switch, Route} from 'react-router-dom';

// Import styles
import './assets/assets/plugins/bootstrap/css/bootstrap.min.css';
import './assets/css/style.css';
import './assets/css/colors/red.css';

import Dashboard from './views/Dashboard/Dashboard.jsx';
import Profile from './views/Profile/Profile.jsx';
import NotFound from './views/NotFound/NotFound.jsx';
import EventsListing from './views/Events/Listing';
// import Sales from './views/Sales/Sales';
import Verify from './views/Verify/Verify';
import Login from './views/Identity/Login/Login';
import SingleEventSales from './views/SingleEvent/Sales';
import ComplimentaryTickets from './views/SingleEvent/ComplimentaryTickets/ComplimentaryTickets';
import CreateNew from './views/Events/CreateNew';
import SingleEventSurvey from './views/SingleEvent/Survey';
import SingleEvent from './views/SingleEvent/SingleEvent';
import Users from './views/Users/UserList';
import EditUser from './views/Users/EditUser';
import NewComplimentary from './views/SingleEvent/ComplimentaryTickets/NewComplimentary';
import ResetPassword from './views/Identity/ResetPassword/ResetPassword';
import RegisterMerchant from './views/Identity/RegisterMerchant/RegisterMerchant';
import New from './views/Events/New/New';
// import TodoApp from './views/TodoApp/TodoApp.jsx';

class App extends Component {
	render() {
		return (
			<div id="main-wrapper">
				<Main />
			</div>
		);
	}
}

const Main = () => (
	<Switch>
		<Route exact path='/' name="Dashboard" component={Dashboard}></Route>
		<Route exact path='/events' name="Events" component={EventsListing}></Route>
		<Route exact path='/profile' name="Profile" component={Profile}></Route>
		{/* <Route exact path='/sales' name="Sales" component={Sales}></Route> */}
		<Route exact path='/event/:eventId' name="Single Event" component={SingleEvent}></Route>
		<Route exact path='/event/:eventId/sales/' name="Event Sales" component={SingleEventSales}></Route>
		<Route exact path='/event/:eventId/comps/' name="Complimentary Tickets" component={ComplimentaryTickets}></Route>
		<Route exact path='/event/:eventId/survey/' name="Event Survey Details" component={SingleEventSurvey}></Route>
		<Route exact path='/event/:eventId/edit' name="Edit Event" component={New}></Route>
		<Route exact path='/events/new' name="Create New Event" component={CreateNew}></Route>
		<Route exact path='/events/create' name="Create New Event" component={New}></Route>
		<Route exact path='/verify' name="Verify" component={Verify}></Route>
		<Route exact path='/login' name="Login" component={Login}></Route>
		<Route exact path='/reset' name="Reset Password" component={ResetPassword}></Route>
		<Route exact path='/register' name="New Merchant Register" component={RegisterMerchant}></Route>
		<Route exact path='/users' name="Users" component={Users}></Route>
		<Route exact path='/users/:userId/edit' name="Edit User" component={EditUser}></Route>
		<Route exact path='/users/new' name="New User" component={EditUser}></Route>
		<Route exact path='/event/:eventId/comps/new' name="Generate Complimentary Tickets" component={NewComplimentary}></Route>
		<Route path="*" component={NotFound}></Route>
	</Switch>
);

export default App;
