import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Button, Input } from 'reactstrap';
import axios from 'axios';
import qs from 'qs';

import Footer from '../../components/Footer/Footer.jsx';
import TopMenu from '../../components/TopMenu/TopMenu.jsx';
import Sidebar from '../../components/Sidebar/Sidebar.jsx';

import EventsTable from '../../components/EventsListing/EventsTable.jsx';

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

class Listing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle: 'Events Listing - Ticketsasa Merchants',
            loginRedirect: false,
            imageBase: null,
            name: '',
            username: '',
            email: '',
            merchant: {},
            merchantId: '',
            events: [],
            blocking: false,
            filterVariables: {
                filterSearch: null,
                filterUnpublished: null
            }
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleFilterInputChange = this.handleFilterInputChange.bind(this);
        this.handleFilterSubmit = this.handleFilterSubmit.bind(this);
        this.checkIfLoggedIn = this.checkIfLoggedIn.bind(this);
        this.getEvents = this.getEvents.bind(this);
        this.toggleBlocking = this.toggleBlocking.bind(this);
    }

    componentWillMount() {
        // Check if user is logged in and get their details
        this.checkIfLoggedIn();
    }

	componentDidMount() {
		// Update page title
		document.title = this.state.pageTitle;
	}

    toggleBlocking() {
        this.setState({blocking: !this.state.blocking});
    }

    checkIfLoggedIn() {
        // Check local storage for the isLoggedIn value
        if(localStorage.getItem('isLoggedIn')) {
            // Get user details
            this.setState({merchant: JSON.parse(localStorage.getItem('merchant'))}); // Converting JSON string to object and storing it to state
            this.setState({name: localStorage.getItem('name')});
            this.setState({email: localStorage.getItem('email')});
            this.setState({username: localStorage.getItem('username')});
            this.setState({merchantId: localStorage.getItem('id')});

            // console.log('These are the merchant details: ', JSON.parse(localStorage.getItem('merchant')));

            this.getEvents(JSON.parse(localStorage.getItem('merchant')));

        } else {
            // Redirect to the login page with an error message
            this.setState({loginRedirect: true});
        }
    }
    
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
            [name]: value
        });
    }

    handleFilterSubmit(event) {
        event.preventDefault();
        // Get filter variables and pass them to getSalesData
        // this.getSalesData(this.state.eventId, this.state.filterVariables.filterEmail, this.state.filterVariables.filterCompsOnly, this.state.filterVariables.filterOrderId);
        this.getEvents(this.state.merchant, this.state.filterVariables.filterSearch, this.state.filterVariables.filterUnpublished);
    }

    handleFilterInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
            filterVariables: {
                [name]: value
            }
        });
    }

    getEvents(merchant = null, search = null, unpublished = null) {
        // Add loader
        this.toggleBlocking();

        // Getting event and ticket sales
        let url = 'https://www.ticketsasa.com/index.php?option=com_enmasse&controller=merchantservice&task=getEvents';

        var dataObj = {
            'user_id': merchant.user_name,
            'search' : search ? search : '',
            'unpublished' : unpublished ? unpublished : ''
        }

		axios({
            method: 'POST',
            url: url,
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(dataObj)
		})
        .then(response => {
            // Prepare data
            // Check if request was successful
            // console.log('events : ', response )

            if(response.data.response === "success") {
                // Get required event data
                // console.log('Events data: ', response.data);
                this.setState({events: response.data.data.events});
                this.setState({imageBase: response.data.data.imageBase});

            } else {
                // Get error details
                // console.log('Error response: ', response);

                // TODO: Display message on the page

            }

            // Remove UI blocker
            this.toggleBlocking();
        })
        .catch(error => {
            // TODO: Display error message on the page

            // Remove UI blocker
            this.toggleBlocking();

        })
        .then(function () {
            // Always executed

            // Remove UI blocker
            // this.toggleBlocking();
        });
    }

    render () {
        const { loginRedirect } = this.state;

        if (loginRedirect) {
            return <Redirect to='/login?isloggedin=false'/>;
        }

        return (
            <BlockUi tag="div" blocking={this.state.blocking}>
                
                <div id="main-wrapper">
                    <TopMenu />
                    <Sidebar />
                    <div className="page-wrapper" style={{minHeight: "643px"}}>
                    
                        <div className="container-fluid">
                            <div className="row page-titles">
                                <div className="col-md-6 col-8 align-self-center">
                                    <h3 className="text-themecolor m-b-0 m-t-0">Events</h3>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active">Events</li>
                                    </ol>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-6">
                                            {/* <Link to="/events/create" className="btn btn-default"><i className="fa fa-plus" aria-hidden="true"></i> Create new event</Link> */}
                                        </div>                                    
                                    </div>

                                    <br />

                                    <div className="card">
                                        <div className="card-block">
                                            <p>Select/search for an event</p>
                                            <form className="form-horizontal" onSubmit={this.handleFilterSubmit}>
                                                <div className="row">
                                                    <div className="col-md-6 col-sm-6">
                                                        <div className="form-group">
                                                            <Input type="text" name="filterSearch" id="filterSearch" placeholder="Event Name" onChange={this.handleFilterInputChange}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-sm-4">
                                                        <div className="form-group">
                                                            <Input type="select" name="filterUnpublished" onChange={this.handleFilterInputChange}>
                                                                <option value="0">All</option>
                                                                <option value="2">Pending Events Only</option>
                                                                <option value="1">Published Events Only</option>
                                                            </Input>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2 col-sm-2">
                                                        <Button color="success">Search</Button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-block">
                                            <h4 className="card-title">All Events</h4>
                                            <h6 className="card-subtitle">List of all events</h6>

                                            <EventsTable eventsArray={this.state.events} imageBase={this.state.imageBase} showPagination={true}></EventsTable>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        
                        <Footer />

                    </div>
                </div>

            </BlockUi>
        )
    }
}

export default Listing;