import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import axios from 'axios';
import qs from 'qs';
import Footer from '../../components/Footer/Footer.jsx';
import TopMenu from '../../components/TopMenu/TopMenu.jsx';
import Sidebar from '../../components/Sidebar/Sidebar.jsx';

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

class UserList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle: 'Manage Users - Ticketsasa Merchants',
            isLoggedIn: false,
            loginRedirect: false,
            activeTab: '1',
            merchant: {},
            userdets: [],
            userId:[],
            name: '',
			email: '',
			phone: '',
			password: '',
			role:"",
			message:"",
            postRedirect: false,
            newName: '',
			newEmail: '',
			newPhone: '',
			newRole:"",
			newPassword: '',
            newConfirmPassword: '',
            blocking: false
        }

        this.checkIfLoggedIn = this.checkIfLoggedIn.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillMount() {
        // Check if the user is logged in and get merchant details
        this.checkIfLoggedIn();
    }

	componentDidMount() {
		// Update page title
        document.title = this.state.pageTitle;
        const { userId } = this.props.match.params;
        this.setState({userId: userId});
    }

    toggleBlocking = () => {
        this.setState({blocking: !this.state.blocking});
    }

    checkIfLoggedIn() {
        // Check local storage for the isLoggedIn value
        if(localStorage.getItem('isLoggedIn')) {
            // Get user details
            this.setState({merchant: JSON.parse(localStorage.getItem('merchant'))}); // Converting JSON string to object and storing it to state
            this.setState({name: localStorage.getItem('name')});
            this.setState({email: localStorage.getItem('email')});
            this.setState({username: localStorage.getItem('username')});
            this.setState({contactName: localStorage.getItem('contactName')});

        } else {
            // Redirect to the login page with an error message
            this.setState({loginRedirect: true});
        }
    }
    
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
            [name]: value
        });
    }
    
    handleSubmit(event) {
        event.preventDefault();
        const { newPassword, newConfirmPassword } = this.state;

        // Block Ui
        this.toggleBlocking()
        
        // Perform all neccassary validations
        if (newPassword !== newConfirmPassword) {
            alert("Passwords don't match");
        }

        else if(newPassword.length < 6) {
            alert("Passwords should be more 6 or more characters");
        }

        else {
            // Post new user details
            var url = 'https://www.ticketsasa.com/index.php?option=com_enmasse&controller=merchantservice&task=newUser';

            var dataObj = {
                'user_id': JSON.parse(localStorage.getItem('merchant')).user_name,
                'email': this.state.newEmail,
                'phone': this.state.newPhone,
                'name': this.state.newName,
                'password': this.state.newPassword,
                'user_role': this.state.newRole,
                'check_password': 0
            }
            
            // console.log(dataObj);
            // Send a POST request
            axios({
                method: 'POST',
                url: url,
                headers: {
                    'content-type': 'application/x-www-form-urlencoded'
                },
                data: qs.stringify(dataObj)
            })
            .then(response => {
                // console.log(response);

                // Display response
                // Store data in state    
                if (response.data.success) {
                    // Show success alert
                    alert('User was successfully created!')

                    // Clear state
                    this.setState({
                        newEmail: "",
                        newPhone: "",
                        newName: "",
                        newRole: "",
                        newPassword: "",
                        newConfirmPassword: ""
                    });

                    // Redirect to users page
                    this.setState({postRedirect: true});
                } 
                
                else if (response.data.error) {
                    // Show error message
                    alert(response.data.message);
                }

                else {
                    // Show error
                    alert('There was an error creating new user.')
                }

                // Remove UI blocker
                this.toggleBlocking()

            })
            .catch(error => {
                // Hanandle error
                alert(error);

                // Remove UI blocker
                this.toggleBlocking()
            });
        }
    }

    render () {
        const { loginRedirect } = this.state;

        if (loginRedirect) {
            return <Redirect to='/login?isloggedin=false'/>;
        }

        const postRedirect = this.state.postRedirect;

        if (postRedirect === true) {
            var path='/users?created=true';
            return	<Redirect to={path}/>
        }
        
        return (
            <BlockUi tag="div" blocking={this.state.blocking}>

                <div id="main-wrapper">
                    <TopMenu />
                    <Sidebar />
                    <div className="page-wrapper" style={{minHeight: "643px"}}>
                        <div className="container-fluid">
                            <div className="row page-titles">
                                <div className="col-md-6 col-8 align-self-center">
                                    <h3 className="text-themecolor m-b-0 m-t-0">Users</h3>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item"><Link to="/users">Users</Link></li>
                                        <li className="breadcrumb-item active">{this.state.userdets.name ? "Edit Details" : "New User"}</li>
                                    </ol>
                                </div> 
                            </div>

                            <div className="row">

                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-block">
                                            <h4 className="card-title">Create New User</h4>
                                            <h6 className="card-subtitle">Enter new user details</h6>
                                            <form className="form-horizontal form-material" onSubmit={this.handleSubmit} >
                                                <br></br>
                                                <div className="form-group">
                                                    <label className="col-md-12">Name</label>
                                                    <div className="col-md-12">
                                                        <input type="text" placeholder="Enter Name" className="form-control form-control-line" name="newName" onChange={this.handleInputChange} required />
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label className="col-md-12">Email</label>
                                                    <div className="col-md-12">
                                                        <input type="email" placeholder="Enter Email" className="form-control form-control-line" name="newEmail" onChange={this.handleInputChange} required />
                                                    </div>
                                                </div>                                          

                                                <div className="form-group">
                                                    <label className="col-md-12">Phone Number</label>
                                                    <div className="col-md-12">
                                                        <input type="text" name="newPhone" placeholder="e.g. 0700111222" onChange={this.handleInputChange} className="form-control form-control-line" autoComplete="off" required />
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label className="col-sm-12">Select Role</label>
                                                    <div className="col-sm-12">
                                                        <select className="form-control form-control-line required" name="newRole" onChange={this.handleInputChange} required>
                                                            <option value="" >- Select Role -</option>
                                                            <option value="admin">Admin</option>
                                                            <option value="verify">Verify</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label className="col-md-12">Password <small>(Should be more than 6 characters long)</small></label>
                                                    <div className="col-md-12">
                                                        <input type="password" name="newPassword" placeholder="Enter Password" className="form-control form-control-line" onChange={this.handleInputChange} autoComplete="false" required />
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label className="col-md-12">Confirm Password</label>
                                                    <div className="col-md-12">
                                                        <input type="password" name="newConfirmPassword" placeholder="Confirm Password" className="form-control form-control-line" onChange={this.handleInputChange} required />
                                                    </div>
                                                </div>
                                                
                                                <div className="form-group">
                                                    <div className="col-sm-12">
                                                        <button type="submit" className="btn btn-success">Create User</button>
                                                    </div>
                                                </div>
                                                {/* TODO: Add form to update password. Add a clickable link that will show the form. It would be hidden save for the link. Make sure the user reenters their password so as to complete changing the password. */}
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
                    
            </BlockUi>
        )
    }
}

export default UserList;
