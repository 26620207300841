import React, {Component} from 'react';
import { Link, Redirect } from 'react-router-dom';
import ticketsasaLogoIcon from '../../assets/assets/images/logo-icon.png';
import ticketsasaLogoText from '../../assets/assets/images/logo-text.png';
// import userOneImage from '../../assets/assets/images/users/1.jpg';
import { NavLink } from 'reactstrap';

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

class TopMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            username: '',
            merchant: {},
            name: '',
            dropdownOpen: false,
            loginPageRedirect: false,
            profilePageRedirect: false,
            logoUrl: ''
        }

        this.getUserDetails = this.getUserDetails.bind(this);
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.logoutUser = this.logoutUser.bind(this);
        this.redirectToProfilePage = this.redirectToProfilePage.bind(this);
    }

    componentWillMount() {
        this.getUserDetails();
    }

    toggleDropdown() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    getUserDetails() {
        // Get user details from localStorage
        this.setState({name: localStorage.getItem('name')});
        this.setState({email: localStorage.getItem('email')});
        this.setState({merchant: JSON.parse(localStorage.getItem('merchant'))});
        this.setState({username: localStorage.getItem('username')});
        let merchantImage = localStorage.getItem('merchantImage') ? localStorage.getItem('merchantImage') : JSON.parse(localStorage.getItem('merchant')).logo_url;
        this.setState({logoUrl: merchantImage});
    }

    logoutUser() {
        // Clear local storage then redirect
        localStorage.clear();

        // Redirect to the login page with message
        this.setState({ loginPageRedirect: true });
    }

    redirectToProfilePage() {
        // Redirect to the profile page
        this.setState({ profilePageRedirect: true });
    }

    render () {
        const { loginPageRedirect } = this.state;
        const { profilePageRedirect } = this.state;

        if (loginPageRedirect) {
            return <Redirect to='/login?loggedout=true'/>;
        }

        if (profilePageRedirect) {
            return <Redirect to='/profile' />;
        }

        return (
            <header className="topbar is_stuck">
                <nav className="navbar top-navbar navbar-toggleable-sm navbar-light">
                    <div className="navbar-header">
                        <Link to="/" className="navbar-brand">
                            <b>
                                {/* <i className="wi wi-sunset"></i>  */}
                                <img src={ticketsasaLogoIcon} alt="homepage" className="small-logo" />
                                
                            </b>
                            <span>
                                <img src={ticketsasaLogoText} alt="homepage" className="dark-logo" />
                            </span>
                        </Link>
                    </div>
                    <div className="navbar-collapse">
                        <ul className="navbar-nav mr-auto mt-md-0 ">
                            {/* <li className="nav-item"> <a className="nav-link nav-toggler hidden-md-up text-muted waves-effect waves-dark" href="javascript:void(0);"><i className="ti-menu"></i></a> </li>
                            <li className="nav-item hidden-sm-down">
                                <form className="app-search p-l-20">
                                    <input type="text" className="form-control" placeholder="Search for..."/> <a className="srh-btn"><i className="ti-search"></i></a>
                                </form>
                            </li> */}
                        </ul>
                        <ul className="navbar-nav my-lg-0">
                            <li className="nav-item dropdown">
                                <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown} >
                                    <DropdownToggle caret className="nav-link dropdown-toggle waves-effect waves-dark text-white" tag="a" aria-haspopup={true} data-toggle="dropdown">
                                        <img src={this.state.logoUrl ? "https://www.ticketsasa.com/" + decodeURIComponent(this.state.logoUrl) : "https://via.placeholder.com/150"} alt="user" className="profile-pic m-r-5" /> {this.state.name}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {/* <DropdownItem onClick={this.redirectToProfilePage}>Manage Profile</DropdownItem> */}
                                        <NavLink href="/profile">Manage Profile</NavLink>
                                        <DropdownItem divider />
                                        {/* <DropdownItem onClick={this.logoutUser}>Log Out</DropdownItem> */}
                                        <NavLink onClick={this.logoutUser}>Log Out</NavLink>
                                    </DropdownMenu>
                                </Dropdown>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
        )
    }
}

export default TopMenu;
