import React, { Component } from 'react';
import { Redirect, Link  } from 'react-router-dom';
import { Alert, UncontrolledAlert } from 'reactstrap';
import axios from 'axios';
import qs from 'qs';
import Footer from '../../components/Footer/Footer.jsx';
import TopMenu from '../../components/TopMenu/TopMenu.jsx';
import Sidebar from '../../components/Sidebar/Sidebar.jsx';
import Users from '../../components/Users/Users.jsx';

class UserList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle: 'Manage Users - Ticketsasa Merchants',
            isLoggedIn: false,
            loginRedirect: false,
            activeTab: '1',
            merchant: {},
            users: [],
            email: '',
            name: '',
            username: '',
            contactName: '',
            created: false
        }

        this.getUsers = this.getUsers.bind(this);
        this.checkIfLoggedIn = this.checkIfLoggedIn.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggleBlocking = this.toggleBlocking.bind(this);
    }

    componentWillMount() {
        // Check if the user is logged in and get merchant details
        this.checkIfLoggedIn();
    }

	componentDidMount() {
		// Update page title
        document.title = this.state.pageTitle;
        
        this.getUsers()
        this.checkParams()
    }

    // Check for params in the url
    checkParams = () => {
        // Get url segments
        var created = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).created;

        if(created) {
            if(created === 'true') {
                this.setState({created: true});
            }  
        }
    }
    
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
            activeTab: tab
            });
        }
    }

    checkIfLoggedIn() {
        // Check local storage for the isLoggedIn value
        if(localStorage.getItem('isLoggedIn')) {
            // Get user details
            this.setState({merchant: JSON.parse(localStorage.getItem('merchant'))}); // Converting JSON string to object and storing it to state
            this.setState({name: localStorage.getItem('name')});
            this.setState({email: localStorage.getItem('email')});
            this.setState({username: localStorage.getItem('username')});
 
 
        } else {
            // Redirect to the login page with an error message
            this.setState({loginRedirect: true});
        }
    }

    toggleBlocking() {
        this.setState({blocking: !this.state.blocking});
    }
     
    getUsers() {
        // Get single event orders
        // Add loader
        this.toggleBlocking();

        // Make call to api
        var url = 'https://www.ticketsasa.com/index.php?option=com_enmasse&controller=merchantservice&task=getEventUsers';

        var dataObj = {
            'user_id': JSON.parse(localStorage.getItem('merchant')).user_name
        }

		// Send a POST request
		axios({
            method: 'POST',
            url: url,
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(dataObj)
		})
        .then(response => {
            // Display response
           
            
            // Store data in state    
            if(response.data.success) {
                 this.setState({users: response.data.users});
            }

            // Remove UI blocker
            this.toggleBlocking();
        })
        .catch(error => {
            // Hanandle error
            alert(error);
            
            // Remove UI blocker
            this.toggleBlocking();
        })
        .then(data => {
            // always executed
            
        });
    }
     
    render () {
        const { loginRedirect } = this.state;

        if (loginRedirect) {
            return <Redirect to='/login?isloggedin=false'/>;
        }
        
        return (
			<div id="main-wrapper">
                <TopMenu />
                <Sidebar />
                <div className="page-wrapper" style={{minHeight: "643px"}}>
                    <div className="container-fluid">
                        <div className="row page-titles">
                            <div className="col-md-6 col-8 align-self-center">
                                <h3 className="text-themecolor m-b-0 m-t-0">Users</h3>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item active">Users</li>
                                </ol>
                            </div> 
                        </div>

                        <div className="row">

                            <div className="col-sm-12">
                                <div className="row">
                                    <div className="col-6">
                                        <Link to="/users/new" className="btn btn-default"><i className="fa fa-plus" aria-hidden="true"></i> Create a new user</Link>
                                    </div>                                    
                                </div>
                                
                                <br />

                                <div className="card">
                                    <div className="card-block">
                                        <form className="form-horizontal form-material" onSubmit={this.handleSubmit}>
                                            
                                            <Alert color="warning" style={{display: this.state.users.length < 1 ? '' : 'none'}}>
                                                There are no users to display.
                                            </Alert>

                                            <UncontrolledAlert color="success" fade={true} style={{display: this.state.created ? '' : 'none'}}>
                                                User successfully created!
                                            </UncontrolledAlert>

                                            <table className="table stylish-table" style={{display: this.state.users.length < 1 ? 'none' : ''}}>
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        <th>Latest Activity</th>
                                                    </tr>
                                                </thead>
                                                <Users users={this.state.users}></Users>
                                            </table>

                                         </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
                
        )
    }
}

export default UserList;
