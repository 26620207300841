import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import axios from 'axios';
import qs from 'qs';
import Footer from '../../../components/Footer/Footer.jsx';
import TopMenu from '../../../components/TopMenu/TopMenu.jsx';
import Sidebar from '../../../components/Sidebar/Sidebar.jsx';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import EventDetails from '../../../components/SingleEvent/CreateNew/EventDetails/EventDetails'
import TicketDetails from '../../../components/SingleEvent/CreateNew/TicketDetails/TicketDetails'
import ImagesUpload from '../../../components/SingleEvent/CreateNew/ImagesUpload/ImagesUpload'

class New extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pageTitle: 'Create New Event - Ticketsasa Merchants',
			isLoggedIn: false,
			loginRedirect: false,
			blocking: false,
			merchant: {},
			email: '',
			name: '',
            phone: '',
            username: '',
			contactEmail: '',
			contactName: '',
			contactPhoneNumber: '',
			activeTab: '1',
			formErrors: [],
            eventDetails: {},
			eventId: null,
			eventCategories: []
		}
	}

	componentWillMount() {
		// Check if the user is logged in and get merchant details
		this.checkIfLoggedIn();
	}

	componentDidMount() {
		// Update page title
		document.title = this.state.pageTitle; 

		// Check if there is an event Id & pull details from it
        const { eventId } = this.props.match.params;
		this.setState({eventId: eventId});
		
		// If an event Id exists, get event details
		if(eventId) {
			// Update page title
			document.title = 'Edit Event - Ticketsasa Merchants';
			this.toggle('2');
			this.getEventDetails(eventId);
		}
	}

	checkIfLoggedIn = () => {
		// Check local storage for the isLoggedIn value
		if(localStorage.getItem('isLoggedIn')) {
			// Get user details
			this.setState({merchant: JSON.parse(localStorage.getItem('merchant'))}); // Converting JSON string to object and storing it to state
			this.setState({name: localStorage.getItem('name')});
			this.setState({email: localStorage.getItem('email')});
			this.setState({phone: localStorage.getItem('phone')});
            this.setState({username: localStorage.getItem('username')});
			
			this.setState({contactName: localStorage.getItem('name')});
			this.setState({contactEmail: localStorage.getItem('email')});
			this.setState({contactPhoneNumber: localStorage.getItem('phone')});

		} else {
			// Redirect to the login page with an error message
			this.setState({loginRedirect: true});
		} 
	}
	
	getEventDetails(eventId = null) {
        // Getting event details
        // Block UI
        this.toggleBlocking();

        let url = 'https://www.ticketsasa.com/index.php?option=com_enmasse&controller=merchantservice&task=getEventDetails';

        var dataObj = {
            'deal_id': eventId,
            'user_id': localStorage.getItem('username')
        }

		axios({
            method: 'POST',
            url: url,
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(dataObj)
		})
        .then(response => {
			// console.log(response)
            // Prepare data
            // Check if request was successful
            if(response.data.success) {
                // Get required event data
				this.setState({eventDetails: response.data.deal})
				this.setState({eventCategories: response.data.dealCategories})

            } else {
                // Get error details
                // TODO: Display TOAST message on the page
                alert('There was an error getting event data.')

            }

            this.toggleBlocking()
        })
        .catch(error => {
            // Display error message on the page
            alert(error);
            
            this.toggleBlocking()

        });
	}

	toggle = (tab) => {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
	}

    toggleBlocking = () => {
        this.setState({blocking: !this.state.blocking});
    }

	render() {
        const { loginRedirect } = this.state;

        if (loginRedirect) {
            return <Redirect to='/login?isloggedin=false'/>;
		}
		
		return(
			<ErrorBoundary>
				<BlockUi tag="div" blocking={this.state.blocking}>
					
				<div id="main-wrapper">
					<TopMenu />
					<Sidebar />

					<div className="page-wrapper" style={{minHeight: "643px"}}>
						<div className="container-fluid">
							<div className="row page-titles">
								<div className="col-md-12 col-12 align-self-center">
									<h3 className="text-themecolor m-b-0 m-t-0">{this.state.eventId ? 'Edit Event' : 'Create New'}</h3>
									<ol className="breadcrumb">
										<li className="breadcrumb-item"><Link to="/">Home</Link></li>
										<li className="breadcrumb-item"><Link to="/events">Events</Link></li>
										<li className="breadcrumb-item active">{this.state.eventId ? this.state.eventDetails.name ? this.state.eventDetails.name + ' (' + this.state.eventDetails.deal_code + ')' : 'Edit Event' : 'Create New'}</li>
									</ol>
								</div>
							</div>
								
							<div className="row">
								<div className="col-12">
									<div className="card">
										<div className="card-block">

											{/* <Alert color="success" style={{display: this.state.events.length < 1 ? '' : 'none'}}>
												Coming soon!
											</Alert> */}
	
											<Nav tabs>
												<NavItem>
													<NavLink
													className={classnames({ active: this.state.activeTab === '1' })}
													onClick={() => { this.toggle('1'); }} style={{display: this.state.eventId ? 'none' : ''}}>
														Get Started
													</NavLink>
												</NavItem>
												<NavItem>
													<NavLink
													className={classnames({ active: this.state.activeTab === '2' })}
													onClick={() => { this.toggle('2'); }}>
														Event Details
													</NavLink>
												</NavItem>
												<NavItem style={{pointerEvents: this.state.eventId ? '' : 'none', opacity: this.state.eventId ? '' : '0.5'}}>
													<NavLink
													className={classnames({ active: this.state.activeTab === '3' })}
													onClick={() => { this.toggle('3'); }}
													>
														Tickets &amp; Pricing
													</NavLink>
												</NavItem>
												<NavItem style={{pointerEvents: this.state.eventId ? '' : 'none', opacity: this.state.eventId ? '' : '0.5'}}>
													<NavLink
													className={classnames({ active: this.state.activeTab === '4' })}
													onClick={() => { this.toggle('4'); }}
													>
														Images Upload
													</NavLink>
												</NavItem>
											</Nav>
											
											<br></br>
											<TabContent activeTab={this.state.activeTab}>
												
												<TabPane tabId="1" style={{display: this.state.eventId ? 'none' : ''}}>
													<p>Thank you for showing interest in listing your event. Using this tool, you'll be able to share the required information or save the process to continue later.</p>
													<br />
													<p>Before you proceed,</p>
													<p><strong>1.</strong> If you haven't already, as the event owner you will need to go to <a href="https://www.pesapal.com" target="_blank" rel="noopener noreferrer">www.pesapal.com</a> and register as a merchant</p>
													<p><strong>2.</strong> You will receive two emails from <a href="mailto:info@pesapal.com" target="_blank" rel="noopener noreferrer">info@pesapal.com</a>, one to activate your account and another with a consumer key and secret. Keep these at reach as you will need the details to receive payments for your events.</p>
													<br />
												
												</TabPane>

												<TabPane tabId="2">
													<EventDetails userName={this.state.username} contactEmail={this.state.contactEmail} contactName={this.state.contactName} contactPhoneNumber={this.state.contactPhoneNumber} toggleBlocking={this.toggleBlocking} eventDetails={this.state.eventDetails} eventCategories={this.state.eventCategories} eventId={this.state.eventId}></EventDetails>
												</TabPane>
											
												<TabPane tabId="3">
													<TicketDetails userName={this.state.username} toggleBlocking={this.toggleBlocking} eventId={this.state.eventId} eventDetails={this.state.eventDetails}></TicketDetails>
												</TabPane>

												<TabPane tabId="4">
													<ImagesUpload userName={this.state.username} eventId={this.state.eventId} toggleBlocking={this.toggleBlocking} eventDetails={this.state.eventDetails}></ImagesUpload>
												</TabPane>

											</TabContent>

										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				
					<Footer /> 
				</div>

				</BlockUi>
			</ErrorBoundary>
		)
	}
}

class ErrorBoundary extends React.Component {
	constructor(props) {
	super(props);
	this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, info) {
		// You can also log the error to an error reporting service
		// logErrorToMyService(error, info);
		console.log(error)
		console.log(info)
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return <h1>Something went wrong.</h1>;
		}

		return this.props.children; 
	}
}

export default New;
