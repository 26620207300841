import React, { Component } from "react";
import whiteLogo from '../../../assets/assets/images/logo-text.png';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';
import axios from 'axios';
import qs from 'qs';

class RegisterMerchant extends Component  {
    constructor(props) {
        super(props);
        this.state = {
            blocking: false,
            pageTitle: 'Create Merchant Account - Ticketsasa Merchants',
            merchantName: '',
            ownerFirstName: '',
            ownerLastName: '',
            ownerEmail: '',
            ownerPhoneNumber: '',
            password: '',
            confirmPassword: '',
            merchantType: ''
        }
    }

    componentDidMount() {
		// Update page title
        document.title = this.state.pageTitle; 
    }

    toggleBlocking = () => {
        this.setState({blocking: !this.state.blocking});
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
            [name]: value
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        
        // Submit new merchant details
        let url = "https://www.ticketsasa.com/index.php?option=com_enmasse& controller=merchantservice&task=registerMerchant";

        var dataObj = {
            'merchantName' : this.state.merchantName ? this.state.merchantName : '',
            'ownerFirstName' : this.state.ownerFirstName ? this.state.ownerFirstName : '',
            'ownerLastName' : this.state.ownerLastName ? this.state.ownerLastName : '',
            'ownerEmail' : this.state.ownerEmail ? this.state.ownerEmail : '',
            'ownerPhoneNumber' : this.state.ownerPhoneNumber ? this.state.ownerPhoneNumber : '',
            'password' : this.state.password ? this.state.password : '',
            'confirmPassword' : this.state.confirmPassword ? this.state.confirmPassword : '',
            'merchantType' : this.state.merchantType ? this.state.merchantType : 'both'
        }

        this.toggleBlocking();

		axios({
            method: 'POST',
            url: url,
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(dataObj)
		})
        .then(response => {
            this.toggleBlocking();

            if(response.data.status === 'success') {
                // Show message
                this.setState({
                    registerStatus: true,
                    registerError: false
                })

                // Clear fields
                this.setState({
                    merchantName: '',
                    ownerFirstName: '',
                    ownerLastName: '',
                    ownerEmail: '',
                    ownerPhoneNumber: '',
                    password: '',
                    confirmPassword: '',
                    merchantType: ''
                })

                alert('Your account was successfully created! Login to get started.')
                
            } else if(response.data.status === 'error') {
                // Show error message
                this.setState({
                    registerError: true,
                    registerErrorMessage: response.data.message
                })

                alert(response.data.message ? response.data.message : 'There was an error creating your merchant account. Please try again. If the issue persists, reach out to events@ticketsasa.com.')

            } else {
                // Show error message
                this.setState({
                    registerError: true
                })

                alert('There was an error creating your merchant account. Please try again. If the issue persists, reach out to events@ticketsasa.com.')

            }

            // Scroll to the top
            window.scrollTo(0,0);

            // Show success message
            this.setState({showMessage: true});
        })
        .catch(error => {
            this.toggleBlocking();
            alert(error);
        });
    }

    render() {
        return (
            <BlockUi tag="div" blocking={this.state.blocking}>
                
            <div className="">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-3"></div>
                        <div className="col-md-6">
                            <center>
                                <Link to="/"><img src={whiteLogo} alt="Ticketsasa"></img></Link>
                            </center>
                            <br /><br />
                            <div className="card">
                                <div className="card-block">
                                    <form className="form-horizontal form-material" onSubmit={this.handleSubmit}>
                                        <Alert color="success" style={{display: this.state.registerStatus ? '' : 'none'}}>Your account has been successfully created! Login <Link to="/login">here</Link> to proceed.</Alert>
                                        <Alert color="danger" style={{display: this.state.registerError ? '' : 'none'}}>{this.state.registerErrorMessage ? this.state.registerErrorMessage : 'There was an error creating your account. Please retry or contact support@ticketsasa.com if the issue persists.'}</Alert>
                                        <center><h5>Create Merchant Account</h5></center>
                                        <br />
                                        <div className="form-group">
                                            <label htmlFor="" className="col-md-12">Merchant Name</label>
                                            <div className="col-md-12">
                                                <input type="text" placeholder="" className="form-control form-control-line" name="merchantName" value={this.state.merchantName} onChange={this.handleInputChange} required />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="" className="col-md-12">Contact First Name</label>
                                            <div className="col-md-12">
                                                <input type="text" placeholder="" className="form-control form-control-line" name="ownerFirstName" value={this.state.ownerFirstName} onChange={this.handleInputChange} required />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="" className="col-md-12">Contact Last Name</label>
                                            <div className="col-md-12">
                                                <input type="text" placeholder="" className="form-control form-control-line" name="ownerLastName" value={this.state.ownerLastName} onChange={this.handleInputChange} required />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="" className="col-md-12">Contact Email</label>
                                            <div className="col-md-12">
                                                <input type="email" placeholder="" className="form-control form-control-line" name="ownerEmail" value={this.state.ownerEmail} onChange={this.handleInputChange} required />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="" className="col-md-12">Contact Phone Number</label>
                                            <div className="col-md-12">
                                                <input type="text" placeholder="" className="form-control form-control-line" name="ownerPhoneNumber" value={this.state.ownerPhoneNumber} onChange={this.handleInputChange} required />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="" className="col-md-12">Merchant Type</label>
                                            <div className="col-md-12">
                                                <select className="form-control form-control-line" name="merchantType" value={this.state.merchantType} onChange={this.handleInputChange} required>
                                                    <option value="">- Select Type -</option>
                                                    <option value="event">Events</option>
                                                    <option value="packages">Holiday Packages</option>
                                                    <option value="both">Both</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="" className="col-md-12">Password</label>
                                            <div className="col-md-12">
                                                <input type="password" placeholder="" className="form-control form-control-line" name="password" value={this.state.password} onChange={this.handleInputChange} required />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="" className="col-md-12">Confirm Password</label>
                                            <div className="col-md-12">
                                                <input type="password" placeholder="" className="form-control form-control-line" name="confirmPassword" value={this.state.confirmPassword} onChange={this.handleInputChange} required />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="col-sm-12">
                                                <button className="btn btn-success" type="submit">Register</button>
                                                <br /><br />
                                                
                                                <p><Link to="/login">Go back to login page</Link></p>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3"></div>
                    </div>
                </div>
            </div>
            
            </BlockUi>
        )
    }
}

export default RegisterMerchant;
