import React, {Component} from 'react'
import axios from 'axios'
import qs from 'qs'
import moment from 'moment'
import { Alert, Input } from 'reactstrap'
import 'react-block-ui/style.css'

class EventDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            username: '',
			contactEmail: '',
			contactName: '',
			contactPhoneNumber: '',
			eventName: '',
			eventDescription: '',
			eventVenue: '',
			eventStartDate: '',
			eventStartTime: '',
			eventEndDate: '',
			eventEndTime: '',
			eventTags: '',
			tags: '',
            countryList: [],
            categoryList: [],
            cityList: [],
			countryId: '',
			cityId: '',
			categoryId: [],
			isFreeEvent: 0,
            formErrors: [],
            eventDetails: {},
            eventCategories: [],
            eventId: null
        }
    }

    toggleBlocking = () => {
        this.props.toggleBlocking()
    }

	componentWillReceiveProps(nextProps) {
        // Check if the events array matches what's been passed
        if(nextProps.contactEmail !== this.state.contactEmail) {
            this.setState({contactEmail: nextProps.contactEmail});
        }

        if(nextProps.eventId !== this.state.eventId) {
            this.setState({eventId: nextProps.eventId});
        }

        if(nextProps.contactName !== this.state.contactName) {
            this.setState({contactName: nextProps.contactName});
        }

        if(nextProps.contactPhoneNumber !== this.state.contactPhoneNumber) {
            this.setState({contactPhoneNumber: nextProps.contactPhoneNumber});
        }

        if(nextProps.userName !== this.state.username) {
            this.setState({username: nextProps.userName});
        }

        if(nextProps.eventDetails !== this.state.eventDetails) {
            this.setState({eventDetails: nextProps.eventDetails});
        }

        if(nextProps.eventDetails !== this.state.eventDetails) {
            this.setState({eventDetails: nextProps.eventDetails})
            this.setState({eventName: nextProps.eventDetails.name})
            this.setState({eventDescription: nextProps.eventDetails.description})
            this.setState({eventVenue: nextProps.eventDetails.loc})
            this.setState({eventTags: nextProps.eventDetails.tags})
            this.setState({countryId: nextProps.eventDetails.loc_id})
            this.setState({cityId: nextProps.eventDetails.city})
            this.setState({isFreeEvent: nextProps.eventDetails.has_ticket})
            // Get City List
            this.getCityList(nextProps.eventDetails.loc_id)
            this.setState({eventStartDate: moment(nextProps.eventDetails.start_at).format('YYYY-MM-DD')})
            this.setState({eventStartTime: moment(nextProps.eventDetails.start_at).format('HH:mm')})
            this.setState({eventEndDate: moment(nextProps.eventDetails.end_at).format('YYYY-MM-DD')})
            this.setState({eventEndTime: moment(nextProps.eventDetails.end_at).format('HH:mm')})
        }

        if(nextProps.eventCategories !== this.state.eventCategories) {
            this.setState({eventCategories: nextProps.eventCategories})
            
            let categoriesArray = []

            // Get the first category
            for(let i = 0; i < nextProps.eventCategories.length; i++) {
                // Set default
                categoriesArray.push(nextProps.eventCategories[i].category_id)
            }

            this.setState({categoryId: categoriesArray})
        }

        this.getCountryList(nextProps.userName);
        this.getCategoryList(nextProps.userName);
    }
    
    getCountryList = (userName = null) => {
        let url = "https://www.ticketsasa.com/index.php?option=com_enmasse&controller=merchantservice&task=getCountryList";

        var dataObj = {
            'user_id' : userName
        }

		axios({
            method: 'POST',
            url: url,
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(dataObj)
		})
        .then(response => {
            // Check status
            if(response.data.status === "success") {
                this.setState({
                    countryList: response.data.data.country_list ? response.data.data.country_list : []
                })
            } else if(response.data.status === "error") {
                // Error

            } else {
                // Error
            }
        })
        .catch(error => {
        });
    }
    
    getCategoryList = (userName = null) => {
        let url = "https://www.ticketsasa.com/index.php?option=com_enmasse&controller=merchantservice&task=getEventCategories";

        var dataObj = {
            'user_id' : userName
        }

        // this.toggleBlocking();

		axios({
            method: 'POST',
            url: url,
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(dataObj)
		})
        .then(response => {
            // this.toggleBlocking();

            // Check status
            if(response.data.status === "success") {
                this.setState({
                    categoryList: response.data.data.categories ? response.data.data.categories : []
                })
            } else if(response.data.status === "error") {
                // Error

            } else {
                // Error
            }
        })
        .catch(error => {
            // this.toggleBlocking();
        });
    }
    
    getCityList = (countryId = null) => {
        let url = "https://www.ticketsasa.com/index.php?option=com_enmasse&controller=merchantservice&task=getCityList";

        var dataObj = {
            'user_id' : this.state.username ? this.state.username : '',
            'countryId' : countryId ? countryId : ''
        }

        this.toggleBlocking();

		axios({
            method: 'POST',
            url: url,
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(dataObj)
		})
        .then(response => {
            this.toggleBlocking();

            // Check status
            if(response.data.status === "success") {
                this.setState({
                    cityList: response.data.data.city_list ? response.data.data.city_list : []
                })
            } else if(response.data.status === "error") {
                // Error

            } else {
                // Error
            }
        })
        .catch(error => {
            this.toggleBlocking();
        });
    }
	
	validateCreateEventForm = () => {
		let errorArray = []

		// Check for required data
		// 1. Contact details
		if(!this.state.contactName || !this.state.contactEmail || !this.state.contactPhoneNumber) {
			this.setState({
				validContactDetails: false 
			})
			
			errorArray.push({errorType: 'contactDetails', errorTitle: 'Contact Details', errorMessage: 'There are missing contact details.'})
		}

		// 2. Event details
		if(!this.state.eventName || !this.state.eventDescription || !this.state.countryId || !this.state.cityId || !this.state.categoryId || !this.state.eventStartDate || !this.state.eventStartTime|| !this.state.eventEndDate || !this.state.eventEndTime || !this.state.eventVenue) {
			this.setState({
				validEventDetails: false 
			})
			errorArray.push({errorType: 'eventDetails', errorTitle: 'Event Details', errorMessage: 'Please enter the missing event details.'})
		}

		return errorArray.length > 0 ? errorArray : []
	}

	handleSubmit = (event) => {
        event.preventDefault();

		// Validate form
		let validateForm = this.validateCreateEventForm()
		
		this.setState({
			formErrors: validateForm
        })
        
        if(validateForm.length > 0) {
            window.scrollTo(0,0);
        }

		if(validateForm.length < 1) {
			this.toggleBlocking()

			// If there are no errors
			// Submit form details
			let url = "https://www.ticketsasa.com/index.php?option=com_enmasse&controller=merchantservice&task=createMerchantEvent"

			// Format date strings
			let startDate, endDate
			
			startDate = moment(this.state.eventStartDate + ' ' + this.state.eventStartTime).format('YYYY-MM-DD HH:mm:ss')
			endDate = moment(this.state.eventEndDate + ' ' + this.state.eventEndTime).format('YYYY-MM-DD HH:mm:ss')

			let dataObj = {
				'user_id': this.state.username,
				'contactName': this.state.contactName,
				'contactEmail': this.state.contactEmail,
				'contactPhoneNumber': this.state.contactPhoneNumber,
				'eventName': this.state.eventName,
				'eventDescription': this.state.eventDescription,
				'eventVenue': this.state.eventVenue,
				'eventCountry': this.state.countryId,
				'eventCity': this.state.cityId,
				'eventCategories': this.state.categoryId.length > 0 ? JSON.stringify(this.state.categoryId) : JSON.stringify([]),
				'eventStartDate': startDate,
				'eventEndDate': endDate,
				'eventTags': this.state.eventTags,
                'iseventFree': this.state.isFreeEvent,
                'merchantName' : localStorage.getItem('merchantName'),
                'userName': localStorage.getItem('name'),
                'eventId': this.state.eventId ? this.state.eventId : null
            }
            
            //console.log('Event Id: ', this.state.eventId);

			axios({
				method: 'POST',
				url: url,
				headers: { 'content-type': 'application/x-www-form-urlencoded' },
				data: qs.stringify(dataObj)
			})
			.then(response => {
				// Check response
				if(response.data.status === 'success') {
					this.setState({
						formSubmittedStatus: 'success',
						formSubmittedShow: true,
						formSubmittedMessageColour: 'success',
						formSubmittedMessage: 'Your new event data was successfully submitted! Track progress on the events page.'
                    })
                    
                    alert('Your new event data was successfully submitted! Track progress on the events page.')

				} else if(response.data.status === 'error') {
					this.setState({
						formSubmittedStatus: 'error',
						formSubmittedShow: true,
						formSubmittedMessageColour: 'danger',
						formSubmittedMessage: response.data.message ? response.data.message : 'There was an error submitting your new event data'
                    })
                    
                    alert('There was an error submitting your new event data. Please try again. Contact events@ticketsasa.com if the issue persists.')

				} else {
					// Show error (something went wrong)
					this.setState({
						formSubmittedStatus: 'error',
						formSubmittedShow: true,
						formSubmittedMessageColour: 'danger',
						formSubmittedMessage: 'There was an error submitting your new event data.'
					})
                    
                    alert('There was an error submitting your new event data. Please try again. Contact events@ticketsasa.com if the issue persists.')
                }
                
                window.scrollTo(0,0);

				this.toggleBlocking()
			})
			.catch(error => {
                window.scrollTo(0,0);
				// Show error (something went wrong)
				this.setState({
                    formSubmittedStatus: 'error',
                    formSubmittedShow: true,
                    formSubmittedMessageColour: 'danger',
					formSubmittedMessage: 'There was an error submitting your new event data.'
                })
                    
                alert('There was an error submitting your new event data. Please try again. Contact events@ticketsasa.com if the issue persists.')
                
                window.scrollTo(0,0);

				this.toggleBlocking()
			})

		}
	}

	handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
	
        if(name === 'categoryId') {
            const options = event.target.options
            let selectOptions = []
            for(let i = 0, l = options.length; i < l; i++) {
                if(options[i].selected) {
                    selectOptions.push(options[i].value);
                }
            }
            this.setState({
                [name]: selectOptions
            });
            //console.log('event category details: ', selectOptions)
        } else {
            this.setState({
                [name]: value
            });
        }

		this.validateCreateEventForm();

		// If the input that has changes is country.
		if(name === 'countryId') {
			// Get cities in that country.
			this.getCityList(value);
        }
	}

    render() {
        const { countryList, cityList, categoryList } = this.state;

		const GetCountryOptions = props => {
            const countryOptions = countryList.map((country, key) => {
                return (
                    <option key={key} value={country.id}>{country.name}</option> 
                )
            })

            return (
                <Input type="select" className="form-control form-control-line" name="countryId" placeholder="Country" value={this.state.countryId} onChange={this.handleInputChange} required>
                    <option value="">-Please select country-</option>
                    {countryOptions}
                </Input>
            )
		}

		const GetCityOptions = props => {
            const cityOptions = cityList.map((city, key) => {
                return (
                    <option key={key} value={city.id}>{city.name}</option> 
                )
            })

            return (
                <Input type="select" className="form-control form-control-line" name="cityId" placeholder="City" value={this.state.cityId} onChange={this.handleInputChange} required>
                    <option value="">-Please select city-</option>
                    {cityOptions}
                </Input>
            )
		}

		const GetCategoryOptions = props => {
            const categoryOptions = categoryList.map((category, key) => {
                return (
                    <option key={key} value={category.id}>{category.name}</option> 
                )
            })

            return (
                <Input type="select" className="form-control form-control-line" name="categoryId" placeholder="Category" value={this.state.categoryId} onChange={this.handleInputChange} required multiple>
                    {categoryOptions}
                </Input>
            )
        }

        //console.log(this.state.formErrors)
        
        return(

            <form className="form-horizontal form-material" onSubmit={this.handleSubmit} name="createEventForm">
                <Alert color={this.state.formSubmittedMessageColour} style={{display: this.state.formSubmittedShow ? '' : 'none'}}>
                    {this.state.formSubmittedMessage}
                </Alert>

                {
                    // Looping over errors form errors if any
                    this.state.formErrors.map((errorDetails, key) => {
                        return (
                            <Alert color="danger" key={key}>
                                {errorDetails.errorMessage}
                            </Alert>
                        )
                    })
                }

                <p><strong><em>Please enter your contact details</em></strong></p>

                <div className="row">

                    <div className="col-md-6">

                        <div className="form-group">
                            <label className="col-md-12">Name</label>
                            <div className="col-md-12">
                                <input type="text" placeholder="" className="form-control form-control-line" value={this.state.contactName} name="contactName" onChange={this.handleInputChange} />
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="col-md-12">Email</label>
                            <div className="col-md-12">
                                <input type="email" placeholder="" className="form-control form-control-line" value={this.state.contactEmail} name="contactEmail" onChange={this.handleInputChange} />
                            </div>
                            {/* <p style={{color: 'red', display: this.state.validContactEmail ? 'none' : ''}}><small><em>Please enter a valid email.</em></small></p> */}
                        </div>
                            
                    </div>

                    <div className="col-md-6">

                        <div className="form-group">
                            <label className="col-md-12">Phone Number</label>
                            <div className="col-md-12">
                                <input type="text" placeholder="" className="form-control form-control-line" value={this.state.contactPhoneNumber} name="contactPhoneNumber" onChange={this.handleInputChange} />
                            </div>
                        </div>
                    </div>

                </div>
                
                <br />
                <p><strong><em>Event Details</em></strong></p>

                <div className="row">
                    <div className="col-md-6">

                        <div className="form-group">
                            <label className="col-md-12">Name</label>
                            <div className="col-md-12">
                                <input type="text" placeholder="" className="form-control form-control-line" value={this.state.eventName} name="eventName" onChange={this.handleInputChange} required />
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="col-md-12">Description</label>
                            <textarea className="form-control form-control-line" name="eventDescription" rows="4" value={this.state.eventDescription} onChange={this.handleInputChange} form="createEventForm" maxLength="340" placeholder="(340 words)"></textarea>
                        </div>

                        <div className="form-group">
                            <label className="col-md-12">Country</label>
                            <div className="col-md-12">
                                <GetCountryOptions></GetCountryOptions>
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="col-md-12">City</label>
                            <div className="col-md-12">
                                <GetCityOptions></GetCityOptions>
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="col-md-12">Venue</label>
                            <div className="col-md-12">
                                <input type="text" placeholder="" className="form-control form-control-line" value={this.state.eventVenue} name="eventVenue" onChange={this.handleInputChange} required />
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="col-md-12">Category</label>
                            <div className="col-md-12">
                                <GetCategoryOptions></GetCategoryOptions>
                            </div>
                        </div>

                    </div>

                    <div className="col-md-6">

                        <div className="form-group">
                            <label className="col-md-12">Start Date</label>
                            <div className="col-md-12">
                                <input type="date" placeholder="" min={moment().format('YYYY-MM-DD')} className="form-control form-control-line" value={this.state.eventStartDate} name="eventStartDate" onChange={this.handleInputChange} required />
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="col-md-12">Start Time<small>(24 hour clock format)</small></label>
                            <div className="col-md-12">
                                <input type="time" placeholder="" className="form-control form-control-line" value={this.state.eventStartTime} name="eventStartTime" onChange={this.handleInputChange} required />
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="col-md-12">End Date</label>
                            <div className="col-md-12">
                                <input type="date" placeholder="" min={moment().format('YYYY-MM-DD')} className="form-control form-control-line" value={this.state.eventEndDate} name="eventEndDate" onChange={this.handleInputChange} required />
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="col-md-12">End Time<small>(24 hour clock format)</small></label>
                            <div className="col-md-12">
                                <input type="time" placeholder="" className="form-control form-control-line" value={this.state.eventEndTime} name="eventEndTime" onChange={this.handleInputChange} required />
                            </div>
                        </div>

                        <div className="form-group">
                            <label className="col-md-12">Tags <small>(use a comma to separate the tags e.g. movies, music)</small></label>
                            <textarea className="form-control form-control-line" name="eventTags" rows="4" value={this.state.eventTags} onChange={this.handleInputChange} form="createEventForm" maxLength="340"></textarea>
                        </div>

                        <div className="form-group">
                            <label className="col-md-12"><input type="checkbox" placeholder="" className="" checked={this.state.isFreeEvent} name="isFreeEvent" onChange={this.handleInputChange} /> This is a free event</label>
                        </div>

                        <button type="submit" className="btn btn-success">Submit Event Details</button>

                    </div>

                </div>
            </form>
            
        )
    }
}

export default EventDetails
